<template>
  <div class="allmas">
    <div class="all">
      <div class="top">
        <h4>引流折扣申请</h4>
      </div>
      <div class="center">
        <!-- 送券人（选择） -->
        <li>
          <span> 送券人：</span>
          <!-- @click="click2" -->
          <div class="weidingyi">
            <input type="text" style="width: 180px;height: 30px;font-size: 16px;" v-model="selected3" disabled>
            <!-- <span>√</span> -->
          </div>
          <!-- <div class="postionableDiv" v-if="dialoag2">
            <span v-for="(item, index) in options3" :key="index" @click="changeMethods2">
              {{ item.name }}
            </span>
          </div> -->
        </li>
        <li>
          <span> 引流时间类型：</span>
          <el-select v-model="TimeType" placeholder="请选择你的开通时间" filterable @change="titleTypeMethods">
            <el-option v-for="item in TimelistType" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </li>
        <li>
          <span> 引流时间类型：</span>
          <el-select v-model="Time" placeholder="请选择你的开通时间" filterable @change="titleMethods">
            <el-option v-for="item in Timelist" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </li>
        <li>
          <span> 引流地图类型：</span>
          <div class="weidingyi" @click="click1">
            <input type="text" style="width: 180px;height: 30px;font-size: 16px;" v-model="selected2">
            <span>√</span>
          </div>
          <div class="postionableDiv" v-if="dialoag1">
            <span v-for="(item, index) in options2" :key="index" @click="changeMethods">
              {{ item.name }}
            </span>
          </div>
        </li>
        <li>
          <span> 引流平台：</span>
          <div class="weidingyi" @click="click2">
            <input type="text" style="width: 180px;height: 30px;font-size: 16px;" v-model="moren">
            <span>√</span>
          </div>
          <div class="postionableDiv" v-if="dialoag2">
            <span v-for="(item, index) in options" :key="index" @click="changeMethods2(index)">
              {{ item.paramKey }}
            </span>
          </div>
        </li>
        <li>
          <p v-for="(item, index) in radiolist" :key="index" @click="Methodscurrent(index)">
            <input type="checkbox" v-model="item.checked" /> <span>{{ item.name }}</span>
          </p>
        </li>
        <li>
          <input type="text" v-model="newobj.money" style="height: 15px;height: 30px;font-size: 16px;"
            :placeholder="title" @change="changeMoney">
        </li>
      </div>
      <div class="bottom">
        <li>
          <span v-for="(item, index) in titlelist" :key="index">{{ item }}</span>
        </li>
      </div>
    </div>
    <div class="footer">
      <button @click="okMethods">确认减免</button>
    </div>
    <div v-if="dialoag" class="dialoagdiv">
      <div class="content">
        <p>减免优惠码成功</p>
        <p>减免优惠码：{{ obj.code }}</p>
        <p>
          <button @click="copyCode">复制分享</button>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'App',
  data() {
    return {
      selected3:window.sessionStorage.getItem('trueName'),
      dialoag2:false,
      options3:[],
      title: '请输入折扣率',
      moren: '',
      dialoag1: false,
      dialoag2: false,
      selected2: '地图平台',
      Time:0,
      TimeType:0,
      Timelist: [
        {
          id: 0,
          name: '一年'
        },
        {
          id: 1,
          name: '二年'
        },
        {
          id: 2,
          name: '三年'
        }],
      TimelistType: [
        {
          id: 0,
          name: '年'
        },
        {
          id: 1,
          name: '月'
        }
      ],
      options2: [
        {
          name: '地图平台'
        },
        {
          name: '聚合平台'
        }
      ],
      newobj: {
        money: ''
      },
      radiolist: [{
        checked: true,
        name: '减免折扣'
      }, {
        checked: false,
        name: '减免金额'
      }],
      selected: '',
      options: [],
      dialoag: false,
      titlelist: [],
      obj: {
        code: ''
      }
    }
  },
  created() {
    const that = this;
    // 温馨提示
    that.$http.get('sysConfig/getSysConfigById/38').then(res => {
      if (res.data.code !== 0) return;
      that.titlelist = res.data.data.valueName.split('</br>')
    });
    that.defaultMethods()
  },
  methods: {
    titleTypeMethods(e) {
      this.Timelist = [
        {
          id: 0,
          name: e == 0 ? '一年' : '一月'
        },
        {
          id: 1,
          name: e == 0 ? '二年' : '二月'
        },
        {
          id: 2,
          name: e == 0 ? '三年' : '三月'
        }
      ];
      this.titleMethods(0);
    },
    titleMethods(e) {
      this.publicMethods(e,this.TimeType)
    },
    // 開通時間判斷開通總金額
    publicMethods(e,type){
      if(type==0){
        if (this.objValue.slowCountMoney > 0) {
          this.checkMoneylow = e == 0 ? 12 * this.objValue.slowCountMoney : e == 1 ? 24 * this.objValue.slowCountMoney : 36 * this.objValue.slowCountMoney;
        } else {
          this.checkMoneylow = 0;
        }
        if (this.objValue.fastCountMoney > 0) {
          this.checkMoneyFast = e == 0 ? 12 * this.objValue.fastCountMoney : e == 1 ? 24 * this.objValue.fastCountMoney : 36 * this.objValue.fastCountMoney;
        } else {
          this.checkMoneyFast = 0;
        }
        this.checkMoney = this.checkMoneylow * 1 + this.checkMoneyFast * 1;
      }else{
        if (this.objValue.slowCountMoney > 0) {
          this.checkMoneylow = e == 0 ? 1 * this.objValue.slowCountMoney : e == 1 ? 2 * this.objValue.slowCountMoney : 3 * this.objValue.slowCountMoney;
        } else {
          this.checkMoneylow = 0;
        }
        if (this.objValue.fastCountMoney > 0) {
          this.checkMoneyFast = e == 0 ? 1 * this.objValue.fastCountMoney : e == 1 ? 2 * this.objValue.fastCountMoney : 3 * this.objValue.fastCountMoney;
        } else {
          this.checkMoneyFast = 0;
        }
        this.checkMoney = this.checkMoneylow * 1 + this.checkMoneyFast * 1;
      }
    },
    changeMethods2(){

    },
    click2(){
this.dialoag1=false;
    },
    changeMoney(e) {
      let abc = /^\d+(\.\d{1,2})?$/;
      console.log(e,abc.test(e),'221');
      if (!abc.test(e)) return this.$message.error(e == '请输入减免金额' ? '请检查您的减免金额格式' : '请检查你的折扣率格式');
    },
    Methodscurrent(e) {
      this.radiolist[0].checked = e !== 0 ? false : this.radiolist[e].checked;
      this.radiolist[1].checked = e !== 1 ? false : this.radiolist[e].checked;
      this.title = e == 1 ? "请输入减免金额" : '请输入折扣率';
    },
    okMethods() {
      const that = this;
      that.$http.post('drainage-preferential-log/editDrainagePreferentialLog', {
        name: that.moren,
        type: that.title == '请输入减免金额' ? 1 : 0,
        money: that.newobj.money
      }).then(res => {
        console.log(res.data, '123');
        that.dialoag = res.data.code !== 0 ? false : true;
        that.obj.code = res.data.data;
        // window.alert(res.data.code!==0?'优惠码生成失败！':'优惠码生成成功！');
      })
    },
    defaultMethods() {
      const that = this;
      // 引流类型
      that.$http.get(`sysConfig/getSysConfigById/${that.selected2 == '地图平台' ? 35 : 36}`).then(res => {
        if (res.data.code !== 0) return;
        that.options = JSON.parse(res.data.data.valueName);
        that.options.forEach(item => {
          item.paramKey = item.paramKey.split('-')[0]
        })
        that.moren = that.options[0].paramKey;
      });
    },
    changeMethods() {
      this.selected2 = this.selected2 == '地图平台' ? '聚合平台' : '地图平台';
      this.dialoag1 = false;
      this.defaultMethods()
    },
    changeMethods2(e) {
      this.moren = this.options[e].paramKey;
      this.dialoag2 = false;
    },
    click1() {
      this.dialoag2 = false;
      this.dialoag1 = !this.dialoag1;
    },
    click2() {
      this.dialoag1 = false;
      this.dialoag2 = !this.dialoag2;
    },
    // 复制分享
    copyCode() {

    }
  }
}
</script>

<style lang="less" scoped>
.allmas {
  width: 100vw;
  background: linear-gradient(to bottom, #55B6FF, #fff);
  height: 100vh;
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
}

.dialoagdiv {
  width: 100%;
  height: 100%;
  background-color: #5c5858;
  opacity: .9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 99;
  overflow: hidden;
}

.dialoagdiv>.content {
  width: 80%;
  height: 30%;
  background-color: #fff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.dialoagdiv>.content>p {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialoagdiv>.content>p:nth-child(1) {
  font-size: 32px;
}

.dialoagdiv>.content>p:nth-child(2) {
  font-size: 24px;
}

.dialoagdiv>.content>p:nth-child(3) {
  padding: 0 5%;
}

.dialoagdiv>.content>p>button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 30px;
  background-color: #55B6FF;
  color: #fff;
  border-radius: 15px;
  border: none;
  font-size: 32px;
}

.all {
  flex: 9;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.top,
.center,
.bottom {
  padding: 0 10%;
}

.top {
  flex: 1;

}

.top>h4 {
  font-size: 26px;
}

.center {
  flex: 4;
  display: flex;
  font-size: 20px;
  flex-direction: column;
}

.center>li {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  position: relative;
}

.center>li:nth-child(3)>p:nth-child(1) {
  margin-right: 20px;
}

.weidingyi {
  position: relative;
  width: 150px;
}

.weidingyi>span {
  position: absolute;
  right: -10%;
  top: .5%;
  width: 20px;
}

.postionableDiv {
  position: absolute;
  border: 1px solid #fff;
  z-index: 66;
  width: 170px;
  height: 140px;
  left: 105px;
  top: 70%;
  padding: 0 5px;
  font-size: 16px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.postionableDiv>span {
  line-height: 30px;
}

.bottom {
  flex: 5;
}

.bottom>li {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px 0;
  border-radius: 5px;
}

.bottom>li>span {
  line-height: 30px;
  text-indent: .5em;
  font-size: 20px;
}

.bottom>li>span:nth-child(1) {
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  font-size: 26px;
}

.footer {
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 0 10%;
  background-color: #fff;
  align-items: center;
  overflow: hidden;
}

.footer>button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  border-radius: 15px;
  height: 40px;
  color: #fff;
  background-color: #55B6FF;
  border: none;
  font-size: 26px;
}
</style>