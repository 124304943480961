import Vue from 'vue'
// 映射页面
import App from './App.vue'
// 路由
import router from './router'
// 导入vuex
import store from './store'
import Vant from 'vant';
import 'core-js';
import 'regenerator-runtime/runtime';
// 导入地图相关插件
import 'amfe-flexible';
// 导入手机vant-ui
import 'vant/lib/index.css';
// 导入elemnet-ui
import './plugins/element.js'
// 导入动态列表
// import dataV from '@jiaminghi/data-view';
// 导入字体图标、icon图标
import './assets/fonts/iconfont.css'
import './assets/iconfont/iconfont.css'
// 导入全局样式表
import './assets/css/global.css'
// 导入api方法
import axios from 'axios'
import 'lib-flexible/flexible.js'
// 导入语言
import i18n from './i18n'
// 配置请求的根路径
// 服务器
// axios.defaults.baseURL ='http://192.168.12.33:80/'
// axios.defaults.baseURL = 'http://1.193.163.25:8088/'
// / 本地地址
//  axios.defaults.baseURL = 'http://192.168.12.22:8088/'
// 测试地址（之后的正式地址）
// axios.defaults.baseURL = 'https://testdzlapi.rcdqiot.com/';
axios.defaults.baseURL = 'https://dzlapi.rcdqiot.com/';
// 正式地址（之后的测试地址）

// axios.defaults.baseURL = 'https://dzlapi.rcdqiot.com/';
// Vue.prototype.$imgUrl = 'http://1.193.163.25:8088/img/'
axios.interceptors.request.use(config => {
  config.headers.token = window.sessionStorage.getItem('token')
  return config
})
Vue.prototype.$imgUrl = 'https://testdzlapi.cpolar.top/station/'
Vue.prototype.$imgUrls = 'https://testdzlapi.cpolar.top/img/'
// Vue.prototype.$imgUrl = 'https://dzlapi.cpolar.top/station/'
// Vue.prototype.$imgUrls = 'https://dzlapi.cpolar.top/img/'
// Vue.prototype.$imgUrl = 'https://testdzlapi.rcdqiot.com/img/'
// Vue.prototype.$imgUrl = 'https://dzlapi.rcdqiot.com/img/'
Vue.prototype.$http = axios
Vue.prototype.$bus = new Vue()
Vue.config.productionTip = false
Vue.use(Vant);
// Vue.use(dataV);
Vue.use(
  {
    i18n: (key, value) => i18n.t(key, value) // 在注册Element时设置i18n的处理方法,可以实现当点击切换按钮后，elementUI可以自动调用.js语言文件实现多语言切换
  }
)
Vue.directive('debounce', {
  inserted(el, binding, vnode) {
    let timer = {}
    el.addEventListener('click', () => {
      if (timer) {
        clearTimeout(timer)
      }
      timer = setTimeout(() => {
        binding.value()
      }, 500)
    })
  }
})
Vue.filter('dateFormat', function (originVal) {
  const dt = new Date(originVal)
  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDate() + '').padStart(2, '0')
  const hh = (dt.getHours() + '').padStart(2, '0')
  const mm = (dt.getMinutes() + '').padStart(2, '0')
  const ss = (dt.getSeconds() + '').padStart(2, '0')
  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
})
// Vue.directive('focus', {
//   inserted: function(el) {
//       el.focus()
//   }
// })
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
