<template>
  <div class="ElecticAustor">
    <el-card>
      <el-row :gutter="24" style="display: flex;flex-direction: row;">
        <el-col :span="4" style="display: flex;flex-direction: row;align-items: center;">
          <span style="flex:3;font-size: 16px;">电站名称：</span>
          <el-input v-model="queryinfo.name" style="flex:7"></el-input>
        </el-col>
        <el-col :span="3" style="display: flex;flex-direction: row;align-items: center;">
          <span style="flex:3;font-size: 16px;">联系人：</span>
          <el-input v-model="queryinfo.phone" style="flex:7"></el-input>
        </el-col>
        <el-col :span="1">
          <el-button type="primary">查询</el-button>
        </el-col>
        <el-col :span="1">
          <el-button type="primary">重置</el-button>
        </el-col>
        <el-col :span="2">
          <el-button type="success">添加电站</el-button>
        </el-col>
        <el-col :span="2">
          <el-button type="danger">批量删除</el-button>
        </el-col>
      </el-row>
      <div class="listall">
        <div class="content">
          <li v-for="(item, index) in tablelist" :key="index">
            <h4>
              <div class="pnames">{{ item.stationName }}(ID：{{ item.id }})</div>
              <div class="pnames">
                <div class="left" v-if="item.typeName !== ''">
                  <el-tag type="warning" style="margin-right: 20px;">{{ item.typeName }}</el-tag>
                  <el-tag type="warning">{{ item.statusName }}</el-tag>
                </div>
                <div class="left" v-else>
                  <span></span><span> </span>
                </div>
                <div class="left">
                  <el-tag :type="item.type == 0 ? 'danger' : 'success'" style="margin-right: 20px;">{{ item.type == 0 ?
        '禁用' : '已启用' }}</el-tag>
                  <el-tag :type="item.status == 0 ? 'danger' : 'success'">{{ item.status == 0 ? '未开放' : '开放' }}</el-tag>
                </div>
              </div>
            </h4>
            <dl>
              <div class="lefts">
                <dt>
                  <!-- 环形图 -->
                  <div class="left1"></div>
                </dt>
                <dd>
                  <div class="topName"><span>直流桩&ensp;/&ensp;充电口</span><span>{{ item.pileCount }}&ensp;/&ensp;{{
        item.count }}</span>
                  </div>
                  <div class="bottomName">
                    <div class="pname">
                      <div class="bott1"> </div>
                      <div class="bott2"><span>空闲充电口</span><span>{{ item.freeCount }}</span></div>
                    </div>
                    <div class="pname">
                      <div class="bott1"> </div>
                      <div class="bott2"><span>使用充电口</span><span>{{ item.codecount }}</span></div>
                    </div>
                    <div class="pname">
                      <div class="bott1"> </div>
                      <div class="bott2"><span>异常充电口</span><span>{{ item.codecount }}</span></div>
                    </div>
                  </div>
                </dd>
              </div>
              <div class="rights">
                <dt>
                  <!-- 环形图 -->
                  <div class="right1">

                  </div>
                </dt>
                <dd>
                  <div class="topName"><span>交流桩&ensp;/&ensp;充电口</span><span>{{ item.pileCount }}&ensp;/&ensp;{{
        item.count }}</span>
                  </div>
                  <div class="bottomName">
                    <div class="pname">
                      <div class="bott1"> </div>
                      <div class="bott2"><span>空闲充电口</span><span>{{ item.freeCount }}</span></div>
                    </div>
                    <div class="pname">
                      <div class="bott1"> </div>
                      <div class="bott2"><span>使用充电口</span><span>{{ item.useCount }}</span></div>
                    </div>
                    <div class="pname">
                      <div class="bott1"> </div>
                      <div class="bott2"><span>异常充电口</span><span>{{ item.errCount }}</span></div>
                    </div>
                  </div>
                </dd>
              </div>
            </dl>
            <div class="list">
              <el-button v-for="(items, indexs) in listname" :key="indexs"
                :type="indexs == activeIndex ? 'success' : 'primary'">
                {{ items }}
              </el-button>
            </div>
          </li>
        </div>
        <div class="footer">
          <el-pagination @size-change="handlesize" @current-change="handleCurrent" :current-page="queryinfo.page"
            :page-sizes="[8, 20, 50, 100]" :page-size="queryinfo.limit" layout="total, sizes, prev, pager, next, jumper"
            :total="total" background>
          </el-pagination>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
import * as echarts from 'echarts'
export default {
  name: '',
  mixins: [],
  components: {
  },
  props: {

  },
  data() {
    return {
      totalMoneys: 0,
      total: 0,
      tupian: this.$imgUrls,
      queryinfo: {
        name: '',
        phone: '',
        page: 1,
        limit: 10
      },
      activeIndex: 0,
      tablelist: [
        {
          stationName: '新能源充电站',
          id: 4,
          status: 0,
          type: 0,
          typeName: '',
          statusName: '',
          count: 10,
          codecount: 4,
          pileCount: 5,
          freeCount: 2,
          useCount: 4,
          errCount: 6,
          freeName: '空闲充电口',
          useName: '使用充电口',
          errName: '异常充电口'
        },
        {
          stationName: '八桥充电站',
          id: 7,
          status: 1,
          type: 1,
          typeName: '重卡充电站',
          statusName: '大型车辆充电站',
          count: 14,
          codecount: 2,
          pileCount: 7,
          freeCount: 0,
          useCount: 1,
          errCount: 2,
          freeName: '空闲充电口',
          useName: '使用充电口',
          errName: '异常充电口'
        }
      ],
      listname: [
        '设备监控',
        '编辑电站',
        '查看订单',
        '充电策略',
        '停车策略',
        '上传站图'
      ]
    }
  },
  computed: {

  },
  watch: {

  },
  created() {
    this.circleImage();
    this.defaultMethods();
    // var myChart=echarts.init
  },
  mounted() {

  },
  methods: {
    circleImage() {
      var roseCharts = document.getElementsByClassName("left1");
      var roseCharts2 = document.getElementsByClassName("right1");
      for (var i = 0; i < roseCharts2.length; i++) {
        var myChart = echarts.init(roseCharts2[i]);
        myChart.setOption({
          legend: {
            top: '5%',
            left: 'left',
            selectedMode: false
          },
          series: [
            {
              type: "pie",
              color: this.tablelist[i].color,
              radius: ['70%', '40%'],
              center: ['50%', '50%'],
              avoidLabelOverlap: true,
              startAngle: 360,
              emphasis: {
                label: {
                  show: true,
                  fontSize: 20,
                }
              },
              labelLine: {
                show: false
              },
              data: [
                {
                  value: this.tablelist[i].pileCount * 1,
                  value2: (this.tablelist[i].pileCount * 1) + (this.tablelist[i].useCount * 1) + (this.tablelist[i].errCount * 1),
                  name: this.tablelist[i].freeName,
                  label: {
                    show: true,
                    position: 'left',
                    formatter(param) {
                      return param.data.value;
                    }
                  }
                },
                {
                  value: this.tablelist[i].useCount * 1,
                  va: (this.tablelist[i].pileCount * 1) + (this.tablelist[i].useCount * 1) + (this.tablelist[i].errCount * 1),
                  name: this.tablelist[i].useName,
                  label: {
                    show: true,
                    position: 'left',
                    formatter(param) {
                      return param.data.value;
                    }
                  }
                },
                {
                  value: this.tablelist[i].errCount * 1,
                  value2: (this.tablelist[i].pileCount * 1) + (this.tablelist[i].useCount * 1) + (this.tablelist[i].errCount * 1),
                  name: this.tablelist[i].errName,
                  label: {
                    show: true,
                    position: 'left',
                    formatter(param) {
                      return param.data.value;
                    }
                  }
                }
              ],
            },
          ]
        })
      };
      for (var i = 0; i < roseCharts.length; i++) {
        var myChart = echarts.init(roseCharts[i]);
        myChart.setOption({
          legend: {
            top: '5%',
            left: 'left',
            selectedMode: false
          },
          series: [
            {
              type: "pie",
              color: this.tablelist[i].color,
              radius: ['70%', '40%'],
              center: ['50%', '50%'],
              avoidLabelOverlap: true,
              startAngle: 360,
              emphasis: {
                label: {
                  show: true,
                  fontSize: 20,
                }
              },
              labelLine: {
                show: false
              },
              data: [
                {
                  value: this.tablelist[i].pileCount * 1,
                  value2: (this.tablelist[i].pileCount * 1) + (this.tablelist[i].useCount * 1) + (this.tablelist[i].errCount * 1),
                  name: this.tablelist[i].freeName,
                  label: {
                    show: true,
                    position: 'left',
                    formatter(param) {
                      return param.data.value;
                    }
                  }
                },
                {
                  value: this.tablelist[i].useCount * 1,
                  va: (this.tablelist[i].pileCount * 1) + (this.tablelist[i].useCount * 1) + (this.tablelist[i].errCount * 1),
                  name: this.tablelist[i].useName,
                  label: {
                    show: true,
                    position: 'left',
                    formatter(param) {
                      return param.data.value;
                    }
                  }
                },
                {
                  value: this.tablelist[i].errCount * 1,
                  value2: (this.tablelist[i].pileCount * 1) + (this.tablelist[i].useCount * 1) + (this.tablelist[i].errCount * 1),
                  name: this.tablelist[i].errName,
                  label: {
                    show: true,
                    position: 'left',
                    formatter(param) {
                      return param.data.value;
                    }
                  }
                }
              ],
            },
          ]
        })
      }
    },
    defaultMethods() {

    },
    handleCurrent(newPage) {
      this.queryinfo.page = newPage
    },
    handlesize(newSize) {
      this.queryinfo.limit = newSize
    }
  }
};
</script>

<style lang='less' scoped>
.ElecticAustor {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 1% 1% 1%;

  .listall {
    flex: 1;
    overflow: hidden;

    .content {
      height: calc(100% - 50px);
      overflow-y: scroll;
      display: flex;
      flex-direction: column;

      li {
        height: 400px;
        display: flex;
        flex-direction: column;
        padding: 0 1%;
        border: 1px solid #c5c5c5;
        margin-top: 20px;

        h4,
        .list {
          flex: 2;
        }

        h4 {
          display: flex;
          flex-direction: column;
          padding-bottom: 10px;
          border-bottom: 1px solid gray;

          .pnames {
            flex: 1;
            display: flex;
          }

          .pnames:nth-child(1) {
            align-items: center;
            font-size: 20px;
          }

          .pnames:nth-child(2) {
            flex-direction: row;

            .left {
              flex: 1;
              display: flex;
              align-items: center;


            }
          }
        }

        .list {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-top: 5px;
          border-top: 1px solid gray;

          p {
            flex: 1;
          }
        }

        dl {
          flex: 6;
          display: flex;
          flex-direction: row;

          .rights,
          .lefts {
            flex: 1;
            display: flex;
            flex-direction: row;


            dt,
            dd {
              flex: 1;
            }
            dt {
              display: flex;
              justify-content: center;
              align-items: center;
              .right1,.left1 {
                width: 200px;
                height: 200px;
              }
            }

            dd {
              display: flex;
              flex-direction: column;

              .topName {
                flex: 1;
                display: flex;
                flex-direction: row;
              }

              .bottomName {
                flex: 9;
                display: flex;
                flex-direction: column;

                .pname {
                  flex: 1;
                  display: flex;
                  flex-direction: row;
                  align-items: center;

                  .bott1 {
                    flex: 1;
                  }

                  .bott2 {
                    flex: 9;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    span {
                      flex: 1;
                    }
                  }
                }
              }
            }
          }
        }

      }
    }

    .footer {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
