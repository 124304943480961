<template>
  <div>
    <!-- 卡片视图区域 直充-->
    <el-card>
      <!-- style="margin-bottom:20px ;" -->
      <el-row :gutter="20" style="margin-bottom: 10px;">
        <el-col :span="3" label="" style="display: flex;flex-direction: row;align-items:center;">
          <span style="flex:3;font-size:16px;">清分人</span>
          <el-select style="flex:7;" v-model="suoshudianzhan" placeholder="请选择清分人" @change="change_value" filterable>
            <el-option v-for="item in powerStationList" :key="item.id" :label="item.adminName" :value="item.id">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" icon="el-icon-search" @click="getVinAccountList">查询</el-button>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" icon="el-icon-refresh-left" @click="chongzhi">重置</el-button>
        </el-col>
        <el-col :span="3" v-if="ownAcount">
          <el-button type="success" icon="el-icon-plus" @click="showVinAccount">分佣商户</el-button>
        </el-col>
        <el-col :span="3" v-if="daochuType">
          <el-button type="primary" @click="daochuMethods">导出</el-button>
        </el-col>
        <!-- <el-col :span="2">
          <el-button type="primary" icon="el-icon-refresh-left" @click="daochu">导出</el-button>
        </el-col> -->
      </el-row>
      <!-- table表格区域 -->
      <el-table :data="vinAccountList" border>
        <el-table-column align="center" label="清分人姓名" prop="trueName" width="100"></el-table-column>
        <el-table-column align="center" label="是否主账户" prop="isMainAccount" width="100">
          <template slot-scope="scope">
            <span>
              {{ scope.row.isMainAccount == 0 ? '非主账户' : '主账户' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="收款卡号" prop="bankCard"></el-table-column>
        <el-table-column align="center" label="手机号" prop="phone" width="110"></el-table-column>
        <el-table-column align="center" label="清分类型" prop="type" width="100">
          <template slot-scope="scope">
            <span>
              {{ scope.row.type == 0 ? '服务费' : scope.row.type == 1 ? '全额消费' : '全额电费' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="清分比例" prop="serviceRate" width="90">
          <template slot-scope="scope">
            <span>
              {{ scope.row.serviceRate + '%' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="清分金额" prop="freezeMoney" width="90">
          <template slot-scope="scope">
            <span>
              ￥{{ jisuan(scope.row.freezeMoney, 100, 2) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="清分商户" prop="totalMerchantMame"></el-table-column>
        <el-table-column align="center" label="登录名称" prop="userName"></el-table-column>
        <el-table-column align="center" label="更新时间" prop="updateTime" width="100">
          <template slot-scope="scope">
            <span>
              {{ scope.row.updateTime !== null ? scope.row.updateTime : '---' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="left" label="操作（禁用状态下不可提现）" v-if="caozuoquanxian" width="360">
          <template slot-scope="scope">
            <el-button size="small" type="primary" icon="el-icon-edit" :disabled="scope.row.b_j_anniu"
              @click="showVinAccount(scope.row)" v-if="ownAcount">编辑</el-button>
            <el-button size="small" type="warning" :disabled="scope.row.b_j_anniu"
              @click="detailMethods(scope.row)">清分明细</el-button>
            <el-button size="small" type="danger" @click="moneyMethods(scope.row)" v-if="StopMoney">冻结金额</el-button>
            <el-button size="small"
              v-if="typings && stopAccountStatus || scope.row.isMainAccount !== 0 && stopAccountStatus"
              :type="scope.row.status == 1 ? 'success' : 'danger'" :disabled="scope.row.b_j_anniu"
              @click="stopMethods(scope.row)">{{ scope.row.status == 1 ? '启用' : '停用' }}</el-button>

            <!-- <el-button size="small" v-if=""
              :type="scope.row.status == 1 ? 'success' : 'danger'" :disabled="scope.row.b_j_anniu"
              @click="stopMethods(scope.row)">{{ scope.row.status == 1 ? '启用' : '停用' }}</el-button> -->
          </template>
        </el-table-column>
        <div slot="empty" class="empty">
          <span>暂无数据</span>
        </div>
      </el-table>
      <!-- 分页导航区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.page" :page-sizes="[6, 20, 50, 100]" :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="total" background>
      </el-pagination>
    </el-card>
    <!-- 停止、启用对话框 -->
    <el-dialog :title="titleName" :visible.sync="titleDialoag" width="30%" @close="colseDialoag">
      <div class="titledialoag">
        <p>
          是否确认{{ titleName }}该清分账户?
        </p>
        <p>
          注:{{ titleName }}该清分账户后，请尽快修改其他清分账户的清分比例，未修改则会影响正常清分，{{ titleName }}请慎重!
        </p>
        <p>
          <button @click="colseDialoag">取消</button> <button @click="stopAccount">确定</button>
        </p>
      </div>
    </el-dialog>
    <!-- 添加&&编辑清分的对话框 -->
    <el-dialog :title="title" :visible.sync="editDialogVisible" width="40%" @close="editDialogClosed" append-to-body
      :close-on-click-modal="false">
      <el-form :model="vinAccountForm" :rules="vinAccountFormRules" ref="vinAccountFormRef" label-width="80px">
        <el-row type="flex" class="row-bg" justify="space-around" :gutter="24">
          <el-col :span="12">
            <!-- 内容主体区域 -->
            <el-form-item label="提现商户" prop="adminId">
              <el-select v-model="vinAccountForm.totalLedgerId" @change="changeStationMethods">
                <el-option v-for="item in allshanghu" :key="item.id" :value="item.id"
                  :label="item.realname"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="真实姓名" prop="trueName">
              <el-input v-model="vinAccountForm.trueName"></el-input>
            </el-form-item>
            <el-form-item label="收款银行" prop="bankName">
              <el-input v-model="vinAccountForm.bankName"></el-input>
            </el-form-item>
            <el-form-item label="收费类型" prop="type">
              <el-select v-model="vinAccountForm.type">
                <el-option v-for="item in typelist" :key="item.id" :value="item.id" :label="item.name"></el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="登录账户" prop="userName">
              <el-input v-model="userName" :disabled="title !== '分佣商户'"></el-input>
            </el-form-item> -->
            <!-- 是否主账户 -->
            <el-form-item label="主账户" prop="isMainAccount">
              <el-radio-group v-model="radio">
                <el-radio v-for="item in leaavel" :key="item.name" :label="item.name">{{ item.name }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属电站" prop="stationId">
              <el-select v-model="vinAccountForm.stationId" @change="methodsDefault" multiple>
                <el-option v-for="item in stationList" :key="item.id" :value="item.id"
                  :label="item.stationName"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="收款卡号" prop="bankCard">
              <el-input v-model="vinAccountForm.bankCard"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="phone">
              <el-input v-model="vinAccountForm.phone"></el-input>
            </el-form-item>
            <el-form-item label="费率" prop="serviceRate">
              <el-input-number v-model="vinAccountForm.serviceRate" controls-position="right" :min="0"
                :max="100"></el-input-number>
              <span style="margin-left: 8px;">%</span>
            </el-form-item>
            <!-- 是否主账户 -->
            <!-- :disabled="title !== '分佣商户'" -->
            <!-- <el-form-item label="登录密码" prop="passWord">
              <el-input v-model="passWord"></el-input>
            </el-form-item> -->
            <el-form-item label="备注说明" prop="des">
              <el-input v-model="vinAccountForm.des"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <!-- 底部按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogClosed">取 消</el-button>
        <el-button type="primary" v-debounce="editVinAccount">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 清分明细订单 -->
    <el-dialog :visible.sync="dialoagBtn" title="清分明细" width="80%" @close="MoneyDialoag2" append-to-body
      :close-on-click-modal="false">
      <el-table :data="List" border>
        <el-table-column align="center" label="清分人姓名" prop="merchantMame"></el-table-column>
        <el-table-column align="center" label="清分类型" prop="type">
          <template slot-scope="scope">
            <span>
              {{ scope.row.type == 0 ? '订单金额' : scope.row.type == 1 ? '电费' : '服务费' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="清分比例" prop="sortingRatio">
          <template slot-scope="scope">
            <span>
              {{ scope.row.sortingRatio + '%' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="清分金额" prop="realityMoney">
          <template slot-scope="scope">
            <span>
              ￥{{ jisuan(scope.row.realityMoney, 100, 2) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="入账商户" prop="trueName"></el-table-column>
        <el-table-column align="center" label="入账场站" prop="adminName"></el-table-column>
        <el-table-column align="center" label="流水单号" prop="billCode"></el-table-column>
        <el-table-column align="center" label="订单总金额" prop="orderMoney">
          <template slot-scope="scope">
            <span>
              ￥{{ jisuan(scope.row.orderMoney, 100, 2) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="电费/服务费" prop="merchantName">
          <template slot-scope="scope">
            <span>
              ￥{{ jisuan(scope.row.newMoney, 100, 2) }}
            </span>
            <span>
              |
            </span>
            <span>
              ￥{{ jisuan(scope.row.servicemoney, 100, 2) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="备注" prop="des"></el-table-column>
        <el-table-column align="center" label="入账时间" prop="createTime">
          <template slot-scope="scope">
            <span>
              {{ scope.row.createTime !== null ? scope.row.createTime : '---' }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange3" @current-change="handleCurrentChange3"
        :current-page="indexobj.page" :page-sizes="[8, 20, 50, 100]" :page-size="carInfo.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="total3" background>
      </el-pagination>
    </el-dialog>
    <!-- 冻结金额 -->
    <el-dialog title="冻结金额" :visible.sync="coolDialoag" width="35%">
      <div class="cool">
        <p>
          <span>￥</span>
          <el-input v-model="freezeMoney" style="width:50%"></el-input>
        </p>
        <p>--温馨提示--</p>
        <p>1、冻结金额为0，则不冻结金额;</p>
        <p>
          2、填写金额多少则冻结多少，冻结金额不支持提现。
        </p>
        <p>
          <button @click="falseMethods">取消</button>
          <button v-debounce="okMethods" :disabled="btn1">确定</button>
        </p>
      </div>
    </el-dialog>
    <!-- 导出清分明细弹窗 -->
    <el-dialog title="清分明细" :visible.sync="qingfenDialoag" width="35%">
    <div>
<el-row :gutter="24">
  <el-col>

  </el-col>
</el-row>
    </div>
    </el-dialog>
  </div>
</template>

<script>
import caozuo from "@/utils/pubilc.js";
import { saveBlobToFile } from '@/utils/saveBlobToFile';
export default {
  name: 'vinAccountWorld',
  data() {
    return {
      qingfenDialoag:false,
      daochuType: false,
      stopAccountStatus: false,
      StopMoney: false,
      ownAcount: false,
      btn1: false,
      leaavel: [
        {
          name: '是'
        },
        {
          name: '否'
        }
      ],
      titleDialoag: false,
      titleName: '停用',
      typings: false,
      List: [],
      coolDialoag: false,
      stationIds: '',
      allshanghu: [],
      typelist: [
        {
          id: 0,
          name: '服务费'
        },
        {
          id: 1,
          name: '全额消费'
        },
        // {
        //   id: 2,
        //   name: '全额电费'
        // }
      ],
      obj: {},
      powerStationList: [],
      suoshudianzhan: '',
      indexobj: {
        usingType: 0,
        billInfoType: 2,
        gunState: '0,3,4,5,6,7',
        adminId: window.sessionStorage.getItem('adminId'),
        page: 1,
        limit: 8,
        payId: '',
        userNumber: '',
        billCode: '',
        pileCode: '',
        consumeType: 0,
        type: 2,
        stationIds: window.sessionStorage.getItem('stationIds'),
        roleId: window.sessionStorage.getItem('roleId')
      },
      DetailBtn: false,
      Detaildialoag: false,
      dialoagBtn: false,
      Moneylist: [],
      total3: 0,
      carInfo: {

      },
      moren: {

      },
      list3: [],
      listindex: 0,
      tupian: this.$imgUrls,
      listobj: {

      },
      listindex: 0,
      listname: [
        '查看充值记录',
        '查看退款记录',
        '查看赠送金记录',
        '查看即充即退记录',
        '查看绑定金额记录',
        '查看充电记录',
      ],
      editDialogVisible1: false,
      editDialogVisible2: false,
      caozuoquanxian: false,
      // 获取vin用户列表所需参数
      queryInfo: {
        stationId: null,
        page: 1,
        limit: 6,
        adminId: window.sessionStorage.getItem('adminId'),
        merchantName: '',
        totalLedgerId: window.sessionStorage.getItem('roleId') == 1 ? 0 : window.sessionStorage.getItem('stationTotalLedgerListId'),
      },
      radio: window.sessionStorage.getItem('roleId') == 1 ? '是' : '否',
      // vin用户数据及参数
      vinAccountList: [],
      passWord: '',
      userName: '',
      vinAccountForm: {
        passWord: '',
        userName: '',
        adminId: '',
        trueName: '',
        id: '',
        bankCard: '',
        bankName: '',
        type: '',
        serviceRate: '',
        des: '',
        isMainAccount: '1',
        freezeMoney: '',
        totalLedgerId: '',
        stationId: ''
      },
      freezeMoney: '',
      vinAccountFormRules: {
        trueName: {
          required: true, message: '请输入真实姓名', trigger: 'blur'
        },
        merchantName: {
          required: true, message: '请输入商户名称', trigger: 'blur'
        },
        type: {
          required: true, message: '请选择收费类型', trigger: 'change'
        },
        serviceRate: {
          required: true, message: '请输入或选择费率', trigger: 'blur'
        },
        phone: {
          required: true, message: '请输入联系电话', trigger: 'blur'
        },
        totalLedgerId: {
          required: true, message: '请选择提现商户', trigger: 'change'
        },
        stationId: {
          required: true, message: '请选择所属电站', trigger: 'change'
        },
        bankCard: {
          required: true, message: '请输入收款卡号', trigger: 'blur'
        },
        bankName: {
          required: true, message: '请输入收款银行', trigger: 'blur'
        },
        // userName: {
        //   required: true, message: '请输入登录账户', trigger: 'blur'
        // },
        // passWord: {
        //   required: true, message: '请输入登录密码', trigger: 'blur'
        // }
      },
      total: 0,
      title: '',
      value: '',
      editDialogVisible: false,
      // 电站数据
      powerStationOptions: [],
      // 电站所属公司数据
      companyOptions: [
        {
          value: 1,
          label: '公交一公司'
        },
        {
          value: 2,
          label: '公交二公司'
        },
        {
          value: 3,
          label: '公交三公司'
        },
        {
          value: 4,
          label: '公交四公司'
        },
        {
          value: 5,
          label: '焦作市龙源巴士'
        }
      ],
      powerOptions: [],
      ids: '',
      stationList: [],
      goMoneyDialoag: false
    }
  },
  computed: {
    shiji() {
      return function (abc, bac) {
        return (((abc * 1) + (bac * 1)) / 100).toFixed(2)
      }
    },
    wait_s() {
      return function (e) {
        return ((e.money - e.giveMoney) / 100).toFixed(2)
      }
    },
    time_all() {
      return function (val) {
        const hr = val.billCode.slice(val.pileCode.length, val.billCode.length)
        return hr
      }
    },
    jisuan() {
      return function (obj, num, fixed) {
        return (obj / num).toFixed(fixed)
      }
    },
    shifu() {
      return function (val) {
        return ((val.billMoney - val.billMoneygive) / 100).toFixed(2)
      }
    }
  },
  watch: {
    'passWord'(newvalue) {
      if (newvalue == undefined || newvalue == '' || newvalue == null) return;
      this.passWord = newvalue;
    },
    'userName'(newvalue) {
      if (newvalue == undefined || newvalue == '' || newvalue == null) return;
      this.userName = newvalue;
    }
  },
  created() {
    const that = this;
    that.typings = window.sessionStorage.getItem('roleId') == 1 ? true : false;
    caozuo(100).then(res => {
      that.caozuoquanxian = res;
    });
    // 编辑、添加清分人员权限
    caozuo(184).then(res => {
      that.ownAcount = res;
    });
    // 冻结金额权限
    caozuo(185).then(res => {
      that.StopMoney = res;
    });
    // 修改分佣商户状态
    caozuo(186).then(res => {
      that.stopAccountStatus = res;
    });
    // 导出按钮权限
    caozuo(198).then(res => {
      that.daochuType = res;
    });
    that.publist();
    that.getVinAccountList();
    // that.stationList = [];
    // that.$http.get('station-info/getAllStationInfoName', {
    //   params: {
    //     roleId: window.sessionStorage.getItem('roleId'),
    //     stationIds: window.sessionStorage.getItem('stationIds'),
    //     stationMode: '0,1,2'
    //   }
    // }).then(resb => {
    //   if (resb.data.code !== 0) return;
    //   that.stationList = resb.data.data;
    // });

    that.$http.get(`admin/getAllAdminName/${window.sessionStorage.getItem('roleId') == 1 ? 5 : window.sessionStorage.getItem('roleId')}`).then(res => {
      if (res.data.code !== 0) return;
      that.allshanghu = res.data.data;
    });
  },

  methods: {
    daochuMethods(){
const that=this;
// that.$http.get('station-ledger/export',{params:row})
    },
    changeStationMethods(e) {
      const that = this;
      let abc = that.allshanghu.filter(item => item.id == e);
      that.defaultApimethods(abc[0].stationIds)
    },
    defaultApimethods(e) {
      const that = this;
      that.$http.get('station-info/getAllStationInfoName', {
        params: {
          roleId: window.sessionStorage.getItem('roleId'),
          stationMode: '0,1',
          stationIds: e
        }
      }).then(res => {
        if (res.data.code !== 0) return;
        that.stationList = res.data.data;
      })
    },
    // 获取密码
    async methodsDefault(e) {
      const that = this;
      await that.$http.get(`station-ledger/getUserInfoByStationId/${e}`).then(ress => {
        that.userName = ress.data.code !== 0 ? '' : ress.data.data.username;
        that.passWord = ress.data.code !== 0 || ress.data.data.password == null || ress.data.data.password == '' ? '' : '******';
      })
    },
    colseDialoag() {
      this.titleDialoag = false;
    },
    stopAccount() {
      const that = this;
      that.vinAccountForm.status = that.titleName == '启用' ? 0 : 1;
      that.$http.post('station-ledger/updStationLedger', that.vinAccountForm).then(res => {
        that.$message({
          type: res.data.code !== 0 ? 'error' : 'success',
          message: '停用成功'
        });
        that.titleDialoag = false;
        if (res.data.code !== 0) return;
        that.getVinAccountList();
      })
    },
    stopMethods(e) {
      const that = this;
      that.titleName = e.status !== 0 ? '启用' : '停用';
      that.vinAccountForm = e;
      that.titleDialoag = true;
    },
    falseMethods() {
      this.coolDialoag = false;
    },
    // 冻结金额
    okMethods() {
      this.vinAccountForm.freezeMoney = this.freezeMoney * 100;
      this.btn1 = true;
      this.$http.post('station-ledger/updStationLedger', this.vinAccountForm).then(res => {
        this.$message({ msg: res.data.code !== 0 ? '冻结金额失败，请检查你的金额格式后，再提交' : '冻结金额成功', type: res.data.code !== 0 ? 'error' : 'success' });
        this.coolDialoag = false;
      })
    },
    //金额冻结弹窗
    moneyMethods(e) {
      this.vinAccountForm = e;
      this.$http.get(`station-ledger/getStationLedgerById/${e.id}`).then(res => {
        this.freezeMoney = (res.data.data.freezeMoney / 100).toFixed(2);
        this.coolDialoag = true;
      })
    },
    daochu() {

    },
    change_value(e) {
      this.powerStationList.map(item => {
        if (item.id !== e) return;
        this.queryInfo.merchantName = item.merchantName;
        this.getVinAccountList()
      });
    },
    // 历史记录
    handleSizeChange3(newSize) {
      this.indexobj.limit = newSize;
      this.getIndex()
    },// 历史记录
    handleCurrentChange3(newPage) {
      this.indexobj.page = newPage;
      this.getIndex()
    },
    getIndex() {
      const that = this; that.list3 = [];
      that.$http.get('bill-info/getAllBillInfo', {
        params: that.indexobj
      }).then(res => {
        if (res.data.code !== 0) return;
        that.list3 = res.data.data;
      })
    },
    detailMethods(e) {
      const that = this;
      that.$http.get('station-ledger/getStationLedgerLog', {
        params: {
          stationLedgerId: e.id,
          page: 1,
          limit: 8
        }
      }).then(res => {
        that.dialoagBtn = true;
        if (res.data.code !== 0) return;
        that.List = res.data.data;
        that.total3 = res.data.count;

      })
    },
    MoneyDialoag2() {
      this.dialoagBtn = false;
    },
    listMethods() {
      const that = this;
      that.indexobj.roleId = window.sessionStorage.getItem('roleId');
      that.indexobj.stationIds = window.sessionStorage.getItem('stationIds');
      that.indexobj.gunState = '0,3,4,5,6,7';
      that.$http.get('bill-info/getAllBillInfo', {
        params: that.indexobj
      }).then(ress => {
        if (ress.data.code !== 0) return;
        that.list3 = ress.data.data;
        that.total3 = ress.data.count;
        that.moren = {
          totalBillMoney: ress.data.data[0].totalBillMoney,
          totalChargeMoney: ress.data.data[0].totalChargeMoney,
          totalDiscountMoney: ress.data.data[0].totalDiscountMoney,
          totalServiceMoney: ress.data.data[0].totalServiceMoney,
          totalBillKwhs: ress.data.data[0].totalBillKwhs,
          totalLengthTime: ress.data.data[0].totalLengthTime,
          totalBillCount: ress.data.data[0].totalBillCount,
        }
      })
      that.dialoagBtn = true;
    },
    // 用户详情
    editDialogClosed1() {
      this.editDialogVisible1 = false;
    },
    // 充电历史订单
    editDialogClosed2() {
      this.editDialogVisible2 = false;
    },
    current() {
      this.queryInfo.page = 1;
    },
    chongzhi() {
      this.queryInfo = {
        // vin: '',
        companyName: '',
        carNumber: '',
        // startTime: null,
        // endTime: null,
        stationId: null,
        page: 1,
        limit: 8,
        stationIds: window.sessionStorage.getItem('stationIds'),
        roleId: window.sessionStorage.getItem('roleId'),
        totalLedgerId: window.sessionStorage.getItem('roleId') == 1 ? 0 : window.sessionStorage.getItem('stationTotalLedgerListId'),
      };
      this.getVinAccountList();
    },
    async publist() {
      const { data: res } = await this.$http.get('station-ledger/getAllStationLedger', { params: this.queryInfo })
      if (res.code !== 0) return;
      this.powerStationList = res.data;
      console.log(res.data, '658');
    },
    // 获取vin用户列表
    async getVinAccountList() {
      const { data: res } = await this.$http.get('station-ledger/getAllStationLedger', { params: this.queryInfo })
      if (res.code !== 0) return;
      this.vinAccountList = res.data;
      this.total = res.count;
    },
    async change_type(row) {
      this.vinAccountForm.id = row.id;
      // console.log(row.type,'208')
      if (row.type == false) {
        row.type = true;
        row.electricCardState = 1;
      } else {
        row.type = false;
        row.electricCardState = 0;
      };
      this.vinAccountForm.electricCardState = row.electricCardState;
      await this.$http.post(`charge-vin/updateStatusById/${row.id}/${this.vinAccountForm.electricCardState}`).then(res => {
        this.$message.success(res.data.msg)
        this.getVinAccountList();
      }).catch(res => {
        this.$message(res.data.msg)
      })
    },
    // 双击展开事件
    dbclick(row, column, event) {
      if (event.currentTarget.querySelector('.el-table__expand-icon')) {
        event.currentTarget.querySelector('.el-table__expand-icon').click()
      }
    },
    // 监听pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize;
      this.getVinAccountList()
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getVinAccountList()
    },
    defaultMethods() {
      const that = this;
      that.title = '分佣商户';
    },
    // 展示添加&编辑商户对话框
    async showVinAccount(row) {
      const that = this;
      that.passWord = '';
      that.userName = '';
      that.powerStationOptions = [];
      that.vinAccountForm = {};
      that.editDialogVisible = true;
      if (row.id === undefined || row.id === null) return that.defaultMethods();
      that.vinAccountForm.adminId = row.adminId;
      that.title = '编辑商户';
      that.$http.get(`station-ledger/getStationLedgerById/${row.id}`).then(res => {
        that.vinAccountForm = res.data.data;
        that.vinAccountForm.stationId = that.vinAccountForm.stationId * 1;
        that.radio = res.data.data.isMainAccount == 1 ? '是' : '否';
        that.stationIds = that.vinAccountForm.stationIds * 1;
        console.log(that.vinAccountForm, '765');
        that.$http.get('station-total-ledger/getAllStationTotalLedger', {
          params: {
            id: res.data.data.totalLedgerId,
            adminId: row.adminId,
            page: 1,
            limit: 10
          }
        }).then(resn => {
          that.vinAccountForm.totalLedgerId = resn.data.data[0].adminId;
          console.log(that.vinAccountForm, '774');
          // that.vinAccountForm.stationId = resn.data.data[0].stationId.includes(',') ? resn.data.data[0].stationId.split(',') : resn.data.data[0].stationId;
          let abc = that.allshanghu.filter(item => item.id == resn.data.data[0].adminId);
          console.log(abc, '776');
          // that.defaultApimethods(abc.stationIds)
        })
        // 登录密码
        // that.methodsDefault(row.stationId);

      })
    },
    // 添加&编辑商户事件
    editVinAccount() {
      this.$refs.vinAccountFormRef.validate(async valid => {
        if (!valid) return;
        this.publicMethods()
      })
    },
    async publicMethods() {
      this.vinAccountForm.adminId = this.vinAccountForm.adminId == '' || this.vinAccountForm.adminId == undefined ? window.sessionStorage.getItem('adminId') * 1 : this.vinAccountForm.adminId * 1;
      if (this.vinAccountForm.trueName == '') return this.$message.error('请输入真实姓名');
      if (this.vinAccountForm.bankCard == '') return this.$message.error('请输入收款卡号');
      if (this.vinAccountForm.bankName == '') return this.$message.error('请输入收款银行');
      if (this.vinAccountForm.phone == '') return this.$message.error('请输入联系电话');
      if (this.vinAccountForm.trueName == '') return this.$message.error('请输入真实姓名');
      // if (this.passWord == '' || this.userName == '') return this.$message.error('请输入登录账户、登录密码');
      // if (this.vinAccountForm.serviceRate == '') return this.$message.error('请输入费率');
      // if (this.vinAccountForm.type == '') return this.$message.error('请选择收费类型');
      if (this.vinAccountForm.stationId == '') return this.$message.error('请选择所属电站');
      if (this.vinAccountForm.totalLedgerId == '') return this.$message.error('请选择提现商户');
      this.vinAccountForm.passWord = this.passWord;
      this.vinAccountForm.userName = this.userName;
      this.vinAccountForm.isMainAccount = this.vinAccountForm.isMainAccount * 1;
      this.vinAccountForm.stationId = this.vinAccountForm.stationId.map(item => item).join(',');
      const { data: res } = await this.$http.post(this.title === '分佣商户' ? 'station-ledger/addStationLedger' : 'station-ledger/updStationLedger', this.vinAccountForm)
      this.btn1 = res.code !== 0 ? true : false;
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success(`${this.title}成功！`);
      this.freezeMoney = '';
      this.getVinAccountList();
      this.editDialogVisible = false;
      this.coolDialoag = false;
    },
    // 添加&编辑商户对话框关闭事件
    editDialogClosed() {
      this.$refs.vinAccountFormRef.resetFields();
      this.editDialogVisible = false;
    }
  }
};
</script>

<style lang="less" scoped>
.titledialoag {
  width: 100%;
  height: 100%;

  p {
    display: flex;
    flex-direction: row;
    justify-content: center;
    line-height: 25px;
    color: red;
  }

  p:nth-child(3) {
    line-height: 40px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #c5c5c5;
      font-size: 16px;
      width: 120px;
      height: 30px;
      color: #000;
    }

    button:nth-child(2) {
      margin-left: 20px;
      border: none;
      color: #fff;
      background-color: blue;
    }
  }
}

.cool {
  display: flex;
  flex-direction: column;

  p {
    display: flex;
    flex-direction: row;
    line-height: 20px;
  }

  p:nth-child(1) {
    align-items: center;

    span {
      margin-right: 10px;
    }
  }

  p:nth-child(2) {
    justify-content: center;
    align-items: center;
  }

  p:nth-child(5) {
    justify-content: center;

    button {
      width: 120px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      border: 1px solid #c5c5c5;
      font-size: 16px;
      border-radius: 5px;
      background: none;

    }

    button:nth-child(2) {
      margin-left: 20px;
      background: #409EFF;
      border: none;
      color: #fff;
    }
  }
}

.moneylist {
  height: 180px;
  display: flex;
  flex-direction: row;

  dl {
    flex: 1;
    display: flex;
    flex-direction: column;

    p {
      flex: 1;
      display: flex;
      align-items: center;
    }

  }
}

.cardslist {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .top,
  .center {
    flex: 2;
    display: flex;
    flex-direction: column;

    dl {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      dt {
        // flex: 2;
        width: 200px;
        display: flex;
        align-items: center;
      }

      dd {
        // flex: 8
        width: calc(100% - 200px);
      }
    }
  }

  .top {
    font-size: 16px;

    dd {
      display: flex;
      flex-direction: row;

      li {
        flex: 1;
        display: flex;
        flex-direction: column;

        p {
          display: flex;
          flex-direction: row;
          align-items: center;
        }


      }

      li:nth-child(2) {
        span:nth-child(1) {
          width: 160px;
        }
      }
    }

  }

  .center {
    border-bottom: 1px solid gray;
    border-top: 1px solid gray;
    padding-bottom: 20px;

    dl {
      flex: 9;
    }

    dd {
      display: flex;
      flex-direction: column;

      li {
        display: flex;
        flex-direction: row;

        p {
          margin-right: 30px;
          display: flex;
          flex-direction: column;

          span:nth-child(1) {
            font-size: 16px;
            width: 125px;
            color: gray;
          }

          span:nth-child(2) {
            color: blue;
            font-size: 32px;
            display: flex;
            margin-top: 10px;
            justify-content: center;
          }
        }
      }
    }

    .footername {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;

      span {
        flex: 1;
        color: blue;
      }
    }
  }


  .bottom {
    display: flex;
    flex-direction: column;
    font-size: 16px;

    h4 {
      padding: 20px 0;
    }
  }

  .center,
  .bottom.top {
    flex: 3;

  }

  .footer {
    flex: 1;
    display: flex;
    flex-direction: column;
    font-size: 16px;

    li {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      p {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}

.listname {
  display: flex;
  flex-direction: row;
  height: 120px;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 20px;
  border: 1px solid gray;
  list-style: none;
  border-radius: 15px;



  .dl {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 20px;

    p {
      flex: 1;
      display: flex;
      justify-content: center;
      font-size: 18px;
    }

    p:nth-child(1) {
      color: gray;
      font-size: 16px;
    }

  }


  .dl:last-child {
    margin-right: 0;
  }
}
</style>
