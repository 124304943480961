<template>
  <div>
    <!-- 卡片视图区域 运营人员-->
    <el-card>
      <!-- 添加角色按钮区域  -->
      <el-row :gutter="20" style="margin-bottom: 10px;">
        <el-col :span="4" style="display: flex;align-items: center;flex-direction: row;">
          <span style="flex:3">券名称:</span>
          <el-input style="flex:7" placeholder="请输入券名称:" clearable v-model="queryInfo.name" @clear="getRolesList"
            @change="current">
          </el-input>
        </el-col>
        <!-- <el-col :span="4" style="display: flex;align-items: center;flex-direction: row;">
          <span style="flex:3">券ID:</span>
          <el-input style="flex:7" placeholder="请输入券ID:" clearable v-model="queryInfo.activeId" @clear="getRolesList"
            @change="current">
          </el-input>
        </el-col> -->
        <el-col :span="4" style="display: flex;align-items: center;flex-direction: row;">
          <span style="flex:3">优惠对象:</span>
          <el-input style="flex:7" placeholder="请输入优惠对象:" clearable v-model="queryInfo.userNumber" @clear="getRolesList"
            @change="current">
          </el-input>
        </el-col>
        <!-- <el-col :span="4" style="display: flex;align-items: center;flex-direction: row;">
          <span style="flex:3">优惠类型:</span>
          <el-select style="flex:7" v-model="queryInfo.type" placeholder="请选择优惠类型" filterable clearable
            @clear="getRolesList" @change="current">
            <el-option v-for="item in Typelist" :key="item.type" :label="item.name" :value="item.type">
            </el-option>
          </el-select>
        </el-col> -->
        <el-col :span="4" style="display: flex;align-items: center;flex-direction: row;">
          <span style="flex:3">优惠类型:</span>
          <el-select style="flex:7" v-model="queryInfo.preferentialType" placeholder="请选择优惠类型" filterable clearable
            @clear="getRolesList" @change="current">
            <el-option v-for="item in zhuangTypelist" :key="item.preferentialType" :label="item.stationName"
              :value="item.preferentialType">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" icon="el-icon-search" @click="getRolesList">查询</el-button>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" icon="el-icon-refresh-left" @click="chongzhi">重置</el-button>
        </el-col>
        <!-- <el-col :span="3">
          <el-button type="primary" icon="el-icon-refresh-left" @click="chongzhi">创建优惠券</el-button>
        </el-col> -->
      </el-row>
      <!-- 角色列表区域 -->
      <el-table :data="rolesList" border empty-text="暂无数据">
        <!-- <el-table-column align="center" label="序列" prop="id"></el-table-column> -->
        <el-table-column align="center" label="优惠对象" prop="userNumber" width="120"></el-table-column>
        <el-table-column align="center" label="卡券金额" prop="money" width="100">
          <template slot-scope="scope">
            <div>
              ￥{{ scope.row.money == null || scope.row.money == undefined ? 0 : scope.row.money }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="订单满足金额" prop="fullAmount" width="110">
          <template slot-scope="scope">
            <div>
              ￥{{ scope.row.fullAmount == null || scope.row.fullAmount == undefined ? 0 : scope.row.fullAmount }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="优惠类型" prop="money" width="120">
          <template slot-scope="scope">
            <span>
              <!-- 三元判断 -->
              {{
        scope.row.preferentialType == 0 ? '充值抵扣券' : scope.row.preferentialType == 1 ? '服务费抵扣券' :
          scope.row.preferentialType == 2 ? '电费抵扣券' : scope.row.preferentialType == 3 ? '总费用抵扣券' :
            scope.row.preferentialType == 4 ? '新人抵扣券' : scope.row.preferentialType == 5 ? '服务费折扣' :
              scope.row.preferentialType == 6 ? '电费折扣' : '总费用折扣'
      }}
            </span>
          </template>
        </el-table-column>
        <!-- /折扣 -->
        <el-table-column align="center" label="优惠金额" prop="discountRate" width="100">
          <template slot-scope="scope">
            <!-- 条件判断 -->
            <span
              v-if="scope.row.preferentialType !== 5 && scope.row.preferentialType !== 6 && scope.row.preferentialType !== 7">￥</span>
            <span>
              {{ jisuan(scope.row.discountRate, 100, 2) }}
            </span>
            <span
              v-if="scope.row.preferentialType == 5 || scope.row.preferentialType == 6 || scope.row.preferentialType == 7">折</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="有效期" prop="dayNums" width="200">
          <template slot-scope="scope">
            <!-- 条件判断 -->
            <div>
              <p> {{ scope.row.startTime }}~</p>
              <p>{{ scope.row.endTime }}</p>

            </div>
            <!-- <div>
              领取后{{  }}天内可用
            </div> -->
          </template>
        </el-table-column>
        <el-table-column align="center" label="购买单号" prop="billCode"></el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTime"></el-table-column>
        <el-table-column align="center" label="券状态" prop="state" width="100">
          <template slot-scope="scope">
            <el-tag :type="scope.row.state == 0 ? 'default' : scope.row.state == 1 ? 'danger' : 'warning'">
              {{ scope.row.state == 0 ? '待使用' : scope.row.state == 1 ? '已失效' : '已使用' }}
            </el-tag>
          </template>
        </el-table-column>
        <!-- <el-table-column align="left" label="操作" width="300" v-if="caozuoquanxian">
          <template slot-scope="scope">
            <div style="display: flex;flex-direction: row;position: relative;">
              <p><span style="padding-right: 15px;color: blue;">详情</span></p>
              <p style="padding-right: 15px;color: blue;">|</p>
              <p><span style="padding-right: 15px;color: blue;">发放</span></p>
              <p style="padding-right: 15px;color: blue;">|</p>
              <p><span style="padding-right: 15px;color: blue;">社群送券</span></p>
              <p style="padding-right: 15px;color: blue;">|</p>
              <p><span style="padding-right: 5px;color: blue;">更多</span> <i
                  :class="scope.row.typeIngs ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i></p>
            </div>
            <div v-if="scope.row.typeIngs"
              style="position: relative;width: 80px;padding-left: 10px;right: 5%;bottom: 0;border: 1px solid #c5c5c5;border-top: 0;border-radius: 5px;">
              <p :style="{ color: activeIndex == 0 ? 'red' : '#000' }" style="line-height: 20px;">追加库存</p>
              <p :style="{ color: activeIndex == 1 ? 'red' : '#000' }" style="line-height: 20px;">发放记录</p>
              <p :style="{ color: activeIndex == 2 ? 'red' : '#000' }" style="line-height: 20px;">下架</p>
            </div>
          </template>
        </el-table-column> -->
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.page" :page-sizes="[8, 20, 50, 100]" :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="total" background>
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import caozuo from "@/utils/pubilc.js";
export default {
  name: 'roleWorld',
  data() {
    return {
      activeIndex: '',
      caozuoquanxian: false,
      newarr: [],
      quxiaoarr: [],
      arr: [],
      hr: '',
      fen: false,
      flag: false,
      del_btn: true,
      user_title: '',
      dialoag: false,
      queryInfo: {
        preferentialType: '',
        name: '',
        type: '',
        stationIds: window.sessionStorage.getItem('stationIds'),
        page: 1,
        limit: 8,
        adminId: window.sessionStorage.getItem('adminId'),
        userNumber:''
      },
      total: null,
      title: '',
      editForm: {
        rolename: null,
        // roleType: null,
        roleTypeName: null,
        des: null,
        id: null
      },
      editFormRules: {
        rolename: [
          { required: true, message: '角色名称不能为空', trigger: 'blur' }
        ]
        // ,
        // roleTypeName: [
        //   { required: true, message: '角色类型不能为空', trigger: 'blur' }
        // ]
      },
      shanchuarr: [],
      editDialogVisible: false,
      // 所有角色列表数据
      rolesList: [],
      // 角色类型数据
      // roleTypeList: [
      //   { roleType: 0, roleTypeName: '管理员' },
      //   { roleType: 1, roleTypeName: '商户' },
      //   { roleType: 2, roleTypeName: '运营商(编辑)' },
      //   { roleType: 3, roleTypeName: '运营商(只读)' },
      //   { roleType: 4, roleTypeName: '发卡' },
      //   { roleType: 5, roleTypeName: '保留' },
      //   { roleType: 6, roleTypeName: '保留' },
      //   { roleType: 7, roleTypeName: '保留' },
      //   { roleType: 9996, roleTypeName: '销售' },
      //   { roleType: 9997, roleTypeName: '平台财务' },
      //   { roleType: 9998, roleTypeName: '普通管理员' },
      //   { roleType: 9999, roleTypeName: '售后' }
      // ],
      // 控制分配权限对话框的显示与隐藏
      powerDialogVisible: false,
      // 所有权限数据
      powerList: [],
      // 角色对应权限数据
      roleLogList: [],
      // 树形控件的属性绑定对象
      treeProps: {
        label: 'menuname',
        children: 'childList'
      },
      // 默认选中节点Id值的数组
      defKeys: [],
      ids: null,
      sttt: null,
      aaa: [],
      ntr: [],
      shuju: {
        name: null,
        page: 1,
        limit: 8
      },
      Typelist: [
        {
          type: 0,
          name: '全部'
        },
        {
          type: 1,
          name: '电费'
        },
        {
          type: 2,
          name: '电费服务费'
        }
      ],
      zhuangTypelist: [
      {
          preferentialType: 0,
          stationName: '充值抵扣券'
        },
        {
          preferentialType: 1,
          stationName: '服务费抵扣券'
        },
        {
          preferentialType: 2,
          stationName: '电费抵扣券'
        },
        {
          preferentialType: 3,
          stationName: '总费用抵扣券'
        },
        {
          preferentialType: 4,
          stationName: '新人送抵扣券'
        },
        // {
        //   preferentialType: 5,
        //   stationName: '服务费折扣'
        // },
        // {
        //   preferentialType: 6,
        //   stationName: '电费折扣'
        // },
        // {
        //   preferentialType: 7,
        //   stationName: '总费用折扣'
        // }


        // {
        //   preferentialType: 1,
        //   stationName: '服务费'
        // },
        // {
        //   preferentialType: 2,
        //   stationName: '电费'
        // },
        // {
        //   preferentialType: 3,
        //   stationName: '总费用'
        // },
        // {
        //   preferentialType: 4,
        //   stationName: '新人送抵扣券'
        // }
      ]
    }
  },

  computed: {
    jisuan() {
      return (val, num, fixed) => {
        return (val / num).toFixed(fixed)
      }
    }
  },

  watch: {
    defKeys: {
      handler(newval) {
        this.defKeys = newval
      }
    }
  },

  created() {
    caozuo(148).then(res => {
      this.caozuoquanxian = res;
    });
    this.getRolesList();
    if (this.ids != null) {
      this.del_btn = !this.del_btn
    } else {
      this.del_btn = true
    };
    this.default_value();
  },

  methods: {
    current() {
      this.queryInfo.page = 1;
    },
    chongzhi() {
      this.queryInfo = {
        preferentialType: '',
        billCode: '',
        stationIds: window.sessionStorage.getItem('stationIds'),
        page: 1,
        limit: 8,
        adminId: window.sessionStorage.getItem('adminId') * 1
      };
      this.getRolesList()
    },
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize
      this.getRolesList();
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getRolesList();
    },

    setPowerDialogClosed() {
      this.dialoag = false;
    },
    checkCange(data, checked) {
      this.quxiaoarr = this.defKeys;
      if (checked == true) {
        this.arr.push(data.id);
      } else {
        this.shanchuarr.push(data.id);
      };
      if (this.shanchuarr.length > 0) {
        this.arr = this.quxiaoarr.filter(item => !this.shanchuarr.includes(item))
      } else {
        let ntr = this.arr;
        this.arr = Array.from(new Set(this.quxiaoarr.concat(ntr)));
      };
      this.hr = this.arr.map(item => item).join(',');
    },
    async default_value() {
      // 获取所有权限的数据
      const { data: res } = await this.$http.get('adminMenu/getAllAdminMenu', {
        params: this.shuju
      })
      this.powerList = res.data;
      this.powerList.forEach(item => {
        item.childList.forEach(ite => {
          ite.childList = ite.childList3
        })
      });
    },
    // 获取所有角色的列表
    async getRolesList() {
      const { data: res } = await this.$http.get('coupons/getAllCoupons', { params: this.queryInfo })
      if (res.code !== 0) {
        return this.$message.error(res.msg)
      }
      this.rolesList = res.data;
      this.total = res.count;
      this.rolesList.forEach(item => {
        if (item.state !== 1) {
          item.disabl = false;
        } else {
          item.disabl = true;
        }
        item.usecharge = (item.usecharge / 100).toFixed(2);
        item.chargenum = (item.chargenum / 100).toFixed(2);
        item.money = (item.money / 100).toFixed(2)
      });
    },
    // 复选框的改变事件
    handleSelectionChange(value) {
      this.ids = '';
      if (value.length > 0) {
        if (value.length > 1) {
          this.del_btn = false;
          value.forEach(item => {
            this.ids += item.id + ','
          })
          this.ids = this.ids.substring(0, this.ids.length - 1)
        } else {
          this.ids = value[0].id
        }
      } else {
        this.del_btn = true;
      }
    },
    // 根据id删除对应的角色

    // 失效
    async removeRoleById(row) {
      const confirmResult = await this.$confirm('此操作将导致该优惠彻底失效, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        row.disabl = false;
        this.$message.info('已取消失效操作')
      } else {
        if (row.state == '0') {
          row.state = '1'
        } else if (row.state == '2') {
          row.state = '0'
        };
        const { data: res } = await this.$http.post('coupons/updCoupons', {
          id: row.id,
          state: row.state,
          adminId: window.sessionStorage.getItem('adminId') * 1
        })
        if (res.code == 0) {
          this.$message.success(res.msg);
          row.state = res.data.state;
          if (row.state == 1) {
            row.disabl = true;
            this.getRolesList()
          } else {
            row.disabl = false;
            this.getRolesList()
          }
        } else {
          this.$message.error(res.msg)
        }
      }

    },
  }
};
</script>

