<template>
  <div class="totallist">
    <el-card>
      <!-- <el-row :gutter="20">
        <el-col>
          <span>引流平台</span>
          <el-select filterable v-model="queryinfo.name" placeholder="请选择引流平台" @clear="getOnAccountList"
            @change="current">
            <el-option v-for="item in listname" :key="item.name" :label="item.name" :value="item.name"></el-option>
          </el-select>
        </el-col>
      </el-row> -->
      <el-table :data="List" border width="80%">
        <el-table-column align="center" label="引流平台" prop="name"></el-table-column>
        <el-table-column align="center" label="开通时间" prop="userNumber">
          <template slot-scope="scope">
            <span v-if="scope.row.openingTime != null">{{ scope.row.openingTime }}</span><span v-else>{{
        scope.row.createTime }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="付款金额" prop="changMoney">
          <template slot-scope="scope">
            <span>{{ scope.row.money }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="申请时间" prop="billCode">
          <template slot-scope="scope">
            <span>{{ scope.row.createTime != null ? scope.row.createTime : '---' }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="状态" prop="des">
          <template slot-scope="scope">
            <span>{{ scope.row.status == 0 ? '待开通' : '已开通' }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope" v-if="scope.row.status == 0">
            <el-button round @click="shenhe(scope.row)">审核开通</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="querinfo.page"
        :page-sizes="[8, 20, 50, 100]" :page-size="querinfo.limit" layout="total, sizes, prev, pager, next, jumper"
        :total="total" background>
      </el-pagination>
    </el-card>
  </div>
</template>
<script>
export default {
  name: '',
  mixins: [],
  components: {

  },
  props: {

  },
  data() {
    return {
      total: 0,
      listname: [],
      List: [],
      querinfo: {
        page: 1,
        name: '',
        limit: 8
      },
      total: 0
    }
  },
  computed: {

  },
  watch: {

  },
  created() {
    this.listMethods()
  },
  mounted() {

  },
  methods: {
    // 审核开通
    shenhe(e) {
      const that = this;
      that.$http.post('drainage/editDrainage', e).then(res => {
        if (res.data.code !== 0) return that.$message.error('开通失败');
        that.$confirm('审核通过，请及时为其开通。', '审核通过', {
          confirmButtonText: '确定'
        }).then(() => {
          that.listMethods()
        })
      })
    },
    listMethods() {
      const that = this;
      that.$http.get('drainage/getAllDrainage', {
        params: that.querinfo
      }).then(res => {
        if (res.data.code !== 0) return;
        that.List = res.data.data;
        that.total = res.data.count;
      })
    },
    // 检索
    current() {

    },
    // 检索
    getOnAccountList() {

    },
    handleSizeChange(newSize) {
      this.querinfo.limit = newSize;
      this.listMethods()
    },
    handleCurrentChange(newPage) {
      this.querinfo.page = newPage;
      this.listMethods()
    }
  }
};
</script>

<style lang='less' scoped>
.totallist {
  width: 100%;
  height: 100%
}
</style>