<template>
  <div>
    <!-- 卡片视图区域 直充-->
    <el-card>
      <!-- style="margin-bottom:20px ;" -->
      <el-row :gutter="20" style="margin-bottom: 10px;">
        <!-- <el-col :span="3" style="display: flex;flex-direction: row;align-items: center;">
          <span style="flex:2;font-size: 16px;">商户</span>
          <el-select style="flex:8" v-model="suoshudianzhan" @change="change_value" filterable>
            <el-option v-for="item in powerStationList" :key="item.id" :label="item.merchantName" :value="item.id">
            </el-option>
          </el-select>
        </el-col> -->
        <!-- <el-col :span="2">
          <el-button type="primary" icon="el-icon-search" @click="defaultMethods">查询</el-button>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" icon="el-icon-refresh-left" @click="chongzhi">重置</el-button>
        </el-col> -->
        <!-- <el-col :span="2">
          <el-button type="primary" icon="el-icon-refresh-left" @click="daochu">导出</el-button>
        </el-col> -->
        <el-col :span="3" v-if="addVS">
          <el-button type="success" icon="el-icon-plus" @click="showVinAccount">新增商户</el-button>
        </el-col>
      </el-row>
      <!-- table表格区域 -->
      <el-table :data="vinAccountList" border>
        <el-table-column align="center" label="商户名称" prop="merchantName"></el-table-column>
        <el-table-column align="center" label="真实姓名" prop="trueName"></el-table-column>
        <el-table-column align="center" label="收费类型" prop="type">
          <template scope="scope">
            <span>{{ scope.row.type == 0 ? '服务费' : scope.row.type == 1 ? '全额消费' : '全额电费' }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="入账时间" prop="type">
          <template scope="scope">
            <span>{{ scope.row.type == 0 ? '服务费' : scope.row.type == 1 ? '全额消费' : '全额电费' }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="清分状态" prop="type">
          <template scope="scope">
            <span>{{ scope.row.type == 0 ? '服务费' : scope.row.type == 1 ? '全额消费' : '全额电费' }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="平台收取金额（元）" prop="money">
          <template slot-scope="scope">
            <span v-if="scope.row.money !== null">
              {{ jisuan(scope.row.money, 100, 2) }}
            </span><span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="余额（元）" prop="money">
          <template slot-scope="scope">
            <span v-if="scope.row.money !== null">
              {{ jisuan(scope.row.money, 100, 2) }}
            </span><span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="结算类型" prop="type">
          <template scope="scope">
            <span>{{ scope.row.type == 0 ? '服务费' : scope.row.type == 1 ? '全额消费' : '全额电费' }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="状态" prop="electricCardState">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status" :active-color="scope.row.status == 1 ? '#ff4949' : '#13ce66'"
              :inactive-color="scope.row.status == 1 ? '#ff4949' : '#13ce66'"
              @change="change_type(scope.row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" label="更新时间" prop="updateTime">
          <template slot-scope="scope">
            <span v-if="scope.row.updateTime !== null">
              {{ scope.row.updateTime }}
            </span><span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column align="left" label="操作（禁用状态下不可提现）" v-if="caozuoquanxian" width="300">
          <template slot-scope="scope">
            <el-button size="small" type="primary" icon="el-icon-edit" :disabled="scope.row.b_j_anniu"
              @click="showVinAccount(scope.row)" v-if="addVS">编辑</el-button>
            <el-button size="small" type="error" :disabled="scope.row.b_j_anniu"
              @click="detailMethods(scope.row)">平台抽成明细</el-button>
            <!-- <el-button size="small" type="error" :disabled="scope.row.b_j_anniu"
              @click="detailMethods(scope.row, 0)">账单明细</el-button>
            <el-button size="small" type="warning" :disabled="scope.row.b_j_anniu"
              @click="detailMethods(scope.row, 1)">分佣明细</el-button> -->
            <!-- <el-button size="small" type="danger" @click="removeById(scope.row)">删除</el-button> -->
          </template>
        </el-table-column>
        <div slot="empty" class="empty">
          <span>暂无数据</span>
        </div>
      </el-table>
      <!-- 分页导航区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.page" :page-sizes="[6, 20, 50, 100]" :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="total" background>
      </el-pagination>
    </el-card>
    <!-- 添加&&编辑商户的对话框 -->
    <el-dialog :title="title" :visible.sync="editDialogVisible1" width="35%" @close="editDialogClosed" append-to-body
      :close-on-click-modal="false">
      <el-form :model="vinAccountForm" :rules="vinAccountFormRules" ref="vinAccountFormRef" label-width="80px">
        <el-form-item label="商户名称" prop="merchantName">
          <el-select v-model="vinAccountForm.merchantName">
            <el-option v-for="item in merchantNameList" :key="item.trueName" :value="item.trueName"
              :label="item.trueName"></el-option>
          </el-select>
          <!-- <el-input v-model="vinAccountForm.merchantName" style="width: 40%;"></el-input> -->
        </el-form-item>
        <el-row type="flex" class="row-bg" justify="space-around" :gutter="24">
          <el-col :span="12">
            <!-- 内容主体区域 -->
            <el-form-item label="收费类型" prop="type">
              <el-select v-model="vinAccountForm.type">
                <el-option v-for="item in stationList" :key="item.type" :value="item.type"
                  :label="item.typeName"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="真实姓名" prop="trueName">
              <el-input v-model="vinAccountForm.trueName" placeholder="请输入真实姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="费率" prop="serviceRate">
              <el-input-number v-model="vinAccountForm.serviceRate" controls-position="right" :min="0"
                :max="100"></el-input-number>
              <span style="margin-left: 8px;">%</span>
            </el-form-item>
            <el-form-item label="联系电话" prop="phone">
              <el-input v-model="vinAccountForm.phone" placeholder="请输入电话号码"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="备注说明" prop="des">
          <el-input v-model="vinAccountForm.des" placeholder="请输入备注说明" style="width: 40%;"></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogClosed">取 消</el-button>
        <el-button type="primary" v-debounce="editVinAccount" :disabled="btn">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 平台抽成明细 -->
    <el-dialog :visible.sync="dialoagBtn" title="平台抽成明细" width="80%" @close="MoneyDialoag2" append-to-body
      :close-on-click-modal="false">
      <div class="listname">
        <div class="dl" v-for="(item, index) in listall" :key="index">
          <p>
            {{ item.name }}
          </p>
          <p>
            ￥ {{ jisuan(item.countMoney, 100, 2) }}
          </p>
        </div>
      </div>
      <el-table :data="List" border>
        <el-table-column align="center" label="商户名称" prop="merchantName"></el-table-column>
        <el-table-column align="center" label="初始金额" prop="startMoney" width="100">
          <template slot-scope="scope">
            <span>￥{{ jisuan(scope.row.startMoney, 100, 2) }}</span>
          </template>
        </el-table-column>
        <!-- 分佣比例 -->
        <!-- <el-table-column align="center" label="分佣比例" prop="type">
          <template scope="scope">
            <span>{{ scope.row.type == 0 ? '服务费' : scope.row.type == 1 ? '全额消费' : '全额电费' }}</span>
          </template>
        </el-table-column> -->
        <el-table-column align="center" label="变动金额" prop="changeMoney" width="100">
          <template slot-scope="scope">
            <span>￥{{ jisuan(scope.row.changeMoney, 100, 2) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="余额" prop="newMoney" width="100">
          <template slot-scope="scope">
            <span>￥{{ jisuan(scope.row.newMoney, 100, 2) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="抽成类型" prop="commissionType" width="100">
          <template slot-scope="scope">
            <el-tag>{{
        scope.row.commissionType == 0 ? '服务费' : scope.row.commissionType == 1 ? '全额消费' : '全额电费' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="抽成费用" prop="commissionMoney" width="100">
          <template slot-scope="scope">
            <span>￥{{ jisuan(scope.row.commissionMoney, 100, 2) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="流水单号" prop="billCode" width="300"></el-table-column>
        <el-table-column align="center" label="服务费" prop="servicemoney" width="100">
          <template slot-scope="scope">
            <span>
              ￥{{ jisuan(scope.row.servicemoney, 100, 2) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="订单金额" prop="orderMoney" width="100">
          <template slot-scope="scope">
            <span>
              ￥{{ jisuan(scope.row.orderMoney, 100, 2) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="备注" prop="des"></el-table-column>
        <el-table-column align="center" label="入账时间" prop="updateTime" width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.updateTime !== null">
              {{ scope.row.updateTime }}
            </span><span v-else>--</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange3" @current-change="handleCurrentChange3"
        :current-page="indexobj.page" :page-sizes="[8, 20, 50, 100]" :page-size="indexobj.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="total3" background>
      </el-pagination>
    </el-dialog>
    <!-- 二维码 -->
    <el-dialog :visible.sync="imageBtn" title="二维码" width="80%" @close="colse2">
      <vue-qr :text='url' style="height: 22vh"></vue-qr>
    </el-dialog>
  </div>
</template>

<script>
import caozuo from "@/utils/pubilc.js";
import VueQr from 'vue-qr';
import { saveBlobToFile } from '@/utils/saveBlobToFile';
export default {
  name: 'vinAccountWorld',
  data() {
    return {
      addVS: false,
      btn: false,
      imageBtn: false,
      title: '',
      url: '',
      titles: '',
      powerStationList: [],
      suoshudianzhan: '', List: [],
      indexobj: {
        page: 1,
        limit: 8,
        totalLedgerId: 0
      },
      DetailBtn: false,
      Detaildialoag: false,
      dialoagBtn: false,
      Moneylist: [],
      total3: 0,
      carInfo: {

      },
      moren: {

      },
      list3: [],
      listindex: 0,
      tupian: this.$imgUrls,
      listobj: {

      },
      listindex: 0,
      listname: [
        '查看充值记录',
        '查看退款记录',
        '查看赠送金记录',
        '查看即充即退记录',
        '查看绑定金额记录',
        '查看充电记录',
      ],
      editDialogVisible1: false,
      editDialogVisible2: false,
      caozuoquanxian: false,
      // 获取vin用户列表所需参数
      queryInfo: {
        adminId: window.sessionStorage.getItem('adminId'),
        stationLedgerId: 0,
        page: 1,
        limit: 6
      },
      // vin用户数据及参数
      vinAccountList: [],
      vinAccountForm: {
        id: '',
        merchantName: '',
        trueName: '',
        type: '',
        des: '',
        phone: '',
        serviceRate: ''
      },
      vinAccountFormRules: {
        trueName: {
          required: true, message: '请输入真实姓名', trigger: 'blur'
        },
        merchantName: {
          required: true, message: '请输入商户名称', trigger: 'change'
        },
        type: {
          required: true, message: '请选择收费类型', trigger: 'change'
        },
        serviceRate: {
          required: true, message: '请输入或选择费率', trigger: 'blur'
        },
        phone: {
          required: true, message: '请输入联系电话', trigger: 'blur'
        }
      },
      total: 0,
      title: '',
      value: '',
      editDialogVisible: false,
      // 电站数据
      powerStationOptions: [],
      // 电站所属公司数据
      companyOptions: [
        {
          value: 1,
          label: '公交一公司'
        },
        {
          value: 2,
          label: '公交二公司'
        },
        {
          value: 3,
          label: '公交三公司'
        },
        {
          value: 4,
          label: '公交四公司'
        },
        {
          value: 5,
          label: '焦作市龙源巴士'
        }
      ],
      listall: [],
      powerOptions: [],
      ids: '',
      merchantNameList: [],
      stationList: [
        {
          type: 0,
          typeName: '服务费'
        },
        {
          type: 1,
          typeName: '全额消费'
        }
      ],
      goMoneyDialoag: false
    }
  },
  computed: {
    shiji() {
      return function (abc, bac) {
        return (((abc * 1) + (bac * 1)) / 100).toFixed(2)
      }
    },
    wait_s() {
      return function (e) {
        return ((e.money - e.giveMoney) / 100).toFixed(2)
      }
    },
    time_all() {
      return function (val) {
        const hr = val.billCode.slice(val.pileCode.length, val.billCode.length)
        return hr
      }
    },
    jisuan() {
      return function (obj, num, fixed) {
        return (obj / num).toFixed(fixed)
      }
    },
    shifu() {
      return function (val) {
        return ((val.billMoney - val.billMoneygive) / 100).toFixed(2)
      }
    }
  },
  created() {
    caozuo(100).then(res => {
      this.caozuoquanxian = res;
    });
    // 清分商户添加、编辑权限
    caozuo(183).then(res => {
      this.addVS = res;
    })
    this.$http.get(`admin/getAllAdminName/${window.sessionStorage.getItem('roleId')==1?5:window.sessionStorage.getItem('roleId')}`).then(res => {
      if (res.data.code !== 0) return;
        res.data.data.forEach(item => {
        this.merchantNameList.push({
          trueName: item.realname
        })
      })
    });
    this.defaultMethods()
  },
  methods: {
    colse2() {
      this.imageBtn = false;
    },
    // 导出（记得更改参数）
    daochu() {
      if (this.queryInfo.startTime != null && this.queryInfo.endTime != null && this.queryInfo.pileCode != null) {
        this.$http.get('station-total-ledger-log/export', {
          params: {
            startTime: this.queryInfo.startTime,
            endTime: this.queryInfo.endTime,
            pileCode: this.queryInfo.pileCode
          },
          responseType: 'blob'
        }).then(res => {
          saveBlobToFile(res.data, '电桩分析')
          if (res.data) {
            this.$message.success('文件导出成功')
          } else {
            this.$message.error('文件导出失败')
          }
        })
      } else {
        this.$message.error('请选择你要导出的电桩与时间')
      }
    },
    defaultMethods() {
      const that = this;
      that.$http.get('station-total-ledger/getAllStationTotalLedger', {
        params: that.queryInfo
      }).then(ress => {
        if (ress.data.code !== 0) return;
        that.vinAccountList = ress.data.data;
        that.total = ress.data.count;
        that.powerStationList = ress.data.data;
        that.suoshudianzhan = ress.data.data[0].id;
        that.queryInfo.stationLedgerId = that.suoshudianzhan;
      })
    },
    change_value() {

    },
    // 历史记录
    handleSizeChange3(newSize) {
      this.indexobj.limit = newSize;
      this.getIndex()
    },// 历史记录
    handleCurrentChange3(newPage) {
      this.indexobj.page = newPage;
      this.getIndex()
    },
    getIndex() {
      const that = this; that.list3 = [];
      that.$http.get(`station-total-ledger/getStationTotalLedgerLog?totalLedgerId=${that.indexobj.totalLedgerId}&page=${that.indexobj.page}&limit=${that.indexobj.limit}`).then(res => {
        if (res.data.code !== 0) return;
        that.List = res.data.data;
      })
    },
    detailMethods(e) {
      const that = this;
      that.dialoagBtn = true;
      that.indexobj.totalLedgerId = e.id;
      that.$http.get(`station-total-ledger/getStationTotalLedgerLog?totalLedgerId=${that.indexobj.totalLedgerId}&page=${that.indexobj.page}&limit=${that.indexobj.limit}`).then(res => {
        if (res.data.code !== 0) return;
        that.List = res.data.data;
        that.total3 = res.data.count;
        that.listall = [
          {
            name: '入账总金额',
            countMoney: res.data.data[0].postingTotalMoney
          },
          {
            name: '抽成总金额',
            countMoney: res.data.data[0].commissionMoney
          },
          {
            name: '本月入账总金额',
            countMoney: res.data.data[0].postingMonthTotalMoney
          },
          {
            name: '本月抽成总金额',
            countMoney: res.data.data[0].commissionMonthTotalMoney
          },
          {
            name: '今日入账总金额',
            countMoney: res.data.data[0].postingToDayTotalMoney
          },
          {
            name: '今日抽成总金额',
            countMoney: res.data.data[0].commissionToDayTotalMoney
          }
        ]
      })
    },
    MoneyDialoag2() {
      this.dialoagBtn = false;
    },
    // listMethods() {
    //   const that = this;
    //   that.indexobj.roleId = window.sessionStorage.getItem('roleId');
    //   that.indexobj.stationIds = window.sessionStorage.getItem('stationIds');
    //   that.indexobj.gunState = '0,3,4,5,6,7';
    //   that.$http.get('bill-info/getAllBillInfo', {
    //     params: that.indexobj
    //   }).then(ress => {
    //     if (ress.data.code !== 0) return;
    //     that.list3 = ress.data.data;
    //     that.total3 = ress.data.count;
    //     that.moren = {
    //       totalBillMoney: ress.data.data[0].totalBillMoney,
    //       totalChargeMoney: ress.data.data[0].totalChargeMoney,
    //       totalDiscountMoney: ress.data.data[0].totalDiscountMoney,
    //       totalServiceMoney: ress.data.data[0].totalServiceMoney,
    //       totalBillKwhs: ress.data.data[0].totalBillKwhs,
    //       totalLengthTime: ress.data.data[0].totalLengthTime,
    //       totalBillCount: ress.data.data[0].totalBillCount,
    //     }
    //   })
    //   that.dialoagBtn = true;
    // },
    current() {
      this.queryInfo.page = 1;
    },
    chongzhi() {
      this.queryInfo = {
        stationLedgerId: 0,
        page: 1,
        limit: 8
      };
      this.defaultMethods();
    },
    // 获取vin用户列表
    async change_type(row) {
      this.vinAccountForm.id = row.id;
      // console.log(row.type,'208')
      if (row.type == false) {
        row.type = true;
        row.electricCardState = 1;
      } else {
        row.type = false;
        row.electricCardState = 0;
      };
      this.vinAccountForm.electricCardState = row.electricCardState;
      await this.$http.post(`charge-vin/updateStatusById/${row.id}/${this.vinAccountForm.electricCardState}`).then(res => {
        this.$message.success(res.data.msg)
        this.defaultMethods();
      }).catch(res => {
        this.$message(res.data.msg)
      })
    },
    // 双击展开事件
    dbclick(row, column, event) {
      if (event.currentTarget.querySelector('.el-table__expand-icon')) {
        event.currentTarget.querySelector('.el-table__expand-icon').click()
      }
    },
    // 监听pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize;
      this.defaultMethods()
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.defaultMethods()
    },
    // 展示添加&编辑商户对话框
    showVinAccount(row) {
      const that = this;
      that.vinAccountForm = {};
      that.editDialogVisible1 = true;
      if (row.id === undefined || row.id === null) {
        that.title = '添加商户'
      } else {
        that.title = '编辑商户';
        that.$http.get('station-total-ledger/getAllStationTotalLedger', {
          params: {
            id: row.id,
            adminId: window.sessionStorage.getItem('adminId')
          }
        }).then(res => {
          if (res.data.code !== 0) return;
          that.vinAccountForm = res.data.data[0];
        })
      }
    },
    // 添加&编辑商户事件
    editVinAccount() {
      this.$refs.vinAccountFormRef.validate(async valid => {
        if (!valid) return;
        this.vinAccountForm.adminId = window.sessionStorage.getItem('adminId') * 1;
        // if(this.vinAccountForm.merchantName=='')return this.$message.error('请输入商户名称');
        // if(this.vinAccountForm.serviceRate=='')return this.$message.error('请输入商户费率');
        // if(this.vinAccountForm.phone=='')return this.$message.error('请输入联系电话');
        // if(this.vinAccountForm.trueName=='')return this.$message.error('请输入真实姓名');
        // if(this.vinAccountForm.type=='')return this.$message.error('请选择收费类型');
        this.btn = true;
        const { data: res } = await this.$http.post('station-total-ledger/editStationTotalLedger', this.vinAccountForm)
        if (res.code !== 0) return this.$message.error(res.msg);
        this.btn = res.code !== 0 ? true : false;
        if (window.sessionStorage.getItem('roleId') !== 1 && res.code == 0 && this.title == '添加商户') {
          // window.sessionStorage.setItem('stationTotalLedgerListId',)
        }
        this.$message.success(`${this.title}成功！`);
        this.defaultMethods();
        this.editDialogVisible1 = false;
      })
    },
    // 添加&编辑商户对话框关闭事件
    editDialogClosed() {
      this.$refs.vinAccountFormRef.resetFields();
      this.editDialogVisible1 = false;
    },
    // 批量删除的复选框改变事件
    handleSelectionChange(value) {
      if (value.length > 0) {
        this.ids = ''
        value.forEach(item => {
          this.ids += item.id + ','
        })
        this.ids = this.ids.substring(0, this.ids.length - 1)
      } else {
        return false
      }
    },
    // 删除vin用户方法
    async removeById(row) {
      const confirmResult = await this.$confirm('此操作将永久删除该VIN用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消删除！')
      }
      if (row.id === null || row.id === '' || row.id === undefined) {
        const { data: res } = await this.$http.post('vin/delVinById/' + this.ids)
        if (res.code !== 0) {
          return this.$message.error('删除VIN用户失败！')
        }
        this.$message.success('删除VIN用户成功！')
        this.defaultMethods()
      } else {
        const { data: res } = await this.$http.post('vin/delVinById/' + row.id)
        if (res.code !== 0) {
          return this.$message.error('删除VIN用户失败！')
        }
        this.$message.success('删除VIN用户成功！')
        this.defaultMethods()
      }
    }
  }
};
</script>

<style lang="less" scoped>
.moneylist {
  height: 180px;
  display: flex;
  flex-direction: row;

  dl {
    flex: 1;
    display: flex;
    flex-direction: column;

    p {
      flex: 1;
      display: flex;
      align-items: center;
    }

  }
}

.listname {
  display: flex;
  flex-direction: row;
  height: 120px;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 20px;
  list-style: none;
  font-size: 16px;

  .dl {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 20px;

    p {
      flex: 1;
      display: flex;
      justify-content: center;
      // font-size: 16px;
    }

    p:nth-child(1) {
      color: gray;
      // font-size: 16px;
    }

  }

  .dl:last-child {
    margin-right: 0;
  }
}

.cardslist {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .top,
  .center {
    flex: 2;
    display: flex;
    flex-direction: column;

    dl {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      dt {
        // flex: 2;
        width: 200px;
        display: flex;
        align-items: center;
      }

      dd {
        // flex: 8
        width: calc(100% - 200px);
      }
    }
  }

  .top {
    font-size: 16px;

    dd {
      display: flex;
      flex-direction: row;

      li {
        flex: 1;
        display: flex;
        flex-direction: column;

        p {
          display: flex;
          flex-direction: row;
          align-items: center;
        }


      }

      li:nth-child(2) {
        span:nth-child(1) {
          width: 160px;
        }
      }
    }

  }

  .center {
    border-bottom: 1px solid gray;
    border-top: 1px solid gray;
    padding-bottom: 20px;

    dl {
      flex: 9;
    }

    dd {
      display: flex;
      flex-direction: column;

      li {
        display: flex;
        flex-direction: row;

        p {
          margin-right: 30px;
          display: flex;
          flex-direction: column;

          span:nth-child(1) {
            font-size: 16px;
            width: 125px;
            color: gray;
          }

          span:nth-child(2) {
            color: blue;
            font-size: 32px;
            display: flex;
            margin-top: 10px;
            justify-content: center;
          }
        }
      }
    }

    .footername {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;

      span {
        flex: 1;
        color: blue;
      }
    }
  }


  .bottom {
    display: flex;
    flex-direction: column;
    font-size: 16px;

    h4 {
      padding: 20px 0;
    }
  }

  .center,
  .bottom.top {
    flex: 3;

  }

  .footer {
    flex: 1;
    display: flex;
    flex-direction: column;
    font-size: 16px;

    li {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      p {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}

.listname {
  display: flex;
  flex-direction: row;
  height: 120px;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 20px;
  border: 1px solid gray;
  list-style: none;
  border-radius: 15px;



  .dl {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 20px;

    p {
      flex: 1;
      display: flex;
      justify-content: center;
      font-size: 18px;
    }

    p:nth-child(1) {
      color: gray;
      font-size: 16px;
    }

  }

  .dl:last-child {
    margin-right: 0;
  }
}
</style>
