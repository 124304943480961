<template>
  <div class="Preferential">
    <!-- 卡片视图区域 -->
    <el-card>
      <el-row :gutter="20">
        <el-form :inline="true" :model="queryInfo" class="demo-form-inline">
          <el-form-item label="券名称：">
            <el-input placeholder="请输入券名称" clearable v-model="queryInfo.name" @clear="getLogList" @change="current">
            </el-input>
          </el-form-item>
          <el-form-item label="券Id：">
            <el-input placeholder="请输入券Id" clearable v-model="queryInfo.name" @clear="getLogList" @change="current">
            </el-input>
          </el-form-item>
          <el-form-item label="优惠对象：">
            <el-select v-model="playGm" placeholder="请选择优惠对象" @change="playchange" filterable>
              <el-option v-for="item in playGmList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="券类型：">
            <el-select v-model="activeActive" placeholder="请选择券类型" @change="activechange" filterable>
              <el-option v-for="item in activeActiveList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="getLogList">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-refresh-left" @click="chongzhi">重置</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="Addactive">创建优惠券</el-button>
          </el-form-item>
        </el-form>
      </el-row>
      <!-- table表格区域 -->
      <el-table style="width: 100%" :data="list" empty-text="暂无数据">
        <el-table-column align="center" label="券名称/ID" prop="createTime">
          <template scope="scope">
            <div style="display:flex;justify-content:flex-start">
              {{ scope.row.name }}
            </div>
            <div style="display:flex;justify-content:flex-start">
              ID：{{ scope.row.id }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="优惠对象" prop="type" width="120">
          <template scope="scope">
            <div>
              {{ scope.row.type == 5?'单价立减':scope.row.type == 6 ?'绑定站充值送': scope.row.type == 7 ?'小程序充值送':'其他活动'
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="券类型" prop="type" width="120">
          <template scope="scope">
            <div>
              {{ scope.row.type == 5?'单价立减':scope.row.type == 6 ?'绑定站充值送': scope.row.type == 7 ?'小程序充值送':'其他活动'
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="金额/折扣" prop="type" width="120">
          <template scope="scope">
            <div>
              {{ scope.row.type == 5?'单价立减':scope.row.type == 6 ?'绑定站充值送': scope.row.type == 7 ?'小程序充值送':'其他活动'
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="剩余库存" prop="type" width="120">
          <template scope="scope">
            <div>
              {{ scope.row.type == 5?'单价立减':scope.row.type == 6 ?'绑定站充值送': scope.row.type == 7 ?'小程序充值送':'其他活动'
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="有效期" prop="nickname">
          <template scope="scope">
            <div>
              {{ scope.row.startTime }}
            </div>
            <div>
              {{ scope.row.endTime }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="创建时间" prop="dutyParagraph">
          <template scope="scope">
            <div>
              {{ scope.row.createTime }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="券状态" prop="type" width="80">
          <template scope="scope">
            <el-tag :type="scope.row.status == 0 ? 'success' : scope.row.status == 1 ? 'info' :
        scope.row.status == 2 ? 'danger' : scope.row.status == 3 ? 'warning' : ''">{{ scope.row.status == 0
        ? '正常' : scope.row.status == 1 ? '结束' :
          scope.row.status == 2 ? '作废' : scope.row.status == 3 ? '冻结' : '临时下架' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" v-if="caozuoquanxian">
          <template slot-scope="scope">
            <span @click="detail(scope.row)" style="margin-right: 5px;color:blue">详情</span>
            <span v-if="scope.row.type == 6" @click="speakTal(scope.row)" style="margin-right: 5px;color:green">发放记录</span>
            <span @click="down(scope.row)" style="margin-right: 5px;color:red">社群送券</span>
            <span @click="down(scope.row)" style="margin-right: 5px;color:red">更多</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页导航区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.page" :page-sizes="[10, 20, 50, 100]" :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="total" background>
      </el-pagination>
    </el-card>
    <!-- 创建活动（营销活动） -->
    <el-dialog title="营销活动" :visible.sync="activeDialog" width="60%">
      <div class="activeMore">
        <div class="content">
          <!-- :style={
          'background':ActiveIndex==index?'#409EFF':'#F7F8FA'
        } -->
          <div v-for="(item, index) in listall" :key="index" class="activeDl" @click="changeColor(index)" :style="{
        background: ActiveIndex == index ? 'orange' : '#c5c5c5'
      }">
            <div class="dl">
              <dt>
                <el-image style="width: 50px; height: 50px" :src="item.url" fit="scale-down"></el-image>
              </dt>
              <dd>
                <p>{{ item.activeName }}</p>
                <p>
                  <el-tag :type="index == 0 ? 'danger' : index == 1 ? '' : 'warning'">{{ item.activeDes }}</el-tag>
                </p>
              </dd>
            </div>
            <div class="li">{{ item.activeMore }}</div>
          </div>
        </div>
        <div class="footers">
          <button @click="activeDialogFalse">取消</button>
          <button @click="activeDialogBtn">确定</button>
        </div>
      </div>
    </el-dialog>
    <!-- 社群送券 -->
    <el-dialog title="社群送券" :visible.sync="publicTalk" width="60%">
      <div class="publicTalk">
        <dl>
      <li>
          <p>
            <span>优惠券名称</span><span>{{}}</span>
          </p>
          <p>
            <span>券有效期</span><span>{{}}</span>
          </p>
      </li>
      <li><p>
        <span>券内容</span><span>{{}}</span>
          </p>
          <p>
            <span>剩余库存</span><span>{{}}</span>
          </p></li>
      <li><p>
        <span>使用场站</span><span>{{}}</span>
          </p>
         </li>
        </dl>
        <dl>
          <dt>
            二维码
          </dt>
          <dd>
            <VueQr :text='url' style="height: 22vh"></VueQr>
            <p>用微信扫码跳转优惠券分享页面，可分享至群聊让用户自主领券，进行社群运营</p>
          </dd>
        </dl>
        <div class="footer">
          <button @click="publicTalkDialoag">关闭</button>
        </div>
      </div>
    </el-dialog>
    <!-- 详情（单价立减、绑定充值送、小程序充值送） -->
    <el-dialog title="" :visible.sync="dDialog" width="60%">
      <el-form :model="newinfo" label-width="110px">
        <el-form-item label="">
          <!-- <span v-for="(item,index) in lists" :key="index" @click="titleChange(index)"
          :style="{
            "color":activeIndex==index?'blue':'#000',
            "border":activeIndex==index?'1px solid blue':'none'
          }"
          >
          {{item}} -->
        <!-- </span> -->
        </el-form-item>
        <div v-if="activeIndex==0">
        <el-form-item label="基础信息"></el-form-item>
        <el-form-item label="优惠券名称" prop="name">
          <el-input v-model="newinfo.name" autocomplete="off" style="width: 80%;" type="textarea" autosize
            maxlength="20" placeholder="请输入活动名称"></el-input>
        </el-form-item>
        <el-form-item label="券ID" prop="name">
          <el-input v-model="newinfo.name" autocomplete="off" style="width: 80%;" type="textarea" autosize
            maxlength="20" placeholder="请输入活动名称"></el-input>
        </el-form-item>
        <el-form-item label="适用场站" prop="name">
          <el-input v-model="newinfo.name" autocomplete="off" style="width: 80%;" type="textarea" autosize
            maxlength="20" placeholder="请输入活动名称"></el-input>
        </el-form-item>
        <el-form-item label="券状态" prop="name">
          <el-input v-model="newinfo.name" autocomplete="off" style="width: 80%;" type="textarea" autosize
            maxlength="20" placeholder="请输入活动名称"></el-input>
        </el-form-item>
        <el-form-item label="规则说明" prop="name">
          <el-input v-model="newinfo.name" autocomplete="off" style="width: 80%;" type="textarea" autosize
            maxlength="20" placeholder="请输入活动名称"></el-input>
        </el-form-item>
        <el-form-item label="优惠规则"></el-form-item>
        <el-form-item label="优惠类型" prop="name">
          <el-input v-model="newinfo.name" autocomplete="off" style="width: 80%;" type="textarea" autosize
            maxlength="20" placeholder="请输入活动名称"></el-input>
        </el-form-item>
        <el-form-item label="优惠金额" prop="name">
          <el-input v-model="newinfo.name" autocomplete="off" style="width: 80%;" type="textarea" autosize
            maxlength="20" placeholder="请输入活动名称"></el-input>
        </el-form-item>
        <el-form-item label="库存限制" prop="name">
          <el-input v-model="newinfo.name" autocomplete="off" style="width: 80%;" type="textarea" autosize
            maxlength="20" placeholder="请输入活动名称"></el-input>
        </el-form-item>
        <el-form-item label="使用规则"></el-form-item>
        <el-form-item label="券有效期" prop="name">
          <el-input v-model="newinfo.name" autocomplete="off" style="width: 80%;" type="textarea" autosize
            maxlength="20" placeholder="请输入活动名称"></el-input>
        </el-form-item>
        <el-form-item label="可用时段" prop="name">
          <el-input v-model="newinfo.name" autocomplete="off" style="width: 80%;" type="textarea" autosize
            maxlength="20" placeholder="请输入活动名称"></el-input>
        </el-form-item>
        <el-form-item label="使用门槛" prop="name">
          <el-input v-model="newinfo.name" autocomplete="off" style="width: 80%;" type="textarea" autosize
            maxlength="20" placeholder="请输入活动名称"></el-input>
        </el-form-item>
      </div>
      <div v-else>
        <el-row>
          <el-col :span="12">
            <el-form-item label="搜索券码" prop="name">
          <el-input v-model="newinfo.name" autocomplete="off" style="width: 80%;" type="textarea" autosize
            maxlength="20" placeholder="请选择发放来源"></el-input>
        </el-form-item>
        <el-form-item label="用户手机号" prop="name">
          <el-input v-model="newinfo.name" autocomplete="off" style="width: 80%;" type="textarea" autosize
            maxlength="20" placeholder="请选择发放来源"></el-input>
        </el-form-item>
        <el-form-item label="创建时间" prop="name">

        </el-form-item>
      </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="发放来源" prop="name">
          <el-input v-model="newinfo.name" autocomplete="off" style="width: 80%;" type="textarea" autosize
            maxlength="20" placeholder="请选择发放来源"></el-input>
        </el-form-item>
         <el-button>重置</el-button>
         <el-button>搜索</el-button>
          </el-col>
        </el-row>
<div>
<p><span>数据列表</span> <span>全部</span> <span>已发放</span> <span>已使用</span> <span>已冻结</span> <span>已失效</span></p>
<el-table style="width: 100%" :data="list" empty-text="暂无数据">
        <el-table-column align="center" label="优惠券券码" prop="createTime"></el-table-column>
        <el-table-column align="center" label="优惠券ID" prop="createTime"></el-table-column>
        <el-table-column align="center" label="用户信息" prop="createTime"></el-table-column>
        <el-table-column align="center" label="用户昵称" prop="createTime"></el-table-column>
        <el-table-column align="center" label="用户来源" prop="createTime"></el-table-column>
        <el-table-column align="center" label="发放来源" prop="createTime"></el-table-column>
        <el-table-column align="center" label="发放时间" prop="createTime"></el-table-column>
        <el-table-column align="center" label="失效时间" prop="createTime"></el-table-column>
        <el-table-column align="center" label="状态" prop="createTime"></el-table-column>
        <el-table-column align="center" label="操作" prop="createTime"></el-table-column>
</el-table>
</div>
      </div>
      </el-form>
      <div slot="footer" class="dialog-footer" style="display: flex;justify-content: center">
        <button @click="DeatilDailog"
          style="display: flex;justify-content: center;align-items: center;color: #fff;font-size: 18px;width: 100px;height: 30px;border-radius: 15px;background-color: #c5c5c5;margin-right: 20px;border: none;">取消</button>
        <button v-debounce="Deatilbtns"
          style="display: flex;justify-content: center;align-items: center;color: #fff;font-size: 18px;width: 100px;height: 30px;border-radius: 15px;background-color:blue;border: none;">确定</button>
      </div>
    </el-dialog>
    <el-dialog title="下架确认" :visible.sync="downDialog" width="20%">
      <div class="downTest">
        <h4>
          下架后活动直接结束
        </h4>
        <button @click="downMethods">确认</button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import caozuo from "@/utils/pubilc.js";
import VueQr from 'vue-qr';
export default {
  data() {
    return {
      dis: false,
      stationName: '',
      obj: {

      },
      talkobj: {

      },
      // tariffForm: {
      //     fixFee: '',
      //     fixServiceFee: '',
      //     tip: '',
      //     tipService: '',
      //     peak: '',
      //     peakService: '',
      //     flat: '',
      //     flatService: '',
      //     valley: '',
      //     valleyService: ''
      //   },
      objValue: [],
      objValues: [],
      checked: false,
      newinfo: {
        // 活动名称
        name: '',
        // 活动描述
        content: '',
        // 5单价立减6绑定站充值送7小程序充值送，
        type: 0,
        // 0正常1结束2作废3冻结4临时下架
        status: 0,
        requirement1: '',
        requirement2: '',
        stationIds: window.sessionStorage.getItem('stationIds') * 1,
        appId: window.sessionStorage.getItem('appId') * 1,
        adminId: window.sessionStorage.getItem('adminId') * 1,
        roleId: window.sessionStorage.getItem('roleId') * 1
      },
      downDialog: false,
      publicTalk: false,
      ActiveIndex: 0,
      total: 0,
      tagActive: 0,
      tagPrcice: 0,
      getlist: [
        '电费', '服务费', '电费服务费'
      ],
      getPrice: [
        '全天可用', '分时段可用'
      ],
      timelist: [
        {
          time: '尖',
          timeMoneyElertic: '',
          timeMoneyService: ''
        },
        {
          time: '峰',
          timeMoneyElertic: '',
          timeMoneyService: ''
        },
        {
          time: '平',
          timeMoneyElertic: '',
          timeMoneyService: ''
        },
        {
          time: '谷',
          timeMoneyElertic: '',
          timeMoneyService: ''
        },
        {
          time: '深',
          timeMoneyElertic: '',
          timeMoneyService: ''
        }
      ],
      defaultTimelist: [
        {
          time: '默认',
          timeMoneyElertic: '',
          timeMoneyService: ''
        }
      ],
      dDialog: false,
      activeDialog: false,
      caozuoquanxian: true,
      list: [],
      lists:[],
      activeActive: 0,
      playGm: 0,
      url: '',
      objValue: [

      ],
      activeIndex:0,
      listall: [
        {
          url: require('../../assets/images/img/PriceDown.png'),
          activeName: '单价立减',
          activeDes: '低价促转化',
          activeMore: '可针对不同用户、场站，时间设置单价优惠，提升复购和转化'
        },
        {
          url: require('../../assets/images/img/MoneyStation.png'),
          activeName: '绑定站充值送',
          activeDes: '促销引流',
          activeMore: '针对单站做充值送活动，该活动金额默认为第一付款项'
        },
        {
          url: require('../../assets/images/img/miniMoney.png'),
          activeName: '小程序充值送',
          activeDes: '低价促转化',
          activeMore: '可针对不同用户、场站，时间设置单价优惠，提升复购和转化'
        }
      ],
      playGmList: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '单价立减'
        },
        {
          id: 2,
          name: '绑定站充值送'
        }
      ],
      playList: [

      ],
      activeActiveList: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '已过期'
        },
        {
          id: 2,
          name: '进行中'
        }
      ],
      value3: ['', ''],
      queryInfo: {
        page: 1,
        limit: 8,
        discountType: null,
        // type:0充值送，1优惠券，2新人送，3折扣，5单价立减，6绑定站充值送，7小程序充值送，8订单优惠券，9电费券，10服务费，99其他活动
        // 5,6,7
        type: '5,6,7',
        name: '',
        userGroupId: null,
        stationIds: window.sessionStorage.getItem('stationIds'),
        adminId: window.sessionStorage.getItem('adminId')
      }
    }
  },
  computed: {
    jisuan() {
      return function (obj, num, fixed) {
        return (obj / num).toFixed(fixed)
      }
    }
  },
  watch: {

  },
  created() {
    const that = this;
    that.list = [];
    that.$http.get('station-info/getAllStationInfoName', {
      params: {
        roleId: window.sessionStorage.getItem('roleId'),
        stationIds: window.sessionStorage.getItem('stationIds'),
        stationMode: '0,1'
      }
    }).then(res => {
      if (res.data.code !== 0) return;
      that.stationName = res.data.data[0].id;
      res.data.data.forEach(item => {
        that.playList.push({
          // 电站id
          id: item.id,
          // 电站名称
          stationName: item.stationName,
          // 费率Id
          feeModelId: item.feeModelId,
          // 低速，交直流
          stationMode: item.stationMode
        })
      })
      that.publicMethods(res.data.data[0].stationMode, res.data.data[0].feeModelId, res.data.data[0].stationMode);
    })
    // that.playGmList
    that.getLogList();
    // caozuo(149).then(res => {
    //   if (window.sessionStorage.getItem('roleId') == 5) {
    //     that.caozuoquanxian = res;
    //   }
    // });
  },
  mounted() {

  },
  methods: {
    delMethods(e){
this.objValue.splice(e,1);
    },
    // 切换适用场站（费率）
    publicMethods(e, feeModelId, stationMode) {
      const that = this;
      // 费率
      // that.$http.get(e == 0 ? `feeModel/getFeeModelById/${feeModelId}` : `feeModel/getLowFeeModelById/${feeModelId}`).then(ress => {
      // if (ress.data.code !== 0) return;
      // that.tariffForm.fixFee = ress.data.data.fixFee / 100;
      // that.tariffForm.fixServiceFee = ress.data.data.fixServiceFee / 100;
      // let billingPolicy = JSON.parse(ress.data.data.billingPolicy);
      // if (stationMode == 0) {
      //   that.tariffForm.tip = billingPolicy.tip / 1000;
      //   that.tariffForm.tipService = billingPolicy.tipService / 1000;
      //   that.tariffForm.peak = billingPolicy.peak / 1000;
      //   that.tariffForm.peakService = billingPolicy.peakService / 1000;
      //   that.tariffForm.flatService = billingPolicy.flatService / 1000;
      //   that.tariffForm.valley = billingPolicy.valley / 1000;
      //   that.tariffForm.valleyService = billingPolicy.valleyService / 1000;
      // }
      // })
    },
    // 切换营销活动
    changeColor(e) {
      this.ActiveIndex = e;
      this.newinfo.type = e == 0 ? 5 : e == 1 ? 6 : 7;
    },
    // 充值送方案
    addobj() {
      this.objValue.push({
        paramKey: 0,
        paramValue: 0,
      })
    },
    // 切换适用场站（更新费率展示）
    playGmMethods(e) {
      const that = this;
      const abc = that.playList.filter(item => item.id == e);
      console.log(abc, '528');
      that.tariffForm.fixFee = ''; that.tariffForm.fixServiceFee = '';
      that.publicMethods(abc[0].stationMode, abc[0].feeModelId, abc[0].stationMode)
    },
    // 修改活动时间
    time_all3(e) {
      if (e !== null) {
        if (e[0] == e[1]) {
          this.$message.error('起始时间不得为同一天！');
          this.newinfo.startTime = ''
          this.newinfo.endTime = ''
        } else {
          this.newinfo.startTime = e[0];
          this.newinfo.endTime = e[1]
        }
        this.dis = true;
      } else {
        this.newinfo.startTime = '';
        this.newinfo.endTime = '';
        this.value3 = [];
        // 时间
        this.dis = false;
        this.checked = false;
      }
      this.newinfo.effectiveType = 1;
      this.newinfo.timeType = 0;
    },
    // 开启营销弹窗
    activeDialogBtn() {
      this.dDialog = true;
      // this.obj.type=
    },
    //关闭营销弹窗
    activeDialogFalse() {
      this.activeDialog = false;
    },
    // 关闭社群推广弹窗
    publicTalkDialoag() {
this.publicTalk=false;
    },
    // 关闭详情
    DeatilDailog() {
      this.activeIndex=0;
      this.dDialog = false;
    },
    // 添加、修改活动
    Deatilbtns() {
      this.status = 0;
      if (this.ActiveIndex == 0) return;
      this.newinfo.requirement1 = this.ActiveIndex == 1 ? JSON.stringify(this.objValue) : '';
      this.newinfo.requirement2 = this.ActiveIndex == 2 ? JSON.stringify(this.objValue) : '';
      this.Deatilbtn()
    },
    // 添加、修改、下架活动方法
    Deatilbtn() {
      const that = this;
      console.log(that.newinfo, '607');
      // that.ActiveIndex = that.newinfo.type == 5 ? 0 : that.newinfo.type == 6 ? 1 : 2;
      that.$http.post('preferential/editPreferential', that.newinfo).then(res => {
        that.$message({
          type: res.data.code !== 0 ? 'error' : 'success',
          message: that.newinfo.status == 4 && res.data.code !== 0 ? '下架活动失败' : that.newinfo.status == 0 && res.data.code !== 0 ? '添加或更新优惠活动失败' : that.newinfo.status == 4 && res.data.code == 0 ? '下架活动成功' : '添加或更新优惠活动成功'
        });
        if (that.newinfo.status == 4) {
          that.downDialog = false;
        } else {
          that.dDialog = res.data.code!==0?true:false;
          that.ActiveIndex =res.data.code!==0?that.activeIndex:0;
          if (res.data.code == 0) return that.activeDialogFalse()
        }
        that.clearMethods();
       that.getLogList();
      })
    },
    clearMethods() {
      const that = this;
      that.newinfo = {};
      that.ActiveIndex = 0;
      that.tagActive = 0;
      that.tagPrcice = 0;
      that.tariffForm = {};
      that.stationName = '';
      that.value3 = '';
    },
    // 关闭：下架活动弹窗
    downMethods() {
      const that = this;
      that.Deatilbtn()
      // that.$api({
      //   url:''
      // })
    },
    // 更换减免范围
    changeTag(e) {
      this.tagActive = e;
    },
    // 更换定价方案
    changePrice(e) {
      this.tagPrcice = e;
    },
    // 切换（搜索）活动状态
    activechange() {

    },
    //切换（搜索）活动玩法
    playchange() {

    },
    // 重置搜索条件
    chongzhi() {

    },
    // 添加活动
    Addactive() {
      this.activeDialog = true;
      this.newinfo.status = 0;
      this.activeIndex=0;
    },
    // 获取活动列表
    getLogList() {
      const that = this;
      that.$http.get('preferential/getAllPreferential', { params: that.queryInfo }).then(res => {
        if (res.data.code !== 0) return;
        that.list = res.data.data;
        that.total = res.data.count;
      })
    },
    // 更换活动名称，切换活动列表
    current() {
      this.getLogList()
    },
    // 切换列表条数
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize;
      this.getLogList()
    },
    // 切换列表页码
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getLogList()
    },
    // 详情弹窗
    detail(e) {
      const that = this;
      // 充值送方案
      that.$http.get(`preferential/getPreferentialById/${e.id}`).then(res => {
        if (res.data.code !== 0) return;
        that.newinfo = res.data.data;
        that.ActiveIndex = that.newinfo.type == 6 ? 1 : that.newinfo.type == 7 ? 2 : 0;
        that.value3 = [that.newinfo.startTime, that.newinfo.endTime];
        that.objValue = that.newinfo.type == 6 ? JSON.parse(that.newinfo.requirement1) : that.newinfo.type == 7 ? JSON.parse(that.newinfo.requirement2) : [];
        that.activeDialogBtn();
      })
    },
    // 社群推广弹窗
    speakTal(e) {
      this.$http.get(`/preferential/getPreferentialById/${e.id}`).then(res => {
        console.log(res.data.data, '681');
        if (res.data.code !== 0) retun;
        this.talkobj = res.data.data;
        this.url = 'PUBLIC' + window.sessionStorage.getItem('appId') + this.talkobj.id + this.talkobj.stationIds;
        this.publicTalk = true;
      })

    },
    // 下架弹窗
    down(e) {
      this.downDialog = true;
      this.newinfo.status = 4;
      this.newinfo.id = e.id;
      this.newinfo.name = e.name;
      this.newinfo.type = e.type;
      this.downDialog=true;
      //
    }
  }
};
</script>

<style lang='less' scoped>
/deep/.el-table tr>th {
    background: #F5F7FA;
}

.Preferential {
  width: 100vw;
  height: 100vh;
  list-style: none;

  // 下架确认
  .downTest {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 18px;
      width: 100px;
      height: 35px;
      border-radius: 15px;
      background-color: blue;
      border: none;
    }
  }

  // 社群推广
  .publicTalk {
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;

    dl:nth-child(1) {
      border: 1px solid gray;
      padding: 0 20px;
      border-radius: 15px;
    }

    .tag {
      flex: 1;
      border: 1px solid gray;
    }

    .hlhtIntent {
      color: gray;
    }

    .footer {
      display: flex;
      justify-content: center;

      button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        width: 100px;
        height: 30px;
        border: none;
        border-radius: 15px;
        color: #fff;
        background-color: #409EFF;
      }
    }
  }

  // 表格详情
  .activeMore {
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;

    .content {
      flex: 9;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .activeDl {
        flex: 1;
        background-color: #F7F8FA;
        border-radius: 15px;
        padding: 0 10px 20px 10px;
        margin-right: 20px;

        .dl {
          flex: 6;
          display: flex;
          flex-direction: row;
          align-items: center;

          dt {
            flex: 1;
          }

          dd {
            flex: 9;

            p:first-child {
              font-size: 18px;
              font-weight: bolder;
            }


          }
        }

        .li {
          flex: 4;
          color: #909399;
          display: flex;
          align-items: center;
        }
      }

      .activeDl:last-child {
        margin-right: 0;
      }
    }



    .footers {
      margin-top: 30px;
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;

      button {
        width: 100px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        border: none;
        font-size: 18px;
        border-radius: 15px;
        background-color: #909399;
      }

      button:nth-child(2) {
        margin-left: 20px;
        background-color: #409EFF;
      }
    }
  }
}
</style>
