<template>
  <div class="Withdrawal">
    <div class="top">
      <h4 v-if="leixing">
        <span>清分人</span>
        <el-select style="width: 10%;" v-model="suoshudianzhan" @change="change_value" filterable>
          <el-option v-for="item in powerStationList" :key="item.id" :label="item.merchantName" :value="item.id">
          </el-option>
        </el-select>
      </h4>
    </div>
    <div class="center" v-if="!leixing">
      <h4><span>平台线上结算业务</span></h4>
      <div class="ceil">
        <li>
          <dl>
            <dt>{{ jisuan(listobj.money, 100, 2) }}</dt>
            <dd>账户余额（元）</dd>
          </dl>
          <dl>
            <dt>{{ jisuan(listobj.toBeCreditedMoney, 100, 2) }}</dt>
            <dd>待入账金额（元）</dd>
          </dl>
          <dl>
            <dt>{{ jisuan(listobj.freezeMoney, 100, 2) }}</dt>
            <dd>冻结金额（元）</dd>
          </dl>
          <dl v-if="bianji">
            <dt>提现方式</dt>
            <dd style="color: #1989FA;">人工转账</dd>
            
            <!-- <dd @click="settingMethods">去设置</dd> -->
          </dl>
          <dl v-if="bianji" style=" display: flex;flex-direction: column;">
            <button @click="tixianMethods"
              style="background-color: #1989FA;color: #fff;border: none;border-radius: 15px;">提现</button>
            <button style="background-color: orange;color:#fff;margin-top: 10px;border: none;border-radius: 15px;"
              @click="agoMoney">原入账记录</button>
          </dl>
        </li>
        <li>
          <dl>
            <dt>本月实际入账</dt>
            <dd>
              <span>{{ jisuan(listobj.postingMonthTotalMoney, 100, 2) }}</span>
              <span @click="ruzhangs(0)">本月充电收入（元）></span>
            </dd>
          </dl>
          <dl>
            <dt>上月实际入账</dt>
            <dd>
              <span>{{ jisuan(listobj.postingLastMonthTotalMoney, 100, 2) }}</span>
              <span @click="ruzhangs(1)">上月充电收入（元）></span>
            </dd>
          </dl>
          <dl>
            <dt>本月实际出账</dt>
            <dd>
              <span>{{ jisuan(listobj.withdrawalMonthTotalMoney, 100, 2) }}</span>
              <span @click="mingxis(0)">本月账户出账（元）></span>
            </dd>
          </dl>
          <dl>
            <dt>上月实际出账</dt>
            <dd>
              <span>{{ jisuan(listobj.withdrawalLastMonthTotalMoney, 100, 2) }}</span>
              <span @click="mingxis(1)">上月账户出账（元）></span>
            </dd>
          </dl>
        </li>
      </div>
    </div>
    <div class="bottom" v-if="typing">
      <el-table :data="List" border>
        <el-table-column label="商户名称" prop="merchantName"></el-table-column>
        <el-table-column label="真实姓名" prop="trueName"></el-table-column>
        <el-table-column label="手机号" prop="phone"></el-table-column>
        <el-table-column label="待转入金额" prop="toBeCreditedMoney">
          <template slot-scope="scope">
            <span>
              ￥{{ jisuan(scope.row.toBeCreditedMoney, 100, 2) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="冻结金额" prop="freezeMoney">
          <template slot-scope="scope">
            <span>
              ￥{{ jisuan(scope.row.freezeMoney, 100, 2) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="账户余额" prop="money">
          <template slot-scope="scope">
            <span>
              ￥ {{ jisuan(scope.row.money, 100, 2) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="提现方式" prop="withdrawalType">
          <template slot-scope="scope">
            <span>
              {{ scope.row.withdrawalType == 0 ? '人工转账' : '自动转账' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="状态" prop="status">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.statusType" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="更新时间" prop="updateTime">
          <template slot-scope="scope">
            <span>
              {{ scope.row.updateTime !== '' ? scope.row.updateTime : '---' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="420">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" @click="edit(scope.row)" v-if="bianji">编辑</el-button>
            <el-button type="warning" @click="ruzhang(scope.row)">入账明细</el-button>
            <el-button type="warning" @click="mingxi(scope.row)">提现明细</el-button>
            <el-button type="danger" @click="dongjie(scope.row)" v-if="stopMoney">冻结</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.page" :page-sizes="[6, 20, 50, 100]" :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="total" background>
      </el-pagination>
    </div>
    <!-- 提现弹窗 -->
    <el-dialog :visible.sync="titleDialoag1" title="提现方式" width="35%" append-to-body @close="colseDialoag">
      <el-form :data="typeobj">
        <el-form-item prop="">
          <el-radio-group @change="changeValue" v-model="name">
            <el-radio v-for="item in leaavelz" :key="item" :label="item" :disabled="item.disabd">{{
        item }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <p>手续费规则</p>
          <p>1.微信收取<span style="color:red">0.6%</span>的手续费</p>
          <p>2.预约提现<span style="color:red">1-3</span>个工作日</p>
          <p style="color:red;display: flex;justify-content: center">
            注:预约提现一般1-3个工作日到账，若未到账，请及时联系售后
          </p>
        </el-form-item>
        <el-form-item>
          <el-row :gutter="24" style="display: flex;flex-direction: row;">
            <el-col :span="12" style="display: flex;justify-content: flex-end;">
              <button @click="colseDialoag"
                style="width: 120px;background-color: #fff;display: flex;justify-content: center;align-items: center;height: 30px;font-size: 16px;">取消</button>
            </el-col>
            <el-col :span="12" style="display: flex;justify-content: flex-start;">
              <button @click="colseDialoag"
                style="width: 120px;display: flex;justify-content: center;align-items: center;height: 30px;font-size: 16px;background-color: aqua;color: #fff;border:none">确定</button>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 清分明细弹窗 -->
    <el-dialog :visible.sync="titleDialoag2" :title="typing ? title : ds + title" width="80%" append-to-body
      @close="colseDialoag2">
      <div class="listnames" v-if="tabList.length > 0">
        <div v-for="(item, index) in listname" :key="index" class="dl">
          <p>{{ item.name }}</p>
          <p>￥{{ jisuan(item.money, 100, 2) }}</p>
        </div>
      </div>
      <el-table :data="tabList" border>
        <el-table-column align="center" label="姓名" prop="merchantMame" width="120"></el-table-column>
        <el-table-column align="center" :label="title == '清分提现明细' ? '提现状态' : '清分类型'" prop="type" width="90">
          <template slot-scope="scope">
            <span v-if="title == '清分提现明细'">
              {{ scope.row.status == 0 ? '启用' : '禁用' }}
            </span>
            <span v-else>
              {{ scope.row.type == 0 ? '订单金额' : scope.row.type == 1 ? '电费' : '服务费' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="清分比例" prop="sortingRatio" v-if="title !== '清分提现明细'" width="90">
          <template slot-scope="scope">
            <span>
              {{ scope.row.sortingRatio + '%' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" v-if="title !== '清分提现明细'" label="清分金额" prop="toBeCreditedMoney" width="90">
          <template slot-scope="scope">
            <span>
              ￥{{ jisuan(scope.row.commissionMoney, 100, 2) }}
            </span>
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" v-if="title !== '清分提现明细'" label="清分金额" prop="toBeCreditedMoney">
          <template slot-scope="scope">
            <span>
              ￥{{ jisuan(scope.row.toBeCreditedMoney, 100, 2) }}
            </span>
          </template>
        </el-table-column> -->
        <el-table-column align="center" v-if="title == '清分提现明细'" label="申请提现金额" prop="changeMoney" width="110">
          <template slot-scope="scope">
            <span>
              ￥{{ jisuan(scope.row.changeMoney, 100, 2) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" v-if="title !== '清分提现明细'" label="订单总金额" prop="orderMoney" width="100">
          <template slot-scope="scope">
            <span>
              ￥{{ jisuan(scope.row.orderMoney, 100, 2) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" v-if="title == '清分提现明细'" label="提现手续费" prop="withdrawalServiceMoney"
          width="100">
          <template slot-scope="scope">
            <span>
              ￥{{ jisuan(scope.row.withdrawalServiceMoney, 100, 2) }}
            </span>
          </template>
        </el-table-column>
        <!-- changeMoney -->
        <el-table-column align="center" v-if="title !== '清分提现明细'" label="入账金额" prop="postingTotalMoney" width="90">
          <template slot-scope="scope">
            <span>
              ￥{{ jisuan(scope.row.changeMoney, 100, 2) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" v-if="title == '清分提现明细'" label="应到账金额" prop="realityMoney" width="100">
          <template slot-scope="scope">
            <span>
              ￥{{ jisuan(scope.row.realityMoney, 100, 2) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" :label="title == '清分提现明细' ? '收款账户 ' : '入账商户'" prop="trueName"
          width="120"></el-table-column>
        <el-table-column align="center" :label="title == '清分提现明细' ? '提现单号 ' : '流水单号'" prop="billCode"></el-table-column>
        <el-table-column align="center" label="电费/服务费" prop="merchantName" v-if="title !== '清分提现明细'" width="140">
          <template slot-scope="scope">
            <span>
              ￥{{ jisuan(scope.row.orderMoney - scope.row.servicemoney, 100, 2) }}
            </span>
            <span>
              |
            </span>
            <span>
              ￥{{ jisuan(scope.row.servicemoney, 100, 2) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="提现类型" prop="withdrawalType" v-if="title == '清分提现明细'" width="100">
          <template slot-scope="scope">
            <span>
              {{ scope.row.withdrawalType == 0 ? '人工到账' : '自动到账' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" :label="title == '清分提现明细' ? '操作人' : '备注'" prop="des" width="120">
          <template slot-scope="scope">
            <span>
              {{ title == '清分提现明细' ? scope.row.adminName : scope.row.des }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" :label="title == '清分提现明细' ? '提现时间 ' : '入账时间'" prop="createTime" width="100">
          <template slot-scope="scope">
            <span>
              {{ scope.row.createTime !== null ? scope.row.createTime : '---' }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2"
        :current-page="carinfo.page" :page-sizes="[8, 20, 50, 100]" :page-size="carinfo.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="total2" background>
      </el-pagination>
    </el-dialog>
    <!-- 原入账记录 账单明细的对话框 -->
    <el-dialog title="账单明细" :visible.sync="expendDialogVisible" width="80%" @close="expendDialogClosed" append-to-body>
      <!-- 内容主体区域 -->
      <el-table :data="expendList" border empty-text="暂无数据">
        <el-table-column align="center" label="商户名称" prop="adminName"></el-table-column>
        <el-table-column align="center" label="初始金额" prop="startMoney" width="120">
          <template slot-scope="scope">
            <span>￥{{ jisuan(scope.row.startMoney, 100, 2) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="变动金额" prop="changeMoney" width="120">
          <template slot-scope="scope">
            <span>￥{{ jisuan(scope.row.changeMoney, 100, 2) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="余额" prop="newMoney" width="120">
          <template slot-scope="scope">
            <span>￥{{ jisuan(scope.row.newMoney, 100, 2) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="结算类型" prop="type" width="120">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.type == 0 ? 'danger' : scope.row.type == 1 ? 'success' : scope.row.type == 2 ? 'warning' : ''">{{
        scope.row.type == 0 ? '提现' : scope.row.type == 1 ? '入账' : scope.row.type == 2 ? '补差' : '提现失败' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="流水单号" prop="billCode" width="260"></el-table-column>
        <el-table-column align="center" label="服务费" prop="servicemoney" width="120">
          <template slot-scope="scope">
            <span>￥{{ jisuan(scope.row.servicemoney, 100, 2) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="订单金额" prop="orderMoney" width="120">
          <template slot-scope="scope">
            <span>￥{{ jisuan(scope.row.orderMoney, 100, 2) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="备注" prop="des">
          <template slot-scope="scope">
            <span>{{ scope.row.des }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="入账时间" prop="createTime" width="140"></el-table-column>
      </el-table>
      <!-- 分页导航区域 -->
      <el-pagination @size-change="zhangdanChange" @current-change="zhangdanCurrentChange"
        :current-page="zhangdanInfo.page" :page-sizes="[8, 20, 50, 100]" :page-size="zhangdanInfo.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="expendTotal" background>
      </el-pagination>
    </el-dialog>
    <!-- 冻结金额 -->
    <el-dialog title="冻结金额" :visible.sync="coolDialoag" width="35%">
      <div class="cool">
        <p>
          <span>￥</span>
          <el-input v-model="freezeMoney" style="width:50%"></el-input>
        </p>
        <p>--温馨提示--</p>
        <p>1、冻结金额为0，则不冻结金额;</p>
        <p>
          2、填写金额多少则冻结多少，冻结金额不支持提现。
        </p>
        <p>
          <button @click="falseMethods">取消</button>
          <button v-debounce="okMethods">确定</button>
        </p>
      </div>
    </el-dialog>
    <el-dialog title="编辑清分人" :visible.sync="editDialogVisible" width="40%" @close="editDialogClosed" append-to-body
      :close-on-click-modal="false">
      <el-form :model="vinAccountForm" :rules="vinAccountFormRules" ref="vinAccountFormRef" label-width="80px">
        <el-row type="flex" class="row-bg" justify="space-around" :gutter="24">
          <el-col :span="12">
            <!-- 内容主体区域 -->
            <el-form-item label="提现商户" prop="adminId">
              <el-select v-model="vinAccountForm.totalLedgerId">
                <el-option v-for="item in allshanghu" :key="item.id" :value="item.id"
                  :label="item.merchantName"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="真实姓名" prop="trueName">
              <el-input v-model="vinAccountForm.trueName"></el-input>
            </el-form-item>
            <el-form-item label="收款银行" prop="bankName">
              <el-input v-model="vinAccountForm.bankName"></el-input>
            </el-form-item>
            <el-form-item label="收费类型" prop="type">
              <el-select v-model="vinAccountForm.type">
                <el-option v-for="item in typelist" :key="item.id" :value="item.id" :label="item.name"></el-option>
              </el-select>
            </el-form-item>
            <!-- 是否主账户 -->
            <el-form-item label="登录账户" prop="userName">
              <el-input v-model="vinAccountForm.userName" :disabled="title !== '新增清分人'"></el-input>
            </el-form-item>
            <el-form-item label="主账户" prop="isMainAccount">
              <el-radio-group v-model="radio">
                <el-radio v-for="item in leaavel" :key="item.name" :label="item.name">{{ item.name }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属电站" prop="stationId">
              <el-select v-model="vinAccountForm.stationId">
                <el-option v-for="item in stationList" :key="item.id" :value="item.id"
                  :label="item.stationName"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="收款卡号" prop="bankCard">
              <el-input v-model="vinAccountForm.bankCard"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="phone">
              <el-input v-model="vinAccountForm.phone"></el-input>
            </el-form-item>
            <el-form-item label="费率" prop="serviceRate">
              <el-input-number v-model="vinAccountForm.serviceRate" controls-position="right" :min="0"
                :max="100"></el-input-number>
              <span style="margin-left: 8px;">%</span>
            </el-form-item>
            <!-- 是否主账户 -->
            <el-form-item label="登录密码" prop="passWord">
              <el-input v-model="vinAccountForm.passWord"></el-input>
            </el-form-item>
            <el-form-item label="备注说明" prop="des">
              <el-input v-model="vinAccountForm.des"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 底部按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogClosed">取 消</el-button>
        <el-button type="primary" v-debounce="editVinAccount" :disabled="btn1">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 预约提现对话框 -->
    <el-dialog title="预约提现" :visible.sync="cashOutDialogVisible" width="38%" @close="cashOutDialogClosed"
      append-to-body>
      <el-form :model="settlementForm" :rules="settlementRules" ref="settlementRef" label-width="80px">
        <el-form-item label="账户余额" prop="money">
          <el-tag>￥{{ jisuan(settlementForm.money,100,2) }}</el-tag>
        </el-form-item>
        <el-form-item label="提现金额">
          <!-- 000 -->
          <el-input-number v-model="yuyuemoney" controls-position="right" :min="1000" @change="yuyue"
            :step="0.01"></el-input-number>
          <span style="margin-left: 8px;">元</span>
        </el-form-item>
      </el-form>
      <el-radio v-model="settlementForm.contacts">个人银行卡</el-radio>
      <div style="margin: 15px 0;">
        微信收取<span style="color: red;">￥{{ wxsq }}</span> 手续费，实际到账<span style="color: red;">￥{{ sjdz }}</span>
      </div>
      <div style="margin: 15px 0;">
        <span style="font-weight: 700;">手续费规则：</span>
      </div>
      <div>
        <span>1.微信收取<span style="color: red;">0.6%</span>的手续费</span>
      </div>
      <div style="margin: 15px 0;">
        <span>2.预约提现<span style="color: red;">1-3</span>个工作日</span>
      </div>
      <div>
        <span style="color: red;">注：预约提现一般1-3个工作日到账，若未到账，请及时联系售后。</span>
      </div>
      <div>
        <!-- font-size: 26px;line-height: 30px; -->
        <span style="color: #fff;">注：</span><span style="color: red;">节假日顺延至节后上班第一天</span>
      </div>
      <!-- 底部按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="cashOutDialogClosed">取 消</el-button>
        <el-button type="primary" v-debounce="editCashOut">预约</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import caozuo from "@/utils/pubilc.js";
export default {
  name: 'Withdrawal',
  data() {
    return {
      expendTotal: 0,
      zhangdanInfo: {
        stationLedgerId: null,
        page: 1,
        limit: 8
      },
      expendList: [],
      expendDialogVisible: false,
      bianji: false,
      yuyuetixian: false,
      wxsq: 0,
      sjdz: 0,
      num: 1000,
      // 000
      yuyuemoney: 1000,
      cashOutDialogVisible: false,
      settlementForm: {
        adminId: '',
        type: 0,
        trueName: '',
        bankCode: '',
        bankName: '',
        openId: '',
        status: 0,
        wechatId: '',
        phone: '',
        bankCard: '',
        serviceRate: '',
        powerStationId: 0,
        stationIds: '',
        money: 0,
        type: 0,
        des: '',
        merchantName: '',
      },
      settlementRules: {
        merchantName: [
          { required: true, message: '请至少选择一项', trigger: 'change' }
        ],
        type: [
          { required: true, message: '请选择你的收费类型', trigger: 'change' }
        ],
        adminId: [
          { required: true, message: '请输入商户名称', trigger: 'change' }
        ],
        trueName: [
          { required: true, message: '真实姓名不能为空', trigger: 'blur' },
        ],
        bankName: [
          { required: true, message: '银行名称不能为空', trigger: 'blur' }
        ],
        openId: [
          { required: true, message: '收款openId不能为空', trigger: 'blur' }
        ],
        wechatId: [
          { required: true, message: '请至少选择一项', trigger: 'change' }
        ],
        phone: [
          { required: true, message: '电话号码不能为空', trigger: 'blur' },
        ],
        bankCard: [
          { required: true, message: '银行卡号不能为空', trigger: 'blur' },
        ],
        serviceRate: [
          { required: true, message: '费率不能为空', trigger: 'blur' },
        ]
      },
      stationIds: '',
      ds: '',
      allshanghu: [],
      stationList: [],
      typelist: [{
        id: 0,
        name: '服务费'
      },
      {
        id: 1,
        name: '全额消费'
      },
      {
        id: 2,
        name: '全额电费'
      }],
      radio: '是',
      leaavel: [
        {
          name: '是'
        },
        {
          name: '否'
        }
      ],
      title: '',
      vinAccountFormRules: {
        trueName: {
          required: true, message: '请输入真实姓名', trigger: 'blur'
        },
        merchantName: {
          required: true, message: '请输入商户名称', trigger: 'blur'
        },
        type: {
          required: true, message: '请选择收费类型', trigger: 'change'
        },
        serviceRate: {
          required: true, message: '请输入或选择费率', trigger: 'blur'
        },
        phone: {
          required: true, message: '请输入联系电话', trigger: 'blur'
        },
        adminId: {
          required: true, message: '请选择提现商户', trigger: 'change'
        },
        stationId: {
          required: true, message: '请选择所属电站', trigger: 'change'
        },
        bankCard: {
          required: true, message: '请输入收款卡号', trigger: 'blur'
        },
        bankName: {
          required: true, message: '请输入收款银行', trigger: 'blur'
        },
        userName: {
          required: true, message: '请输入登录账户', trigger: 'blur'
        },
        passWord: {
          required: true, message: '请输入登录密码', trigger: 'blur'
        }
      },
      vinAccountForm: {
        passWord: '',
        userName: '',
        adminId: '',
        trueName: '',
        id: '',
        bankCard: '',
        bankName: '',
        type: 0,
        serviceRate: '',
        des: '',
        isMainAccount: '1',
        freezeMoney: '',
        totalLedgerId: '',
        stationId: ''
      },
      editDialogVisible: false,
      coolDialoag: false,
      //提现
      typeobj: {

      },
      freezeMoney: '',
      name: '人工转账',
      total2: 0,
      checkIndex: 0,
      btn1: false,
      // 明细表
      carinfo: {
        page: 1,
        limit: 8,
        stationLedgerId: '',
        roleId: window.sessionStorage.getItem('roleId'),
        type: 3
      },
      listname: [],
      title: '本月清分入账明细',
      titleDialoag1: false,
      titleDialoag2: false,
      suoshudianzhan: '',
      powerStationList: [],
      leaavelz: [
        '人工转账', '自动转账'
      ],
      List: [],
      stopMoney: false,
      currentobj: {
        money1: 0,
        money2: 0
      },
      tabList: [],
      typing: false,
      listobj: {},
      queryInfo: {
        page: 1,
        limit: 6,
        adminId: window.sessionStorage.getItem('adminId'),
        roleId: window.sessionStorage.getItem('roleId'),
        merchantName: ''
      },
      leixing: false,
      obj: {

      },
      total: 0
    }
  },
  computed: {
    shiji() {
      return function (abc, bac) {
        return (((abc * 1) + (bac * 1)) / 100).toFixed(2)
      }
    },
    wait_s() {
      return function (e) {
        return ((e.money - e.giveMoney) / 100).toFixed(2)
      }
    },
    time_all() {
      return function (val) {
        const hr = val.billCode.slice(val.pileCode.length, val.billCode.length)
        return hr
      }
    },
    jisuan() {
      return function (obj, num, fixed) {
        if (obj == null || obj == undefined) return '--';
        return (obj / num).toFixed(fixed)
      }
    },
    shifu() {
      return function (val) {
        return ((val.billMoney - val.billMoneygive) / 100).toFixed(2)
      }
    }
  },
  created() {
    const that = this;
    caozuo(100).then(res => {
      that.caozuoquanxian = res;
    });
    // 提现权限187
    caozuo(187).then(res => {
      that.bianji = res;
    })
    // 冻结金额权限188
    caozuo(188).then(res => {
      that.stopMoney = res;
    })
    that.typing = window.sessionStorage.getItem('roleId') == 1 || window.sessionStorage.getItem('roleId') == 2 || window.sessionStorage.getItem('roleId') == 3 || window.sessionStorage.getItem('roleId') == 10 ? true : false;
    // that.defaultMethods()
    that.getVinAccountList();
    that.stationList = [];
    that.$http.get('station-info/getAllStationInfoName', {
      params: {
        roleId: window.sessionStorage.getItem('roleId'),
        stationIds: window.sessionStorage.getItem('stationIds'),
        stationMode: '0,1,2'
      }
    }).then(resb => {
      if (resb.data.code !== 0) return;
      that.stationList = resb.data.data;
    });
    if (this.yuyuemoney == 1000) {
      this.wxsq = Number(this.yuyuemoney) * 0.006;
      this.sjdz = Number(this.yuyuemoney) - Number(this.wxsq);
    }
  },
  methods: {
    expendDialogClosed() {
      this.expendDialogVisible = false;
      this.zhangdanInfo.limit = 8;
      this.zhangdanInfo.page = 1;
      this.expendList = [];
    },
    agoMoney() {
      const that = this;
      that.defaultMethods();
      that.expendDialogVisible = true;
    },
    defaultMethods() {
      const that = this;
      that.$http.get(`station-ledger-log-old/getStationLedgerLog?stationLedgerId=${that.listobj.id * 1}&page=${that.zhangdanInfo.page}&limit=${that.zhangdanInfo.limit}`).then(res => {
        console.log(res.data.code, '730');
        if (res.data.code !== 0) return;
        that.expendList = res.data.data;
        that.expendTotal = res.data.count;
      })
    },
    zhangdanCurrentChange(newPage) {
      this.zhangdanInfo.page = newPage;
      this.defaultMethods()
    },
    zhangdanChange(newSize) {
      this.zhangdanInfo.limit = newSize;
      this.defaultMethods()
    },
    yuyue(e) {
      // if (e < 1000) return this.yuyue = 1000;
      if (e > this.settlementForm.money) {
        this.jinggao = true;
        this.$message('提现金额不得大于余额');
      } else {
        this.jinggao = false;
        this.wxsq = (Number(this.yuyuemoney) * 0.006).toFixed(2);
        this.sjdz = Number(this.yuyuemoney) - Number(this.wxsq).toFixed(2);
      }
    },
    // 预约提现对话框的关闭事件
    cashOutDialogClosed() {
      this.cashOutDialogVisible = false;
    },
    // 预约提现的方法
    async editCashOut() {
      const that = this;
      let abc = null;
      abc = that.yuyuemoney;
      if (that.yuyuemoney > that.settlementForm.money) return that.$message.error('提现金额不得大于余额');
      that.$http.post('financial-overview/Withdrawal', {
        id: that.listobj.id,
        money: abc * 100,
        adminId: window.sessionStorage.getItem('adminId') * 1,
        type: 0
      }).then(res => {
        if (res.data.code == 0) {
          that.$message.success(res.data.msg);
          that.getSettlementList();
        } else {
          that.$message.error(res.data.msg)
        }
        that.cashOutDialogVisible = false
      })
    },
    // 添加&编辑清分人事件
    editVinAccount() {
      const that = this;
      console.log(that.vinAccountForm.type, that.vinAccountForm, '692');
      if (that.vinAccountForm.trueName == '') return that.$message.error('请输入真实姓名');
      if (that.vinAccountForm.bankCard == '') return that.$message.error('请输入收款卡号');
      if (that.vinAccountForm.bankName == '') return that.$message.error('请输入收款银行');
      if (that.vinAccountForm.phone == '') return that.$message.error('请输入联系电话');
      if (that.vinAccountForm.trueName == '') return that.$message.error('请输入真实姓名');
      if (that.vinAccountForm.password == '') return that.$message.error('请输入登录密码');
      if (that.vinAccountForm.serviceRate == '') return that.$message.error('请输入费率');
      // if (that.vinAccountForm.type == '') return that.$message.error('请选择收费类型');
      if (that.vinAccountForm.stationId == '') return that.$message.error('请选择所属电站');
      if (that.vinAccountForm.totalLedgerId == '') return that.$message.error('请选择提现商户');
      that.$refs.vinAccountFormRef.validate(async valid => {
        if (!valid) return;
        const { data: res } = await that.$http.post('station-ledger/updStationLedger', that.vinAccountForm)
        that.btn1 = res.code !== 0 ? true : false;
        if (res.code !== 0) return that.$message.error(res.msg)
        that.$message.success('编辑清分人成功');
        that.freezeMoney = '';
        that.getVinAccountList();
        that.editDialogVisible = false;
        // that.coolDialoag = false;
        // this.publicMethods()
      })
    },
    // 用户详情
    editDialogClosed() {
      this.editDialogVisible = false;
    },
    // 确定冻结
    okMethods() {
      const that = this;
      if (that.freezeMoney == '' || that.freezeMoney == undefined || that.freezeMoney == null || that.freezeMoney == 0) return that.$message.error(`${that.freezeMoney == 0 ? '冻结金额应为整数且大于0' : '请检查您的冻结金额格式'}`);
      that.vinAccountForm.freezeMoney = that.freezeMoney * 100,
        that.$http.post('station-ledger/updStationLedger', that.vinAccountForm).then(res => {
          that.$message({
            type: res.data.code !== 0 ? 'error' : 'success',
            message: res.data.code !== 0 ? '冻结失败，请检查您的冻结金额' : '冻结金额设置成功'
          });
          if (res.data.code !== 0) return;
          that.getVinAccountList()
        })
    },
    // 确定取消
    falseMethods() {
      this.coolDialoag = false;
    },
    // 提现
    tixianMethods() {
      // if (this.listobj.money == 0) return this.$message.error('该账户余额不足，不支持提现');
      this.cashOutDialogVisible = true;
      this.settlementForm.money = this.listobj.money;
      // * 100
      that.$http.post('station-ledger/Withdrawal', {
        data: {
          id: that.listobj.id,
          money: that.yuyuemoney,
          adminId: window.sessionStorage.getItem('adminId') * 1
        }
      }).then(res => {
        that.$message({
          type: res.data.code !== 0 ? 'error' : 'success',
          message: res.data.code !== 0 ? '提现失败' : '提现成功',
        })
        if (res.data.code !== 0) return;
        that.$message.error('提现已预约，节后到账');
      })
    },
    // 设置提现方式
    settingMethods() {
      if (this.listobj.money == 0) return this.$message.error('该账户余额不足，不支持提现');
      this.titleDialoag1 = true;
    },
    // 关闭明细弹窗表
    colseDialoag2() {
      this.titleDialoag2 = false;
      this.carinfo.limit = 8;
      this.carinfo.page = 1;
    },
    // 关闭冻结弹窗
    colseDialoag() {
      this.titleDialoag1 = false;
    },
    // 编辑
    edit(row) {
      const that = this;
      that.powerStationOptions = [];
      that.allshanghu = [];
      that.vinAccountForm = {};
      that.editDialogVisible = true;
      that.$http.get('station-total-ledger/getAllStationTotalLedger', {
        params: {
          adminId: row.adminId,
          page: 1,
          limit: 10
        }
      }).then(ress => {
        if (ress.data.code !== 0) return;
        that.allshanghu = ress.data.data;
      })
      that.$http.get(`station-ledger/getStationLedgerById/${row.id}`).then(res => {
        that.vinAccountForm = res.data.data;
        that.vinAccountForm.stationId = that.vinAccountForm.stationId * 1;
        that.vinAccountForm.passWord = "******";
        that.radio = res.data.data.isMainAccount == 1 ? '是' : '否';
        that.stationIds = that.vinAccountForm.stationIds * 1;
      });
    },
    // 提现明细
    mingxi(row) {
      const that = this; that.tabList = [];
      that.carinfo.stationLedgerId = row.id;
      that.listname = [];
      that.mingxiMethods()
    },
    mingxis(e) {
      const that = this;
      that.carinfo.type = e;
      that.listname = []; that.tabList = [];
      // that.carinfo.stationLedgerId = that.listobj.id;
      that.ds = e == 0 ? '本月' : '上月';
      that.mingxiMethods()
    },
    mingxiMethods() {
      const that = this;
      that.title = "清分提现明细";
      that.$http.get('financial-overview/getWithdrawalLog', {
        params: this.carinfo
      }).then(res => {
        that.titleDialoag2 = true;
        if (res.data.code !== 0) return;
        that.tabList = res.data.data;
        that.total2 = res.data.count;
        that.listname = [
          {
            name: '提现总金额',
            money: that.tabList[0].withdrawalTotalMoney
          },
          {
            name: '本月提现总金额',
            money: that.tabList[0].withdrawalMonthTotalMoney
          },
          {
            name: '今日提现总金额',
            money: that.tabList[0].withdrawalToDayTotalMoney
          }
        ];
      });
    },
    // 冻结
    dongjie(row) {
      this.obj = row;
      this.vinAccountForm = row;
      this.coolDialoag = true;
    },
    // 入账明细(超管)
    ruzhang(row) {
      const that = this;
      that.listname = []; that.tabList = [];
      that.carinfo.stationLedgerId = row.id;
      that.ruzhangMethods()
    },
    // 入账明细（商户）
    ruzhangs(e) {
      const that = this; that.tabList = [];
      that.carinfo.type = e; that.listname = [];
      that.carinfo.stationLedgerId = that.listobj.id;
      that.ds = e == 0 ? '本月' : '上月';
      that.ruzhangMethods()
    },
    ruzhangMethods() {
      const that = this; that.title = "清分入账明细";
      that.$http.get('financial-overview/getStationLedgerLog', {
        params: that.carinfo
      }).then(res => {
        that.titleDialoag2 = true;
        if (res.data.code !== 0) return;
        that.tabList = res.data.data;
        that.listname = [
          {
            name: '入账总金额',
            money: res.data.data[0].postingTotalMoney
          },
          {
            name: '清分总金额',
            money: res.data.data[0].sortingTotalMoney
          },
          {
            name: '本月入账总金额',
            money: res.data.data[0].postingMonthTotalMoney
          },
          {
            name: '本月清分总金额',
            money: res.data.data[0].sortingMonthTotalMoney
          },
          {
            name: '今日入账总金额',
            money: res.data.data[0].postingToDayTotalMoney
          },
          {
            name: '今日清分总金额',
            money: res.data.data[0].sortingToDayTotalMoney
          }
        ]
      })
    },
    handleSizeChange2(newSize) {
      this.carinfo.limit = newSize;
      if (this.title == '清分提现明细') return this.mingxiMethods();
      this.ruzhangMethods()
    },
    handleCurrentChange2(newPage) {
      this.carinfo.page = newPage;
      if (this.title == '清分提现明细') return this.mingxiMethods();
      this.ruzhangMethods()
    },
    changeValue(e) {
      console.log(e, '664');
      if (e == '自动转账') return this.$message.error('正在开发中');
    },
    change_value(e) {
      this.powerStationList.map(item => {
        if (item.id !== e) return;
        this.queryInfo.merchantName = item.merchantName;
        this.publicMethods()
      });
    },
    // defaultMethods() {
    //   const that = this;
    //   that.$http.get('financial-overview/getStationLedgerLog', {
    //     params: that.queryInfo
    //   }).then(res => {
    //     if (res.data.code !== 0) return;
    //     that.total = res.data.count;
    //     that.List = res.data.data;
    //   })
    // },
    async publicMethods() {
      const that = this;
      that.$http.get('financial-overview/getAllStationLedger', { params: that.queryInfo }).then(res => {
        if (res.data.code !== 0) return that.$message.error(res.data.msg);
        that.listobj = window.sessionStorage.getItem('roleId') == 1 || window.sessionStorage.getItem('roleId') == 2 || window.sessionStorage.getItem('roleId') == 3 || window.sessionStorage.getItem('roleId') == 10 ? {} : res.data.data;
        that.List = window.sessionStorage.getItem('roleId') == 1 || window.sessionStorage.getItem('roleId') == 2 || window.sessionStorage.getItem('roleId') == 3 || window.sessionStorage.getItem('roleId') == 10 ? res.data.data : [];
        that.total = res.data.count;
        if (window.sessionStorage.getItem('roleId') == 1 || window.sessionStorage.getItem('roleId') == 2 || window.sessionStorage.getItem('roleId') == 3 || window.sessionStorage.getItem('roleId') == 10) return that.List.forEach(item => {
          item.statusType = item.status == 0 ? true : false
        });
      })
    },
    // 获取vin用户列表
    async getVinAccountList() {
      const that = this;
      await that.$http.get('financial-overview/getAllStationLedger', { params: that.queryInfo }).then(res => {
        if (res.data.code !== 0) return;
        that.leixing = window.sessionStorage.getItem('roleId') == 1 || window.sessionStorage.getItem('roleId') == 2 || window.sessionStorage.getItem('roleId') == 3 || window.sessionStorage.getItem('roleId') == 10 ? true : false;
        that.listobj = window.sessionStorage.getItem('roleId') == 1 || window.sessionStorage.getItem('roleId') == 2 || window.sessionStorage.getItem('roleId') == 3 || window.sessionStorage.getItem('roleId') == 10 ? {} : res.data.data;
        that.List = window.sessionStorage.getItem('roleId') == 1 || window.sessionStorage.getItem('roleId') == 2 || window.sessionStorage.getItem('roleId') == 3 || window.sessionStorage.getItem('roleId') == 10 ? res.data.data : [];
        that.powerStationList = res.data.data;
        that.total = res.data.count;
        if (window.sessionStorage.getItem('roleId') == 1 || window.sessionStorage.getItem('roleId') == 2 || window.sessionStorage.getItem('roleId') == 3 || window.sessionStorage.getItem('roleId') == 10) return that.List.forEach(item => {
          item.statusType = item.status == 0 ? true : false
        });

      })
    },
    // 监听pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize;
      this.getVinAccountList()
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage;
      this.getVinAccountList()
    }
  }
};
</script>

<style lang="less" scoped>
.listnames {
  display: flex;
  flex-direction: row;
  height: 120px;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 20px;
  border: 1px solid gray;
  list-style: none;
  border-radius: 15px;

  .dl {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 20px;

    p {
      flex: 1;
      display: flex;
      justify-content: center;
      font-size: 18px;
    }

    p:nth-child(1) {
      color: gray;
      font-size: 16px;
    }

  }

  .dl:last-child {
    margin-right: 0;
  }
}

.Withdrawal {
  width: 97%;
  height: 100%;
  padding-bottom: 100px;
  margin: 0 2% 100px 1%;



  .cool {
    display: flex;
    flex-direction: column;

    p {
      display: flex;
      flex-direction: row;
      line-height: 20px;
    }

    p:nth-child(1) {
      align-items: center;

      span {
        margin-right: 10px;
      }
    }

    p:nth-child(2) {
      justify-content: center;
      align-items: center;
    }

    p:nth-child(5) {
      justify-content: center;

      button {
        width: 120px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        border: 1px solid #c5c5c5;
        font-size: 16px;
        border-radius: 5px;
        background: none;

      }

      button:nth-child(2) {
        margin-left: 20px;
        background: #13ce66;
        border: none;
        color: #fff;
      }
    }
  }

  .top {
    h4 {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 16px;

      span {
        margin-right: 10px;
      }
    }
  }

  .center {
    width: 100%;
    height: 200px;

    h4 {
      height: 30px;
      display: flex;
      align-items: center;

      span {
        font-size: 18px;
      }
    }

    .ceil {
      height: 100%;
      display: flex;
      flex-direction: column;

      li {
        display: flex;
        flex-direction: row;
        font-size: 18px;

        dl {
          flex: 1;
          display: flex;
          flex-direction: column;
        }
      }

      li:nth-child(1) {
        flex: 4;
        margin-bottom: 10px;

        dl {
          height: 100%;

          dt,
          dd {
            flex: 1;
            display: flex;
            justify-content: center;
            // align-items: center;
          }

          dt {
            font-size: 24px;
            font-weight: bolder;
          }
        }

        dl:nth-child(5) {
          display: flex;
          justify-content: center;
          align-items: center;

          button {
            width: 120px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            background-color: blue;
          }
        }

        dl:nth-child(4) {
          dd {
            margin-left: -1%;
            color: blue;
          }
        }
      }

      li:nth-child(2) {
        flex: 6;
        border: 1px solid gray;
        border-radius: 15px;


        dt,
        dd {
          display: flex;
          justify-content: center;

        }

        dt {
          flex: 1;
          align-items: center;
        }

        dd {
          flex: 9;
          flex-direction: column;

          span {
            flex: 1;

            display: flex;
            justify-content: center;
            align-items: center;
          }

          span:nth-child(1) {
            padding: 10px 0;
          }
        }
      }
    }
  }
}
</style>