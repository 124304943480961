<template>
  <div class="allType">
    <div class="top">
      <h4>流量中心</h4>
      <li>
        <span v-for="(item, index) in list" :key="index" @click="checkMethods(index)" :style="{
          'border-bottom': checkedIndex == index ? '1px solid blue' : 'none',
          color: checkedIndex == index ? 'blue' : '#000'
        }">
          {{ item }}
        </span>
      </li>
    </div>
    <div class="center">
      <!-- <h4>流量平台对接流程</h4> -->
      <li>
        <span v-for="(item, index) in deslist" :key="index">{{ item }}</span>
      </li>
    </div>
    <div class="bottom" v-if="allmap.length < 5">
      <div class="content">
        <li v-for="(item, index) in maplist" :key="index">
          <dl>
            <dt>
              <el-image style="width: 120px; height: 120px" :src="tupian + item.imageUrl" fit="scale-down">
              </el-image>
            </dt>
            <dd>
              <span>
                {{ item.name }}
              </span>
            </dd>
          </dl>
          <div class="p"><button @click="startMethods(item)">申请开通</button></div>
        </li>
      </div>
    </div>
    <div class="bottom1" v-else>
      <div class="content" v-for="(item, index) in maplist" :key="index">
        <li v-for="(items, indexs) in item" :key="indexs">
          <dl>
            <dt>
              <el-image style="width: 120px; height: 120px" :src="tupian + items.imageUrl" fit="scale-down">
              </el-image>
            </dt>
            <dd>
              <span>
                {{ items.name }}
              </span>
            </dd>
          </dl>
          <div class="p"><button @click="startMethods(items)">申请开通</button></div>
        </li>
      </div>
    </div>
    <el-dialog title="申请开通" :visible.sync="dialoag" width="40%" @close="MoneyDialoag" append-to-body
      :close-on-click-modal="false">
      <el-form :model="formobj" ref="editFormRef" label-width="140px">
        <el-form-item label="开通场站：">
          <!-- multiple -->
          <el-select v-model="formobj.stationIds" placeholder="请选择你的开通场站" filterable @change="stationMethods">
            <el-option v-for="item in Stationlist" :key="item.id" :label="item.stationName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="直流：" v-if="formobj.stationIds !== '' && objValue.fastCount > 0">
          <span style="padding-right: 10px;">数量：{{ objValue.fastCount
            }}</span><span style="padding-right: 10px;">金额：{{ objValue.fastMoney }}元</span><span
            style="padding-right: 10px;">总价：{{ objValue.fastCountMoney }}元</span>
        </el-form-item>
        <el-form-item label="交流：" v-if="formobj.stationIds !== '' && objValue.slowCount > 0">
          <span style="padding-right: 10px;">数量：{{ objValue.slowCount
            }}</span><span style="padding-right: 10px;">金额：{{ objValue.slowMoney }}元</span><span
            style="padding-right: 10px;">总价：{{ objValue.slowCountMoney }}元</span>
        </el-form-item>
        <el-form-item label="开通时间类型：">
          <el-select v-model="formobj.TimeType" placeholder="请选择你的开通时间" filterable @change="titleTypeMethods">
            <el-option v-for="item in TimelistType" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开通时间：">
          <el-select v-model="formobj.Time" placeholder="请选择你的开通时间" filterable @change="titleMethods">
            <el-option v-for="item in Timelist" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开通金额：">
          <el-input v-model="formobj.money" disabled v-if="typing"></el-input>
          <span v-else>免费</span>
        </el-form-item>
        <el-form-item label="开通的场站金额（交直流总额）：">
          <el-input v-model="formobj.checkMoney" disabled v-if="typing"></el-input>
          <span v-else>免费</span>
        </el-form-item>
        <el-form-item label="优惠码：">
          <el-input v-model="formobj.Freecode"></el-input>
        </el-form-item>
        <el-form-item label="" v-if="formobj.Freecode == ''">
          <p style="color:red">该订单使用优惠码后，减免1000元</p>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
                <el-button @click="quxiao">取消</el-button>
                <el-button v-debounce="okMethods" type="primary">确定</el-button>
              </div>
    </el-dialog>
    <el-dialog title="扫码付款" :visible.sync="dialoag2" width="60%" @close="MoneyDialoag2" append-to-body
      :close-on-click-modal="false">
      <div class="list" style="display: flex;flex-direction: column;">
        <p>
          <VueQr :text='imageurls' style="height: 22vh"></VueQr>
          <span>付款金额：{{ allsum(formobj.checkMoney, money) }}元/{{ formobj.time }}{{ formobj.TimeType == 0 ? '年' : '月' }}</span>
        </p>
        <!-- -->
        <div class="footer" style="display: flex;flex-direction: row;justify-content: center;">
          <el-button @click="MoneyDialoag2">取消</el-button>
          <el-button type="primary" @click="Moneychange">{{ titleBtn }}</el-button>
          <!-- <button style="display: flex;padding: 0 10px;align-items: center;font-size: 15px;color:#fff;background-color: aqua;border-radius: 15px;margin-right: 20px;">取消</button><button @click="Moneychange">{{ titleBtn }}</button> -->
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import VueQr from 'vue-qr';
export default {
  name: 'Flowmanagement',
  components: {
    VueQr
  },
  data() {
    return {
      objValue: [],
      Stationlist: [],
      allmap: [],
      money: 0,
      listobj: {
        year: 1,
        money: 2000
      },
      dialoag2: false,
      Timelist: [
        {
          id: 0,
          name: '一年'
        },
        {
          id: 1,
          name: '二年'
        },
        {
          id: 2,
          name: '三年'
        }],
      TimelistType: [
        {
          id: 0,
          name: '年'
        },
        {
          id: 1,
          name: '月'
        }
      ],
      imageurls: '',
      formobj: {
        Freecode: '',
        money: 0,
        Time: 0,
        TimeType: 0,
        drainageName: '',
        stationIds: '',
        checkMoney: 0,
        checkMoneylow: 0,
        FastMoney: 0,
      },
      typing: false,
      dialoag: false,
      checkedIndex: 0,
      list: [
        '地图平台',
        '聚合平台'
      ],
      title: '',
      deslist: [],
      maplist: [],
      titleBtn: '支付',
      tupian: this.$imgUrls
    }
  },
  computed: {
    allsum: function () {
      return (abc, bac) => {
        return abc * 1 + bac * 1
      }
    }
  },
  created() {
    const that = this;
    that.$http.get(`sysConfig/getSysConfigById/${that.checkedIndex == 0 ? 35 : 36}`).then(res => {
      if (res.data.code !== 0) return;
      that.allmap = JSON.parse(res.data.data.valueName);
      that.deslist = res.data.data.des.split('<br>');
      if (that.allmap.length > 4) return that.clearMethods();
      that.allmap.forEach(item => {
        that.maplist.push({
          imageUrl: item.paramKey.split('-')[1],
          name: item.paramKey.split('-')[0],
          paramValue: item.paramValue
        })
      })
    });
    that.$http.get('station-info/getAllStationInfoName', {
      params: {
        roleId: window.sessionStorage.getItem('roleId'),
        stationMode: '0,1',
        stationIds: window.sessionStorage.getItem('stationIds')
      }
    }).then(res => {
      that.Stationlist = res.data.code !== 0 ? [] : res.data.data;
    })

  },
  mounted() {

  },
  methods: {
    clearMethods() {
      const that = this;
      that.maplist = that.defaultMethods();
      that.maplist.forEach(item => {
        item.forEach(items => {
          items.imageUrl = items.paramKey.split('-')[1];
          items.name = items.paramKey.split('-')[0];
          items.paramValue = items.paramValue
        })
      });
    },
    okMethods() {
      this.$http.post('drainage/createNative', {
        // this.money*1+this.formobj.checkMoney*1
        money: parseFloat('0.1'),
        stationIds: this.formobj.stationIds,
        drainageName: this.formobj.drainageName,
        code: this.formobj.Freecode,
        adminId: window.sessionStorage.getItem('adminId') * 1,
        drainageId: 0,
        openingTime: this.formobj.TimeType == 0 ? this.formobj.Time : this.formobj.Time / 12,
        type: this.checkedIndex
      }).then(res => {
        this.imageurls = res.data.QrCode;
        this.dialoag = false;
        this.dialoag2 = true;
      })
    },
    quxiao() {
      this.dialoag = false;
      this.formobj.Time = 0;
      this.formobj.Freecode = '';
      this.formobj.money = 0;
      this.money = 0;
    },
    stationMethods(e) {
      const that = this;
      that.$http.get(`drainage-station-info/getAllPileCountByStationId/${e}`, {
        params: {
          stationId: e
        }
      }).then(res => {
        if (res.data.code !== 0) return;
        that.objValue = res.data.data;
        console.log(that.objValue, '249');
      })
    },
    titleTypeMethods(e) {
      this.Timelist = [
        {
          id: 0,
          name: e == 0 ? '一年' : '一月'
        },
        {
          id: 1,
          name: e == 0 ? '二年' : '二月'
        },
        {
          id: 2,
          name: e == 0 ? '三年' : '三月'
        }
      ];
      this.titleMethods(0);
    },
    titleMethods(e) {
      if (this.formobj.TimeType == 0) {
        this.formobj.money = e == 0 ? 12 * this.money : e == 1 ? 24 * this.money : 36 * this.money;
      } else {
        this.formobj.money = e == 0 ? 1 * this.money : e == 1 ? 2 * this.money : 3 * this.money;
      }
      this.publicMethods(e, this.formobj.TimeType)
    },
    // 開通時間判斷開通總金額
    publicMethods(e, type) {
      if (type == 0) {
        if (this.objValue.slowCountMoney > 0) {
          this.formobj.checkMoneylow = e == 0 ? 12 * this.objValue.slowCountMoney : e == 1 ? 24 * this.objValue.slowCountMoney : 36 * this.objValue.slowCountMoney;
        } else {
          this.formobj.checkMoneylow = 0;
        }
        if (this.objValue.fastCountMoney > 0) {
          this.formobj.checkMoneyFast = e == 0 ? 12 * this.objValue.fastCountMoney : e == 1 ? 24 * this.objValue.fastCountMoney : 36 * this.objValue.fastCountMoney;
        } else {
          this.formobj.checkMoneyFast = 0;
        }
        this.formobj.checkMoney = this.formobj.checkMoneylow * 1 + this.formobj.checkMoneyFast * 1;
      } else {
        if (this.objValue.slowCountMoney > 0) {
          this.formobj.checkMoneylow = e == 0 ? 1 * this.objValue.slowCountMoney : e == 1 ? 2 * this.objValue.slowCountMoney : 3 * this.objValue.slowCountMoney;
        } else {
          this.formobj.checkMoneylow = 0;
        }
        if (this.objValue.fastCountMoney > 0) {
          this.formobj.checkMoneyFast = e == 0 ? 1 * this.objValue.fastCountMoney : e == 1 ? 2 * this.objValue.fastCountMoney : 3 * this.objValue.fastCountMoney;
        } else {
          this.formobj.checkMoneyFast = 0;
        }
        this.formobj.checkMoney = this.formobj.checkMoneylow * 1 + this.formobj.checkMoneyFast * 1;
      }

    },
    Moneychange() {
      this.$http.post('drainage/createNative', {
        // this.money*1+this.formobj.checkMoney*1
        money: parseFloat('0.1'),
        stationIds: this.formobj.stationIds,
        drainageName: this.formobj.drainageName,
        code: this.formobj.Freecode,
        adminId: window.sessionStorage.getItem('adminId') * 1,
        drainageId: 0,
        openingTime: this.formobj.TimeType == 0 ? this.formobj.Time : this.formobj.Time / 12,
        type: this.checkedIndex
      }).then(res => {
        this.imageurls = res.data.QrCode;
        this.dialoag = false;
        this.dialoag2 = true;
      })
      // if (this.titleBtn == '已付款') return this.dialoag2 = false;
      // this.$confirm('付款后，平台会在46小时内处理并告知流量方处理上线，如未按时上线，请联系运维管理人员。', '扫码确认', {
      //   confirmButtonText: '确定'
      // }).then(() => {
      //   this.titleBtn = '已付款';
      // })
    },
    MoneyDialoag2() {

    },
    MoneyDialoag() {

    },
    startMethods(e) {
      const that = this;
      that.money = e.paramValue;
      // that.formobj.money = that.money;
      if (that.formobj.TimeType == 0) {
        that.formobj.money = that.formobj.Time == 0 ? 12 * that.money : that.formobj.Time == 1 ? 24 * that.money : 36 * that.money;
      } else {
        that.formobj.money = that.formobj.Time == 0 ? 1 * that.money : that.formobj.Time == 1 ? 2 * that.money : 3 * that.money;
      }
      that.publicMethods(that.formobj.Time, that.formobj.TimeType);
      that.formobj.drainageName = e.name;
      if (e.paramValue == '敬请期待') return that.$message.error(e.paramValue);
      that.typing = e.paramValue !== 0 ? true : false;
      that.$confirm('是否需要开通已选择的服务?', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 开通服务
        // that.$router.push('/DefaultH5')
        that.dialoag = true;
      }).catch(() => {
        // 取消开通服务
        that.$confirm('取消后，您申请的服务将不生效，是否仍要操作?', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // 确定取消
        })
      })
    },
    checkMethods(e) {
      const that = this;
      that.checkedIndex = e;
      that.maplist = [];
      that.$http.get(`sysConfig/getSysConfigById/${that.checkedIndex == 0 ? 35 : 36}`).then(res => {
        if (res.data.code !== 0) return;
        that.allmap = JSON.parse(res.data.data.valueName);
        that.deslist = res.data.data.des.split('<br>');
        if (that.allmap.length > 4) return that.clearMethods();
        that.allmap.forEach(item => {
          that.maplist.push({
            imageUrl: item.paramKey.split('-')[1],
            name: item.paramKey.split('-')[0],
            paramValue: item.paramValue
          })
        })
      })
    },
    defaultMethods() {
      let result = [];
      for (let i = 0; i < this.allmap.length; i += 4) {
        result.push(this.allmap.slice(i, i + 4));
      }
      return result;
    }
  }
};
</script>

<style lang='less' scoped>
.allType {
  width: 100%;
  height: 100%;

  list-style: none;

  .top {
    margin: 0 2% 0 1%;
    height: 120px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid gray;

    h4,
    li {
      flex: 1;
      display: flex;
      flex-direction: row;
      font-size: 20px;

      span {
        padding-bottom: 5px;
      }

      span:nth-child(1) {
        margin-right: 100px;

      }
    }

    li {
      font-size: 18px;
    }
  }

  .center {
    margin: 0 2% 0 1%;
    margin-top: 20px;
    background-color: #f8f2f2;
    // opacity: .5;
    width: 97%;
    display: flex;
    flex-direction: column;

    li {
      font-size: 18px;
      display: flex;
      flex-direction: column;

      span {
        line-height: 40px;
        display: flex;
        align-items: center;
        text-indent: 2em;
      }

      span:first-child {
        font-size: 20px;
        text-indent: .5em;
        font-weight: bolder;
        border-bottom: 1px solid #eee;
      }
    }
  }

  .bottom {
    margin: 50px 20px;
    display: flex;
    flex-direction: column;

    .content {
      height: 200px;
      width: 100%;
      display: flex;
      flex-direction: row;

      li {
        flex: 1;
        display: flex;
        flex-direction: column;
        border: 1px solid #c5c5c5;
        margin-right: 100px;
        border-radius: 15px;

        dl {
          flex: 7;
          display: flex;
          flex-direction: row;
          font-size: 18px;

          dt,
          dd {
            display: flex;
            align-items: center;
          }

          dt {
            flex: 3;
            justify-content: center;
          }

          dd {
            flex: 7;
          }
        }

        .p {
          flex: 3;
          display: flex;
          justify-content: center;
          align-items: center;
          border-top: 1px solid #c5c5c5;

          button {
            height: 30px;
            width: 100px;
            display: flex;
            font-size: 16px;
            justify-content: center;
            align-items: center;
            border-radius: 15px;
            outline: none;
            background-color: #fff;
            color: blue;
            border: 1px solid blue;
          }
        }
      }
    }
  }

  .bottom1 {
    margin: 10px 20px;
    display: flex;
    flex-direction: column;

    .content {
      display: flex;
      flex-direction: row;
      margin-bottom: 40px;

      li {
        width: 450px;
        display: flex;
        flex-direction: column;
        border: 1px solid #c5c5c5;
        margin-right: 1%;
        border-radius: 15px;
        height: 200px;

        dl {
          flex: 7;
          display: flex;
          flex-direction: row;
          font-size: 18px;

          dt,
          dd {
            display: flex;
            align-items: center;
          }
        }

        .p {
          flex: 3;
          display: flex;
          justify-content: center;
          align-items: center;
          border-top: 1px solid #c5c5c5;

          button {
            height: 30px;
            width: 100px;
            display: flex;
            font-size: 16px;
            justify-content: center;
            align-items: center;
            border-radius: 15px;
            outline: none;
            background-color: #fff;
            color: blue;
            border: 1px solid blue;
          }
        }
      }

      li:nth-child(4) {
        margin-right: 0;
      }
    }
  }
}
</style>