<template>
  <!-- 卡片视图区域 -->
  <el-card class="card">
    <el-form :inline="true" :model="queryInfo" class="form">
      <div>
        <el-form-item label="所属电站">
          <el-select v-model="suoshudianzhan" placeholder="请选择" @change="change_value" clearable
            @clear="getPowerStationList" filterable>
            <el-option v-for="item in powerStationList" :key="item.id" :label="item.stationName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户编号">
          <el-input placeholder="请输入用户编号" clearable @keyup.enter.native="getPowerStationList"
            v-model="queryInfo.userNumber" @change="current" @clear="getPowerStationList">
          </el-input>
        </el-form-item>
        <el-form-item label="用户手机号">
          <el-input placeholder="请输入用户手机号" clearable @change="current" @keyup.enter.native="getPowerStationList"
            v-model="queryInfo.phone" @clear="getPowerStationList">
          </el-input>
        </el-form-item>
        <el-form-item label="停车时间">
          <el-date-picker type="datetimerange" :picker-options="pickerOptions" v-model="values" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']" @change="changeTime">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="是否减免停车费用">
          <el-select v-model="queryInfo.isDeduction" placeholder="请选择" clearable @clear="getPowerStationList"
            filterable>
            <el-option v-for="item in isDeductionList" :key="item.isDeduction" :label="item.stationName"
              :value="item.isDeduction">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="支付状态">
          <el-select v-model="queryInfo.payState" @change="current" placeholder="请选择" clearable
            @clear="getPowerStationList" filterable>
            <el-option v-for="items in payStateList" :key="items.payState" :label="items.stationName"
              :value="items.payState">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="debounceClick">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-refresh-left" @click="chongzhi">重置</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="daochu" v-if="daochubtn">导出</el-button>
        </el-form-item>
      </div>
      <!-- <el-form-item label="启动方式" :span="3">
              <el-select v-model="queryInfo.startType" placeholder="请选择" clearable @clear="getPowerStationList"
                style="width: 150px;" filterable>
                <el-option v-for="item in startTypeList" :key="item.startType" :label="item.stateName"
                  :value="item.startType">
                </el-option>
              </el-select>
            </el-form-item> -->
    </el-form>
    <div class="listname" v-if="listall.length > 0">
      <div class="dl" v-for="(item, index) in listall" :key="index">
        <p>
          {{ item.name }}
        </p>
        <p>
          {{ index > 1 ? timesalls(item.countMoney, 100, 2) : timesalls(item.countMoney, 60, 2) }}
        </p>
      </div>
    </div>
    <!-- table表格区域 -->
    <el-table class="table" :data="chargingList" border empty-text="暂无数据">
      <!-- <el-table-column align="center" label="序列号" prop="id" width="80"></el-table-column> -->
      <el-table-column align="center" label="停车订单编号" prop="parkBillCode" width="180">
        <template slot-scope="scope">
          <div>
            {{ jiequ(scope.row.parkBillCode, scope.row.pileCode) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="groundLockCode" label="电桩编号" width="160">
        <template slot-scope="scope">
          <div>
            {{ scope.row.pileCode }}{{ scope.row.gunNumber==1?'A':scope.row.groundLockCode==2?'B':'' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="用户手机号" prop="phone" width="110"></el-table-column>
      <el-table-column align="center" label="支付类型" prop="startType" width="100">
        <template slot-scope="scope">
          <!-- {{scope.row.startType}} -->
          <img style="width: 40px;
           height: 40px;" :src="scope.row.startType == 0 ? tupian + '小程序.png' : tupian + 'app.png'" alt="">
        </template>
      </el-table-column>
      <el-table-column align="center" label="停车时间" prop="parkTime" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.parkTime == ''">--</span>
          <span v-else>{{ scope.row.parkTime }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="驶离时间" prop="departureTime" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.departureTime == ''">--</span>
          <span v-else>{{ scope.row.departureTime }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="减免单号" prop="deductionNumber">
        <template scope="scope">
          <span>{{ scope.row.deductionNumber == '' ? '--' : scope.row.deductionNumber }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="停车占位时长" prop="parkingOccupancyDuration" width="110">
        <template slot-scope="scope">
          <div>
            {{ timesalls(scope.row.parkingOccupancyDuration, 60, 2) }}小时
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="停车费用" prop="parkingFees" width="90">
        <template slot-scope="scope">
          <div>
            ￥{{ timesalls(scope.row.parkingFees, 100, 2) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="停车减免时长" prop="parkingReductionDuration" width="120">
        <template slot-scope="scope">
          <div>
            {{ timesalls(scope.row.parkingReductionDuration, 60, 2) }}小时
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="订单优惠金额" prop="billMoneygive" width="120">
        <template slot-scope="scope">
          <div>
            ￥{{ timesalls(scope.row.billMoneygive, 100, 2) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="实际计费时长" prop="parkingReductionDurationgive" width="120">
        <template slot-scope="scope">
          <div>
            {{ timesalls(scope.row.parkingReductionDurationgive, 60, 2) }}小时
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="实际收入" prop="reallyParkingFees" width="100">
        <template slot-scope="scope">
          <div>
            ￥{{ timesalls(scope.row.reallyParkingFees, 100, 2) }}
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column align="center" label="用户充电前余额" prop="beforeChargingMoney">
        <template slot-scope="scope">
          <div>
            ￥{{ timesalls(scope.row.beforeChargingMoney,100,0) }}
          </div>
        </template>
      </el-table-column> -->
      <!-- <el-table-column align="center" label="用户唯一编码规则" prop="userNumber" width="140"></el-table-column> -->
      <!-- <template scope="scope">
            <span v-if="scope.row.parkingFees==''">--</span>
            <span v-else>{{ scope.row.parkingFees }}元</span>
          </template> -->

      <!-- <el-table-column align="center" label="停车费用" width="80">
        <template scope="scope">
          <span>{{ timesalls(scope.row.parkingFees, 100, 2) }}元</span><el-tag
            :type="scope.row.isDeduction == 0 ? 'warning' : ''">{{ scope.row.isDeduction == 0 ? '减免' : '不减免' }}</el-tag>
        </template>
      </el-table-column> -->
      <el-table-column align="center" label="支付状态" prop="payState" width="80">
        <template scope="scope">
          <el-tag :type="scope.row.payState == 0 ? 'error' : scope.row.payState == 1 ? 'success' : 'warning'">{{
      scope.row.payState == 0 ? '未支付' : scope.row.payState == 1 ? '已支付' : '停车中' }}</el-tag>
        </template>
      </el-table-column>
      <!-- <el-table-column align="center" label="创建时间" prop="createTime" width="100">
        <template scope="scope">
          <span v-if="scope.row.createTime == ''">--</span>
          <span v-else>{{ scope.row.createTime }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="更新时间" prop="updateTime" width="100">
        <template scope="scope">
          <span v-if="scope.row.updateTime == ''">--</span>
          <span v-else>{{ scope.row.updateTime }}</span>
        </template>
      </el-table-column> -->
    </el-table>
    <!-- 分页导航区域 -->
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page"
      :page-sizes="[5, 20, 50, 100]" :page-size="queryInfo.limit" layout="total, sizes, prev, pager, next, jumper"
      :total="total" background>
    </el-pagination>
  </el-card>
</template>

<script>
import caozuo from "@/utils/pubilc.js";
import { saveBlobToFile } from '@/utils/saveBlobToFile';
export default {
  name: 'chargingWorld',
  data() {
    return {
      daochubtn: false,
      pickerOptions: {
        onPick: obj => {
          this.pickerMinDate = new Date(obj.minDate).getTime()
          const start = this.formatDate(obj.minDate, 'start')
          const end = this.formatDate(obj.maxDate, 'end')
          obj.maxDate && (this.values = [start, end])
        },
        disabledDate: time => {
          if (this.pickerMinDate) {
            const oneMonth = 1000 * 3600 * 24 * 31
            const maxTime = this.pickerMinDate + oneMonth
            const mixTime = this.pickerMinDate - oneMonth + 1000 * 3600 * 24
            const tomorrow = new Date(this.formatDate(new Date().getTime() + 1000 * 3600 * 24, 'start'))
            return time.getTime() >= tomorrow || time.getTime() >= maxTime || time.getTime() < mixTime
          }
        }
      },
      listall: [],
      tupian: this.$imgUrls,
      pickerMinDate: '',
      values: [],
      isDeductionList: [
        {
          isDeduction: 0,
          stationName: '减免'
        }, {
          isDeduction: 1,
          stationName: '不减免'
        }
      ],
      payStateList: [
        {
          payState: 0,
          stationName: '未支付'
        }, {
          payState: 1,
          stationName: '已支付'
        }, {
          payState: 2,
          stationName: '停车中'
        }
      ],
      yincang: true,
      page_num: 0,
      pileType_list: [
        {
          pileType: 0,
          label: '锐创直流桩'
        },
        {
          pileType: 1,
          label: '锐创交流桩'
        },
        {
          pileType: 2,
          label: '无疆(二代)交流桩'
        },
        {
          pileType: 3,
          label: '光法(一代)直流桩'
        },
        {
          pileType: 4,
          label: '光法(一代)交流桩'
        },
        {
          pileType: 5,
          label: '锐创低速桩'
        },
        {
          pileType: 6,
          label: '其他电桩'
        }
      ],
      startTypeList: [
        {
          startType: 0,
          stateName: '小程序启动'
        },
        {
          startType: 1,
          stateName: '平台卡启动'
        },
        {
          startType: 2,
          stateName: '独立卡启动'
        },
        {
          startType: 3,
          stateName: '超级卡启动'
        },
        {
          startType: 4,
          stateName: 'Vin启动'
        },
        {
          startType: 5,
          stateName: '互联互通启动'
        },
        {
          startType: 9,
          stateName: '位置启动方式'
        },
      ],
      // tupian: tupian,
      caozuoquanxian: false,
      shifou: '',
      // 查询充电订单列表所需参数
      queryInfo: {
        userNumber: '',
        payState: '',
        deductionNumber: '',
        departureTime: '',
        parkTime: '',
        parkingFees: '',
        isDeduction: '',
        page: 1,
        limit: 5,
        phone: '',
        billInfoType: 1,
        adminId: window.sessionStorage.getItem('adminId'),
        stationIds: ''
      },
      // 充电订单数据
      chargingList: [],
      stateName: '',
      total: 0,
      value: '',
      suoshudianzhan: '',
      detailList: [],
      // 电站列表数据
      powerStationList: [],
      // 是否已支付
      isPay: [
        {
          label: "未支付",
          payState: 0
        }, {
          label: "已支付",
          payState: 1
        }, {
          label: "停车中",
          payState: 2
        },
      ],
      // 是否无效订单数据
      invalidOrder: [
        {
          label: "是",
          defaultValue: 0
        },
        {
          label: "否",
          defaultValue: 1
        }
      ]
    }
  },

  computed: {
    jiequ() {
      return function (val, num) {
        return val.substring(num.length, val.length)
      }
    },
    time_all() {
      return function (val) {
        const hr = val.billCode.slice(val.pileCode.length, val.billCode.length)
        return hr
      }
    },
    timesalls() {
      return function (value, num, count) {
        return (value / num).toFixed(count)
      }
    },
  },

  watch: {
    chargingList: {
      handler(newval, oldval) {
        this.chargingList = newval
      }
    }
  },
  created() {
    caozuo(106).then(res => {
      this.caozuoquanxian = res;
    })
    // 导出
    caozuo(106).then(res => {
      this.daochubtn = res;
    })
    this.getPowerStationList()
  },

  methods: {
    daochu() {
      if (this.queryInfo.stationIds.length == 0 || this.queryInfo.stationIds == undefined || this.queryInfo.stationIds == null) { this.queryInfo.stationIds = window.sessionStorage.getItem('stationIds') };
      if (this.queryInfo.startTime == null || this.queryInfo.endTime == null) return this.$message.error('请选择你要导出的电站与时间');
      this.$http.get('bill-park-info/export', {
        responseType: 'blob',
        params: {
          startTime: this.queryInfo.startTime,
          endTime: this.queryInfo.endTime,
          stationIds: this.queryInfo.stationIds,
          payState: this.queryInfo.payState * 1
        }
      }).then(res => {
        if (res.data) {
          saveBlobToFile(res.data, '停车订单电站列表');
          this.$message.success('文件导出成功');
        } else {
          this.$message.error('文件导出失败');
        };
      })
    },
    pickBlur() {
      this.pickerMinDate = ''
    },
    formatDate(datetime, type) {
      const time = new Date(datetime)
      const year = time.getFullYear()
      const month = (time.getMonth() + 1).toString().padStart(2, '0')
      const date = (time.getDate()).toString().padStart(2, '0')
      return type === 'start' ? year + '-' + month + '-' + date + ' ' + '00' + ':' + '00' + ':' + '00' : year + '-' + month + '-' + date + ' ' + '23' + ':' + '59' + ':' + '59'
    },
    getDate() {
      const start = new Date()
      this.values[0] = this.formatDate(start.getTime() - 1000 * 3600 * 24 * 30, 'start')
      this.values[1] = this.formatDate(start.getTime(), 'end')
    },
    change_value(e) {
      this.queryInfo.page = 1;
      if (e !== '' && e !== undefined && e !== '') return this.queryInfo.stationIds = e;
    },
    current() {
      this.queryInfo.page = 1;
    },
    changeTime(value) {
      this.queryInfo.page = 1;
      if (value) {
        this.queryInfo.startTime = value[0];
        this.queryInfo.endTime = value[1];
        if (this.queryInfo.startTime == this.queryInfo.endTime) {
          this.$message.error('起始时间不得为同一天！');
          this.queryInfo.startTime = ''
          this.queryInfo.endTime = ''
        }
      } else {
        this.queryInfo.startTime = ''
        this.queryInfo.endTime = ''
        this.pickerMinDate = '';
        this.getChargingOrderList()
      }
    },
    // 查询所有搜索条件
    debounceClick() {
      this.getChargingOrderList()
    },
    zhanshi() {
      this.yincang = !this.yincang
    },
    // 重置搜索条件
    chongzhi() {
      this.queryInfo = {
        userNumber: '',
        payState: '',
        deductionNumber: '',
        departureTime: '',
        parkTime: '',
        parkingFees: '',
        isDeduction: '',
        startTime: '',
        endTime: '',
        page: 1,
        limit: 10,
        phone: '',
        billInfoType: 1,
        adminId: window.sessionStorage.getItem('adminId')
      };
      this.values = [];
      this.suoshudianzhan = '';
      this.getChargingOrderList()
    },
    // 搜索充电订单列表
    async getChargingOrderList() {
      const that = this;
      that.chargingList = [];
      that.total = 0;
      that.queryInfo.stationIds = that.queryInfo.stationIds == '' ? window.sessionStorage.getItem('stationIds') : that.suoshudianzhan;
      await that.$http.get('bill-park-info/getAllBillParkInfo', { params: that.queryInfo }).then(resb => {
        if (resb.data.code !== 0) return that.listall = [];
        that.chargingList = resb.data.data;
        that.total = resb.data.count;
        that.listall = [
          {
            name: '停车总时长（分钟）',
            countMoney: resb.data.data[0].totalParkTimeLength
          },
          {
            name: '停车优惠总时长（分钟）',
            countMoney: resb.data.data[0].totalParreDuctionTimeLength
          },
          {
            name: '停车总额（元）',
            countMoney: resb.data.data[0].totalParkMoney
          },
          {
            name: '停车优惠总额（元）',
            countMoney: resb.data.data[0].totalParDiscountMoney
          },
          {
            name: '停车实收总额（元）',
            countMoney: resb.data.data[0].totalParkMoney - resb.data.data[0].totalParDiscountMoney
          }
        ];
      });
    },
    // 监听pageSize改变的事件
    handleSizeChange(pageSize) {
      this.queryInfo.limit = pageSize;
      this.getPowerStationList()
    },
    // 获取电站列表数据
    getPowerStationList() {
      this.queryInfo.stationIds = this.suoshudianzhan ? this.suoshudianzhan : window.sessionStorage.getItem('stationIds');
      this.$http.get('bill-park-info/getAllBillParkInfo', { params: this.queryInfo }).then(resb => {
        if (resb.data.code !== 0) return this.listall = [];
        this.chargingList = resb.data.data;
        this.total = resb.data.count;
        this.listall = [
          {
            name: '停车总时长（小时）',
            countMoney: resb.data.data[0].totalParkTimeLength
          },
          {
            name: '停车优惠总时长（小时）',
            countMoney: resb.data.data[0].totalParreDuctionTimeLength
          },
          {
            name: '停车总额（元）',
            countMoney: resb.data.data[0].totalParkMoney
          },
          {
            name: '停车优惠总额（元）',
            countMoney: resb.data.data[0].totalParDiscountMoney
          },
          {
            name: '停车实收总额（元）',
            countMoney: resb.data.data[0].totalParkMoney - resb.data.data[0].totalParDiscountMoney
          }
        ];

      });
      this.$http.get('station-info/getAllStationInfoName', {
        params: {
          roleId: window.sessionStorage.getItem('roleId'),
          stationIds: window.sessionStorage.getItem('stationIds'),
          stationMode: '0,1,2'
        }
      }).then(resb => {
        if (resb.data.code == 0) {
          resb.data.data.forEach(item => {
            this.powerStationList.push({
              id: item.id,
              stationName: item.stationName
            })
          })
        }
      });

    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getPowerStationList()
    },
    // 展示订单包含子项内容
    async showDetail(id) {
      const { data: res } = await this.$http.get('order/getOrderById?id=' + id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.detailList = res.data
    },
    // 双击展开折叠
    dbclick(row, column, event) {
      // console.log(event.currentTarget.querySelector(".el-table__expand-icon"))
      if (event.currentTarget.querySelector(".el-table__expand-icon")) {
        event.currentTarget.querySelector(".el-table__expand-icon").click()
      }
    },
    // 结算订单
    async jiesuan(row) {
      const confirmResult = await this.$confirm('此操作将结算该订单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消结算！')
      }
      const { data: res } = await this.$http.post('bill-info/billSettlement', {
        id: row.id,
        adminId: window.sessionStorage.getItem('adminId') * 1,
        startType: row.startType,
        billMoney: row.billMoney,
        billInfoType: 1
      })
      if (res.code !== 0) {
        return this.$message.error(res.msg)
      }
      this.$message.success('结算成功！')
      this.getPowerStationList()
    }
  }
};
</script>

<style lang="less" scoped>
.listname {
  display: flex;
  flex-direction: row;
  height: 120px;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 20px;
  border: 1px solid gray;
  list-style: none;
  border-radius: 15px;
  font-size: 16px;

  .dl {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 20px;

    p {
      flex: 1;
      display: flex;
      justify-content: center;
      // font-size: 16px;
    }

    p:nth-child(1) {
      color: gray;
      // font-size: 16px;
    }

  }

  .dl:last-child {
    margin-right: 0;
  }
}
</style>
