<template>
  <div class="login_page">
    <div class="login_container">
      <div class="login_body">
        <div class="left">
          <div class="centers">

            <h4>
              <span>
                智慧助理
              </span>
              <span>
                .
              </span>
              <span>云充电平台</span>
            </h4>
            <div class="logins">
              <img src="@/assets/images/dzlogo.png" alt="">
            </div>
            <div class="current">
              <i>智能管理，让充电更简单</i>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="center">
            <h4>
              欢迎登录电助理
            </h4>
            <el-form ref="loginFormRef" :model="loginForm" :rules="loginFormRules" class="login_form">
              <el-form-item prop="userName">
                <el-input v-model="loginForm.userName" placeholder="请输入用户名" prefix-icon="el-icon-user">
                </el-input>
              </el-form-item>
              <el-form-item prop="passWord">
                <el-input v-model="loginForm.passWord" placeholder="请输入密码" prefix-icon="el-icon-lock" type="password"
                  show-password>
                </el-input>
              </el-form-item>
              <el-form-item prop="code">
                <div class="ceil"
                  style="display: flex;flex-direction: row;justify-content: space-between;align-items: center;">
                  <el-input v-model="loginForm.code" placeholder="请输入验证码" style="width: 60%;">
                  </el-input>
                  <el-image :src="url" @click="onClickImg" @error="onClickImg"
                    style="cursor: pointer;width: 40%;height: 40px;margin-left: 20px;"></el-image>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button class="btns" type="primary" @click="login">登&nbsp;&nbsp;录</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'loginWorld',

  data() {
    const validateUser = (rule, value, callback) => {
      const reg = /^[a-zA-Z0-9_]{4,16}$/
      if (value === '' || value === undefined || value === null) {
        callback(new Error('请输入账号'))
      } else if (!reg.test(value)) {
        callback(new Error('账号应在4到16位之间,包含字母、数字或下划线'))
      } else {
        callback()
      }
    }
    const validatePass = (rule, value, callback) => {
      const reg = /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{6,20}$/
      if (value === '' || value === undefined || value === null) {
        callback(new Error('请输入密码'))
      } else {
        callback()
      }
    }
    return {
      // 为登陆表单设置数据绑定
      url: '',
      loginForm: {
        userName: null,
        passWord: null,
        code: ''
      },
      queryInfo: {
        roleId: window.sessionStorage.getItem('roleId'),
        // 当前展示的页码数
        page: 1,
        // 当前每页显示的数据条数
        limit: 100
      },
      // 表单验证规则对象
      loginFormRules: {
        // 用户名的校验规则
        userName: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { validator: validateUser, trigger: 'blur' }
        ],
        // 密码的校验规则
        passWord: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' }
        ]
      }
    }
  },

  created() {
    window.addEventListener('keydown', this.keyDown);
    // 刷新验证码
    this.onClickImg();
  },

  // 离开页面：事件销毁
  destroyed() {
    window.removeEventListener('keydown', this.keyDown, false)
  },

  methods: {
    // 验证码获取
    async onClickImg() {
      const { data: res } = await this.$http.get('admin/createKaptchaCodeImg', { responseType: 'blob' })
      this.url = window.URL.createObjectURL(res)
    },
    async login() {
      const that = this;
      that.$refs.loginFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await that.$http.post('admin/login', that.loginForm)
        // 登录提示
        that.$message({
          type: res.code == 0 ? 'success' : 'error',
          message: res.code == 0 ? '登陆成功' : '登录失败'
        });
        that.loginForm.code = res.code !== 0 ? '' : that.loginForm.code;
        if (res.code !== 0) return that.onClickImg();
        // 1.将登陆成功之后的token。保存到客户端的 sessionStorage 中
        //  1.1 项目中除了登录之外的其他API接口，必须在登陆之后才能访问
        //  1.2 token 只应用在当前网站打开期间生效，所以将token保存在sessionStorage中
        // 请求头
        window.sessionStorage.setItem('token', res.data.token)
        // adminID
        window.sessionStorage.setItem('adminId', res.data.admin.id)
        // 用户所有场站
        window.sessionStorage.setItem('stationIds', res.data.admin.stationIds)
        // 用户所在的权限（权限名称、权限id所包含的页面）
        window.sessionStorage.setItem('roleId', res.data.admin.roleId)
        // 用户的状态（是否禁用）
        window.sessionStorage.setItem('status', res.data.admin.status)
        // 用户的登录名称
        window.sessionStorage.setItem('trueName', res.data.admin.realName)
        // 用户的小程序appid
        window.sessionStorage.setItem('appId', res.data.admin.wxIds)
        // 分账商户总Id
        if (res.data.admin.stationLedgerList !== null && res.data.admin.stationLedgerList !== undefined && res.data.admin.stationLedgerList !== '' && res.data.admin.stationLedgerList.length > 0) {
          window.sessionStorage.setItem('stationTotalLedgerListId', res.data.admin.stationLedgerList.map(item => item.id).join(','));
        }
        that.queryInfo.roleId = res.data.admin.roleId;
        // 获取该用户菜单列表
        const { data: ress } = await that.$http.get('adminMenu/getAllAdminMenuByRoleId', { params: that.queryInfo })
        ress.data.forEach(item => {
          window.sessionStorage.setItem('oldUrl', item.url == ress.data[0].childList[0].url ? ress.data[0].url : ress.data[0].childList[0].url);
          window.sessionStorage.setItem('oldmenuname', item.url == ress.data[0].childList[0].url ? ress.data[0].menuname : ress.data[0].childList[0].menuname);
          that.$router.push('/' + item.url == ress.data[0].childList[0].url ? ress.data[0].url : ress.data[0].childList[0].url);
        })
      })
    },
    // 监听回车键执行事件
    keyDown(e) {
      // 回车则执行登录方法 enter键的ASCII是13
      if (e.keyCode === 13 || e.keyCode === 108) {
        this.login() // 需要执行的方法方法
      }
    }
  }
};
</script>

<style lang="less" scoped>
.login_page {
  width: 100vw;
  height: 100vh;
  background-image: url(@/assets/images/bg.jpg);
  background-size: 100% 100%;
  overflow: hidden !important;
}

.login_container {
  position: fixed;
  top: 46.5%;
  width: 100vw;
  height: 100%;
}

.login_body {
  width: 40%;
  height: 50%;
  border-radius: 15px;
  background-color: rgba(161, 94, 242, 0.7);
  // opacity: 0.5;
  position: absolute;
  left: 50%;
  top: 5%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .left,
  .right {
    // margin:5% 0;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .left {

    // background-color: red;
    .centers {
      // background-color: royalblue;
      margin: 5%;
      display: flex;
      flex-direction: column;
      border-right: 1px solid rgb(209, 202, 202);

      // justify-content: center;
      h4,
      .current {
        flex: 1;
        // background-color: olive;
        color: #fff;
      }

      h4 {
        display: flex;
        flex-direction: row;
        font-size: 24px;
        letter-spacing: 10px;
      }

      .logins {
        flex: 8;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .current {
        font-size: 20px;
        display: flex;
        align-items: center;
        letter-spacing: 10px;
      }
    }


  }

  .right {
    margin-left: -10px;

    // background-color: rebeccapurple;
    .center {
      margin: 5%;
      display: flex;
      flex-direction: column;
      // background:linear-gradient(white,0.5);
      background-color: rgba(255, 255, 255, 0.75);
      border-radius: 15px;

      h4 {
        flex: 1;
        letter-spacing: 10px;
        font-size: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .login_form {
        flex: 9;
        display: flex;
        flex-direction: column;

        .el-form-item {
          margin: 5% 10%;
        }

        .btns {
          width: 100%;
          height: 100%;
          font-size: 24px;
        }
      }
    }

  }
}
</style>