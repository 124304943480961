<template>
  <div>
    <!-- 卡片视图区域 直充-->
    <el-card>
      <!-- style="margin-bottom:20px ;" -->
      <el-row :gutter="20" style="margin-bottom: 10px;">
        <el-col :span="3" label="车牌号">
          <el-input placeholder="请输入车牌号" clearable v-model="queryInfo.carNumber" @clear="getVinAccountList"
            @change="current">
          </el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" icon="el-icon-search" @click="getVinAccountList">查询</el-button>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" icon="el-icon-refresh-left" @click="chongzhi">重置</el-button>
        </el-col>
        <el-col :span="3">
          <el-button type="success" icon="el-icon-plus" @click="showVinAccount">添加</el-button>
        </el-col>
      </el-row>
      <!-- table表格区域 -->
      <el-table :data="vinAccountList" border @selection-change="handleSelectionChange" @row-dblclick="dbclick">
        <el-table-column align="center" type="selection" width="55"></el-table-column>
        <el-table-column align="center" type="expand">
          <template slot-scope="scope">
            <el-form label-position="left" class="demo-table-expand">
              <el-row :gutter="20" type="flex" class="row-bg" justify="space-around">
                <el-col :span="8">
                  <el-form-item label="车架号：">
                    <span v-if="scope.row.carvin">{{ scope.row.carvin }}</span>
                    <span v-else> -- </span>
                  </el-form-item>
                  <el-form-item label="车牌号：">
                    <span v-if="scope.row.carNumber">{{ scope.row.carNumber }}</span>
                    <span v-else> -- </span>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="所属公司：">
                    <span v-if="scope.row.companyName">{{ scope.row.companyName }}</span>
                    <span v-else> -- </span>
                  </el-form-item>
                  <el-form-item label="更新时间：">
                    <span v-if="scope.row.updateTime">{{ scope.row.updateTime }}</span>
                    <span v-else> -- </span>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column align="center" label="场站编号" prop="stationName"></el-table-column>
        <el-table-column align="center" label="车架号" prop="carvin"></el-table-column>
        <el-table-column align="center" label="车牌编号" prop="carNumber"></el-table-column>
        <el-table-column align="center" label="自编号" prop="selfNumbering">
          <template slot-scope="scope">
            <span v-if="scope.row.selfNumbering !== null">
              {{ scope.row.selfNumbering }}
            </span><span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="路别" prop="columns">
          <template slot-scope="scope">
            <span v-if="scope.row.columns !== null">
              {{ scope.row.columns }}
            </span><span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="所属公司" prop="companyName"></el-table-column>
        <el-table-column align="center" label="状态" prop="electricCardState" width="80">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.type" :active-color="scope.row.type == false ? '#ff4949' : '#13ce66'"
              :inactive-color="scope.row.type == false ? '#ff4949' : '#13ce66'"
              @change="change_type(scope.row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column align="left" label="操作" v-if="caozuoquanxian">
          <template slot-scope="scope">
            <el-button size="small" type="primary" icon="el-icon-edit" :disabled="scope.row.b_j_anniu"
              @click="showVinAccount(scope.row)">编辑</el-button>
            <el-button size="small" :disabled="scope.row.b_j_anniu"
              @click="detailMethods(scope.row)">用户详情</el-button>
            <!-- <el-button size="small" type="danger" @click="removeById(scope.row)">删除</el-button> -->
          </template>
        </el-table-column>
        <div slot="empty" class="empty">
          <span>暂无数据</span>
        </div>
      </el-table>
      <!-- 分页导航区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.page" :page-sizes="[6, 20, 50, 100]" :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="total" background>
      </el-pagination>
    </el-card>
    <!-- 添加&&编辑直充用户的对话框 -->
    <el-dialog :title="title" :visible.sync="editDialogVisible" width="35%" @close="editDialogClosed" append-to-body
      :close-on-click-modal="false">
      <el-form :model="vinAccountForm" :rules="vinAccountFormRules" ref="vinAccountFormRef" label-width="80px">
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="12">
            <!-- 内容主体区域 -->
            <el-form-item label="站点选择" prop="stationId" required>
              <el-select v-model="vinAccountForm.stationId">
                <el-option v-for="item in stationList" :key="item.id" :value="item.id"
                  :label="item.stationName"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属公司" prop="companyName" required>
              <el-input v-model="vinAccountForm.companyName"></el-input>
            </el-form-item>
            <el-form-item label="路别：" prop="columns">
              <el-input v-model="vinAccountForm.columns"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车架号" prop="carvin" required>
              <el-input v-model="vinAccountForm.carvin"></el-input>
            </el-form-item>
            <el-form-item label="车牌号" prop="carNumber" required>
              <el-input v-model="vinAccountForm.carNumber"></el-input>
            </el-form-item>
            <el-form-item label="自编号：" prop="selfNumbering">
              <el-input v-model="vinAccountForm.selfNumbering"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 底部按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogClosed">取 消</el-button>
        <el-button type="primary" v-debounce="editVinAccount">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 用户详情 -->
    <el-dialog title="" :visible.sync="Detaildialoag" width="60%" @close="DetailBtn" append-to-body
      :close-on-click-modal="false">
      <div class="cardslist">
        <div class="top">
          <dl>
            <dt>
              <el-image style="width: 200px; height: 200px" :src="tupian + 'morentouxiang.png'"
                fit="scale-down"></el-image>
            </dt>
            <dd>
              <!-- 横向排布 -->
              <li>
                <!-- p：横向排布，均分 -->
                <p><span>{{ listobj.columns==undefined||listobj.columns==null||listobj.columns==''?'--':listobj.columns }}</span></p>
                <p><span>{{ listobj.selfNumbering==undefined||listobj.selfNumbering==null||listobj.selfNumbering==''?'--':listobj.selfNumbering }}</span></p>
                <p><span>{{ listobj.carNumber==undefined||listobj.carNumber==null||listobj.carNumber==''?'--':listobj.carNumber }}</span></p>
                <!-- <p><span></span></p> -->
              </li>
              <li>
                <p><span>用户来源</span><span>{{ listobj.stationName }}</span></p>
                <p><span>所属公司</span><span>{{ listobj.companyName }}</span></p>
                <p><span>加入时间</span><span>{{ listobj.createTime }}</span></p>
                <!-- <p><span>最近一次充电时间</span><span>{{ listobj.lastTimeCharge }}</span></p> -->
              </li>
            </dd>
          </dl>
        </div>
        <div class="center">
          <dl>
            <dt>
              <el-image style="width: 200px; height: 200px" :src="tupian + '总费用.png'" fit="scale-down"></el-image>
            </dt>
            <dd>
              <!-- 纵向排布 -->
              <li>
                <!-- p：纵向排布 -->
                <p></p>
              </li>
              <li style="display:flex;flex-direction:row;justify-content: space-between;">
                <!-- p：纵向排布 -->
                <p><span>总消费（元）</span><span>{{ jisuan(listobj.totalConsumptionMoney, 100, 2) }}</span></p>
                <p><span @click="listMethods" style="color: blue;">查看充电记录</span></p>
              </li>
            </dd>
          </dl>
        </div>

        <div class="footer">
          <!-- 纵向 -->
          <li>
            <p>
              <span>近30天的充电数据</span>
            </p>
            <p>
              <span>充电次数：</span>
              <span>{{ listobj.totalBillCount }}次</span>
            </p>
            <p>
              <span>充电电量：</span>
              <span>{{ listobj.totalBillKwhs }}度</span>
            </p>
            <p>
              <span>充电费用：</span>
              <span>{{ listobj.totalBillMoney }}元</span>
            </p>
          </li>
        </div>
      </div>
    </el-dialog>
    <!-- 充电历史订单 -->
    <el-dialog :visible.sync="dialoagBtn" width="80%" @close="MoneyDialoag2" append-to-body
      :close-on-click-modal="false">
      <div class="Gomoney">
        <h4>充电历史订单</h4>
        <div class="moneylist">
          <dl>
            <p>订单金额（元）</p>
            <p>{{ jisuan(moren.totalBillMoney, 100, 2) }}</p>
          </dl>
          <dl>
            <p>电费金额（元）</p>
            <p>{{ jisuan(moren.totalChargeMoney, 100, 2) }}</p>
          </dl>
          <dl>
            <p>优惠金额（元）</p>
            <p>{{ jisuan(moren.totalDiscountMoney, 100, 2) }}</p>
          </dl>
          <dl>
            <p>服务费金额（元）</p>
            <p>{{ jisuan(moren.totalServiceMoney, 100, 2) }}</p>
          </dl>
          <dl>
            <p>实际收入（元）</p>
            <p>{{ shiji(moren.totalServiceMoney, moren.totalChargeMoney) }}</p>
          </dl>
          <dl>
            <p>充电电量（kwh）</p>
            <p>{{ jisuan(moren.totalBillKwhs,100,4) }}</p>
          </dl>
          <dl>
            <p>充电时间（分钟）</p>
            <p>{{ jisuan(moren.totalLengthTime, 60, 0) }}</p>
          </dl>
          <dl>
            <p>充电次数</p>
            <p>{{ moren.totalBillCount==undefined||moren.totalBillCount==null||moren.totalBillCount==''?'---':moren.totalBillCount }}</p>
          </dl>
        </div>
        <div class="list3" v-for="(item, index) in list3" :key="index">
          <h4
            style="display:flex; flex-direction:row;align-items:center; background:#e5e5e5;padding:10px 0;font-size:16px;">
            <span style="flex:1;padding-left:10px;font-size:14px;">{{ time_all(item) }}</span>
            <span style="flex:2;display:flex;justify-content:center;"> Vin码：{{ item.carvin !== '' ? item.carvin : '--'
              }}</span>
            <span style="flex:3;display:flex;justify-content:center;"> {{ item.createTime !== '' ? item.createTime :
        '--'
              }}</span>
            <span style="flex:1;display:flex;justify-content:center;">ID:{{ item.id }}</span>
            <span style="flex:1;display:flex;justify-content:center;"> 充电方式:{{ item.id }}</span>
            <span style="flex:2;display:flex;justify-content:center;"> 充电桩编码:{{ item.pileCode }}</span>
          </h4>
          <div class="CONTENT" style="border:1px solid gray;margin:20px 0;border-radius:15px;">
            <dl style="display:flex; flex-direction:row;">
              <dt style="display:flex;flex-direction:column;align-items:center;flex:1;">
                <el-image :src="tupian + 'morentouxiang.png'" style="width: 200px; height: 200px"></el-image>
                <span>充电卡用户ID：{{ item.id }}</span>
              </dt>
              <dd style="display:flex; flex-direction:column;flex:9;">
                <!-- 纵向均分两行 -->
                <div class="li1" style="flex:1;display:flex; flex-direction:row;">
                  <!-- 横向，均分5列 -->
                  <div class="hengxiang" style="flex:3;display:flex; flex-direction:column;">
                    <span style="flex:1;display:flex;align-items:center;">{{ item.stationName }}</span>
                    <span style="flex:1;display:flex;align-items:center;">充电口：{{ item.gunNumName }}</span>
                    <span style="flex:1;display:flex;align-items:center;">充电时长：{{ item.totalChargeTime }}分钟</span>
                  </div>
                  <div class="hengxiang" style="flex:2;display:flex; flex-direction:column;">
                    <span style="flex:1;display:flex;align-items:center;"></span>
                    <span style="flex:1;display:flex;align-items:center;margin-left:-15%;">开始充电：{{ item.startTime
                      }}</span><span style="flex:1;display:flex;align-items:center;margin-left:-15%;">结束充电：{{
        item.endTime
      }}</span>
                  </div>
                  <div class="hengxiang" style="flex:2;display:flex; flex-direction:column;">
                    <span style="flex:1;display:flex;align-items:center;"></span>
                    <span style="flex:1;display:flex;align-items:center;margin-left:25%;">开始SOC：{{ item.startSoc
                      }}</span><span style="flex:1;display:flex;align-items:center;margin-left:25%;">结束SOC：{{
        item.endSoc
      }}</span>
                  </div>
                  <!-- span 纵向 -->
                  <div class="hengxiang" style="flex:1;display:flex; flex-direction:column;">
                    <span style="flex:1;display:flex;align-items:center;">￥{{ jisuan(item.billMoney, 100, 2)
                      }}</span><span style="flex:1;display:flex;align-items:center;">当前订单金额</span>
                  </div>
                  <!-- span 纵向 -->
                  <div class="hengxiang" style="flex:2;display:flex; flex-direction:column;">
                    <span style="flex:1;display:flex;align-items:center;margin-left:25%;">￥{{ shifu(item) }}</span><span
                      style="flex:1;display:flex;align-items:center;margin-left:25%;">实际订单金额</span>
                  </div>
                </div>
                <div class="li2" style="flex:1;display:flex; flex-direction:row;">
                  <div class="hengxiang" style="flex:3;display:flex; flex-direction:column;">
                    <span style="flex:1;display:flex;align-items:center;">尖电量：{{ jisuan(item.tipKwh, 100, 4) }}（度）</span>
                    <span style="flex:1;display:flex;align-items:center;">峰电量：{{ jisuan(item.peakKwh, 100, 4) }}（度）</span>
                  </div>
                  <div class="hengxiang" style="flex:2;display:flex; flex-direction:column;">
                    <span style="flex:1;display:flex;align-items:center;margin-left:-15%;">平电量：{{
        jisuan(item.flatKwh, 100, 4)
      }}（度）</span>
                    <span style="flex:1;display:flex;align-items:center;margin-left:-15%;">谷电量：{{
          jisuan(item.valleyKwh, 100, 4) }}（度）</span>
                  </div>
                  <div class="hengxiang" style="flex:2;display:flex; flex-direction:column;">
                    <span style="flex:1;display:flex;align-items:center;margin-left:25%;">电费：{{ item.startSoc
                      }}</span><span style="flex:1;display:flex;align-items:center;margin-left:25%;">服务费：{{ item.endSoc
                      }}</span>
                  </div>
                  <!-- span 纵向 -->
                  <div class="hengxiang" style="flex:1;display:flex; flex-direction:column;">
                    <span style="flex:1;display:flex;align-items:center;">{{ jisuan(item.totalKwh,100,4) }}</span><span
                      style="flex:1;display:flex;align-items:center;">已充电量（度）</span>
                  </div>
                  <!-- span 纵向 -->
                  <div class="hengxiang" style="flex:2;display:flex; flex-direction:column;">
                    <span :style="{color:item.payTypeName=='未支付'?'red':'gray'}"
                      style="flex:1;display:flex;align-items:center;margin-left:25%;">{{ item.payTypeName }}</span>
                    <span style="flex:1;display:flex;align-items:center;margin-left:25%;color:red"
                      v-if="item.payTypeName=='未支付'">结算</span>
                    <span style="flex:1;display:flex;align-items:center;margin-left:25%;color:orange">推送</span>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
        </div>
        <el-pagination @size-change="handleSizeChange3" @current-change="handleCurrentChange3"
          :current-page="indexobj.page" :page-sizes="[8, 20, 50, 100]" :page-size="carInfo.limit"
          layout="total, sizes, prev, pager, next, jumper" :total="total3" background>
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import caozuo from "@/utils/pubilc.js";
export default {
  name: 'vinAccountWorld',
  data() {
    return {
      indexobj: {
        carVin: 0,
        page: 1,
        limit: 8,
        consumeType: 0
      },
      DetailBtn: false,
      Detaildialoag: false,
      dialoagBtn: false,
      Moneylist: [],
      total3: 0,
      carInfo: {

      },
      moren: {

      },
      list3: [],
      listindex: 0,
      tupian: this.$imgUrls,
      listobj: {

      },
      listindex: 0,
      listname: [
        '查看充值记录',
        '查看退款记录',
        '查看赠送金记录',
        '查看即充即退记录',
        '查看绑定金额记录',
        '查看充电记录',
      ],
      editDialogVisible1: false,
      editDialogVisible2: false,
      caozuoquanxian: false,
      // 获取vin用户列表所需参数
      queryInfo: {
        // vin: '',
        companyName: '',
        carNumber: '',
        // startTime: null,
        // endTime: null,
        stationId: null,
        page: 1,
        limit: 6,
        stationIds: window.sessionStorage.getItem('stationIds'),
        roleId: window.sessionStorage.getItem('roleId')
      },
      // vin用户数据及参数
      vinAccountList: [],
      vinAccountForm: {
        id: null,
        // roadId: null,
        // carNum: null,
        companyName: null,
        power: null,
        userTime: null,
        engineNum: null,
        carvin: null,
        carNumber: null,
        companyId: null,
        powerStationId: null,
        electricCardState: 0,
        stationId: null,
        // adminId: window.sessionStorage.getItem('adminId'),
      },
      vinAccountFormRules: {},
      total: 0,
      title: '',
      value: '',
      editDialogVisible: false,
      // 电站数据
      powerStationOptions: [],
      // 电站所属公司数据
      companyOptions: [
        {
          value: 1,
          label: '公交一公司'
        },
        {
          value: 2,
          label: '公交二公司'
        },
        {
          value: 3,
          label: '公交三公司'
        },
        {
          value: 4,
          label: '公交四公司'
        },
        {
          value: 5,
          label: '焦作市龙源巴士'
        }
      ],
      powerOptions: [],
      ids: '',
      stationList: [],
      goMoneyDialoag: false
    }
  },

  computed: {
    shiji() {
      return function (abc, bac) {
        return abc==null||abc==undefined?'---':(((abc * 1) + (bac * 1)) / 100).toFixed(2)
      }
    },
    wait_s() {
      return function (e) {
        return ((e.money - e.giveMoney) / 100).toFixed(2)
      }
    },
    time_all() {
      return function (val) {
        const hr = val.billCode.slice(val.pileCode.length, val.billCode.length)
        return hr
      }
    },
    jisuan() {
      return function (obj, num, fixed) {
        return obj==null||obj==undefined?'---':(obj / num).toFixed(fixed)
      }
    },
    shifu() {
      return function (val) {
        return ((val.billMoney - val.billMoneygive) / 100).toFixed(2)
      }
    }
  },

  created() {
    caozuo(100).then(res => {
      this.caozuoquanxian = res;
    });
    this.getVinAccountList()
  },

  methods: {
    // 历史记录
    handleSizeChange3(newSize) {
      this.indexobj.limit = newSize;
      this.getIndex()
    },// 历史记录
    handleCurrentChange3(newPage) {
      this.indexobj.page = newPage;
      this.getIndex()
    },
    getIndex() {
      const that = this; that.list3 = [];
      that.$http.get('charge-user-info/getAllBillByUserId', {
        params: that.indexobj
      }).then(res => {
        if (res.data.code !== 0) return;
        that.list3 = res.data.data;
      })
    },
    detailMethods(e) {
      const that = this;
      that.indexobj.carVin=e.carvin;
      that.$http.get(`charge-vin/getChargeVinById/${e.id}`).then(res => {
        if (res.data.code !== 0) return;
        that.listobj = res.data.data; that.tanceng();
        that.carInfo = {
          payId: e.id,
          usingType: 0,
          billInfoType: 2,
          adminId: window.sessionStorage.getItem('adminId'),
          page: 1,
          limit: 8,
          userNumber: e.userNumber,
          billCode: e.billCode,
          pileCode: e.pileCode,
          consumeType: 0,
          type: 2
        }
      })
    },
    MoneyDialoag2() {
      this.dialoagBtn = false;
    },
    tanceng() {
      this.Detaildialoag = true;
    },
    listMethods() {
      const that = this;
      that.$http.get('charge-user-info/getAllBillByUserId', {
        params: that.indexobj
      }).then(ress => {
        if (ress.data.code !== 0) return;
        that.list3 = ress.data.data;
        that.total3 = ress.data.count;
        that.moren = {
          totalBillMoney: ress.data.data[0].totalBillMoney,
          totalChargeMoney: ress.data.data[0].totalChargeMoney,
          totalDiscountMoney: ress.data.data[0].totalDiscountMoney,
          totalServiceMoney: ress.data.data[0].totalServiceMoney,
          totalBillKwhs: ress.data.data[0].totalBillKwhs,
          totalLengthTime: ress.data.data[0].totalLengthTime,
          totalBillCount: ress.data.data[0].totalBillCount,
        }
      })
      that.dialoagBtn = true;
    },
    // 用户详情
    editDialogClosed1() {
      this.editDialogVisible1 = false;
    },
    // 充电历史订单
    editDialogClosed2() {
      this.editDialogVisible2 = false;
    },
    current() {
      this.queryInfo.page = 1;
    },
    chongzhi() {
      this.queryInfo = {
        // vin: '',
        companyName: '',
        carNumber: '',
        // startTime: null,
        // endTime: null,
        stationId: null,
        page: 1,
        limit: 8,
        stationIds: window.sessionStorage.getItem('stationIds'),
        roleId: window.sessionStorage.getItem('roleId')
      };
      this.getVinAccountList();
    },
    // 获取vin用户列表
    async getVinAccountList() {
      const { data: res } = await this.$http.get('charge-vin/getAllChargeVin', { params: this.queryInfo })
      if (res.code !== 0) {
        return this.$message.error(res.msg)
      }
      this.vinAccountList = res.data
      this.vinAccountList.forEach(item => {
        item.b_j_anniu = false;
      });
      this.vinAccountList.forEach(item => {
        if (item.electricCardState == 0) {
          item.type = true
        } else if (item.electricCardState == 1) {
          item.type = false
        }
      })
      this.total = res.count
    },
    async change_type(row) {
      this.vinAccountForm.id = row.id;
      // console.log(row.type,'208')
      if (row.type == false) {
        row.type = true;
        row.electricCardState = 1;
      } else {
        row.type = false;
        row.electricCardState = 0;
      };
      this.vinAccountForm.electricCardState = row.electricCardState;
      await this.$http.post(`charge-vin/updateStatusById/${row.id}/${this.vinAccountForm.electricCardState}`).then(res => {
        this.$message.success(res.data.msg)
        this.getVinAccountList();
      }).catch(res => {
        this.$message(res.data.msg)
      })
    },
    // 双击展开事件
    dbclick(row, column, event) {
      if (event.currentTarget.querySelector('.el-table__expand-icon')) {
        event.currentTarget.querySelector('.el-table__expand-icon').click()
      }
    },
    // 监听pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize;
      this.getVinAccountList()
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getVinAccountList()
    },
    // 展示添加&编辑直充用户对话框
    async showVinAccount(row) {
      this.powerStationOptions = []
      this.editDialogVisible = true;
      let a = null;
      if (window.sessionStorage.getItem('stationIds') == null) {
        a = null
      } else {
        a = window.sessionStorage.getItem('stationIds')
      };
      const { data: res } = await this.$http.get('station-info/getAllStationInfoName', {
        params: {
          roleId: window.sessionStorage.getItem('roleId'),
          stationIds: window.sessionStorage.getItem('stationIds'),
          stationMode: '0,1,2'
        }

      })
      this.stationList = res.data;


      // if (res.code !== 0) return this.$message.error(res.msg)
      // res.data.forEach(item => {
      //   const obj = {
      //     label: '',
      //     value: ''
      //   }
      //   obj.label = item.name
      //   obj.value = item.id
      //   this.powerStationOptions.push(obj)
      // })
      if (row.id === undefined || row.id === null) {
        this.title = '添加直充用户'
      } else {
        this.title = '编辑直充用户'
        const { data: res } = await this.$http.get('charge-vin/getChargeVinById/' + row.id)
        if (res.code !== 0) {
          row.b_j_anniu = false;
          this.$message.error(res.msg)
        } else {
          row.b_j_anniu = true;
          this.vinAccountForm = res.data
        }

      }
    },
    // 添加&编辑直充用户事件
    editVinAccount() {
      this.$refs.vinAccountFormRef.validate(async valid => {
        if (!valid) return
        this.vinAccountForm.adminId = window.sessionStorage.getItem('adminId') * 1;
        if (this.title === '添加直充用户') {
          const { data: res } = await this.$http.post('charge-vin/addChargeVin', this.vinAccountForm)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success('添加直充用户成功！')
          this.getVinAccountList()
          this.editDialogVisible = false
        } else {
          const { data: res } = await this.$http.post('charge-vin/updateChargeVinById', this.vinAccountForm)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success('编辑直充用户成功！')
          this.getVinAccountList()
          this.editDialogVisible = false
        }
      })
    },
    // 添加&编辑直充用户对话框关闭事件
    editDialogClosed() {
      this.$refs.vinAccountFormRef.resetFields();
      this.editDialogVisible = false;
      this.vinAccountList.forEach(element => {
        element.b_j_anniu = false;
      });
    },
    // 批量删除的复选框改变事件
    handleSelectionChange(value) {
      if (value.length > 0) {
        this.ids = ''
        value.forEach(item => {
          this.ids += item.id + ','
        })
        this.ids = this.ids.substring(0, this.ids.length - 1)
      } else {
        return false
      }
    },
    // 删除vin用户方法
    async removeById(row) {
      const confirmResult = await this.$confirm('此操作将永久删除该VIN用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消删除！')
      }
      if (row.id === null || row.id === '' || row.id === undefined) {
        const { data: res } = await this.$http.post('vin/delVinById/' + this.ids)
        if (res.code !== 0) {
          return this.$message.error('删除VIN用户失败！')
        }
        this.$message.success('删除VIN用户成功！')
        this.getVinAccountList()
      } else {
        const { data: res } = await this.$http.post('vin/delVinById/' + row.id)
        if (res.code !== 0) {
          return this.$message.error('删除VIN用户失败！')
        }
        this.$message.success('删除VIN用户成功！')
        this.getVinAccountList()
      }
    }
  }
};
</script>

<style lang="less" scoped>
.moneylist {
  height: 180px;
  display: flex;
  flex-direction: row;

  dl {
    flex: 1;
    display: flex;
    flex-direction: column;

    p {
      flex: 1;
      display: flex;
      align-items: center;
    }

  }
}

.cardslist {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .top,
  .center {
    flex: 2;
    display: flex;
    flex-direction: column;

    dl {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      dt {
        // flex: 2;
        width: 200px;
        display: flex;
        align-items: center;
      }

      dd {
        // flex: 8
        width: calc(100% - 200px);
      }
    }
  }

  .top {
    font-size: 16px;

    dd {
      display: flex;
      flex-direction: row;

      li {
        flex: 1;
        display: flex;
        flex-direction: column;

        p {
          display: flex;
          flex-direction: row;
          align-items: center;
        }


      }

      li:nth-child(2) {
        span:nth-child(1) {
          width: 160px;
        }
      }
    }

  }

  .center {
    border-bottom: 1px solid gray;
    border-top: 1px solid gray;
    padding-bottom: 20px;

    dl {
      flex: 9;
    }

    dd {
      display: flex;
      flex-direction: column;

      li {
        display: flex;
        flex-direction: row;

        p {
          margin-right: 30px;
          display: flex;
          flex-direction: column;

          span:nth-child(1) {
            font-size: 16px;
            width: 125px;
            color: gray;
          }

          span:nth-child(2) {
            color: blue;
            font-size: 32px;
            display: flex;
            margin-top: 10px;
            justify-content: center;
          }
        }
      }
    }

    .footername {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;

      span {
        flex: 1;
        color: blue;
      }
    }
  }


  .bottom {
    display: flex;
    flex-direction: column;
    font-size: 16px;

    h4 {
      padding: 20px 0;
    }
  }

  .center,
  .bottom.top {
    flex: 3;

  }

  .footer {
    flex: 1;
    display: flex;
    flex-direction: column;
    font-size: 16px;

    li {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      p {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}

.listname {
  display: flex;
  flex-direction: row;
  height: 120px;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 20px;
  border: 1px solid gray;
  list-style: none;
  border-radius: 15px;



  .dl {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 20px;

    p {
      flex: 1;
      display: flex;
      justify-content: center;
      font-size: 18px;
    }

    p:nth-child(1) {
      color: gray;
      font-size: 16px;
    }

  }

  .dl:last-child {
    margin-right: 0;
  }
}
</style>
