<template>
  <div>
    <!-- 卡片视图区域   电卡用户-->
    <el-card>
      <!--  -->
      <el-row :gutter="20" style="margin-bottom: 10px;">
        <el-col :span="3" style="display: flex;flex-direction: row;align-items: center;">
          <span style="flex:2;">卡号:</span><el-input style="flex:8;" placeholder="请输入车牌编号" clearable
            v-model="queryInfo.physicalCode" @clear="getOnAccountList" @change="current"></el-input>
        </el-col>
        <el-col :span="4" style="display: flex;flex-direction: row;align-items: center;">
          <span style="flex:3;">车牌编号:</span><el-input style="flex:7;" placeholder="请输入车牌编号" clearable
            v-model="queryInfo.cardNumber" @clear="getOnAccountList" @change="current"></el-input>
        </el-col>
        <el-col :span="4" style="display: flex;flex-direction: row;align-items: center;">
          <span style="flex:3;">持卡人:</span><el-input style="flex:7;" placeholder="请输入持卡人姓名" clearable
            v-model="queryInfo.cardName" @clear="getOnAccountList" @change="current">
          </el-input>
        </el-col>
        <el-col :span="4" style="display: flex;flex-direction: row;align-items: center;">
          <span style="flex:3;">电卡类型:</span> <el-select filterable style="flex:7;" v-model="queryInfo.electricCardType"
            placeholder="请选择电卡类型" clearable @clear="getOnAccountList" @change="current">
            <el-option v-for="item in electricCardType" :key="item.electricCardType" :label="item.label"
              :value="item.electricCardType"></el-option>
          </el-select>
        </el-col>
        <el-col :span="3" style="display: flex;flex-direction: row;align-items: center;">
          <span style="flex:3;">手机号:</span><el-input style="flex:7;" placeholder="请输入手机号" clearable
            v-model="queryInfo.cardTel" @clear="getOnAccountList" @change="current">
          </el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" icon="el-icon-search" @click="getOnAccountList">查询</el-button>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" icon="el-icon-refresh-left" @click="chongzhi">重置</el-button>
        </el-col>
        <!-- <el-col :span="4" style="margin-left: 40px;">
          <el-button @click="chongzhi">重置</el-button>
        </el-col> -->
      </el-row>
      <!-- table表格区域 -->
      <div class="listname" v-if="listall.length>0">
        <div class="dl" v-for="(item, index) in listall" :key="index">
          <p>
            {{ item.name }}
          </p>
          <p>
            {{ index !== 0 ? jisuan(item.countMoney, 100, 2) : item.countMoney }}
          </p>
        </div>
      </div>
      <el-table :data="onAccountList" border stripe @selection-change="handleSelectionChange" @row-dblclick="dbclick"
        empty-text="暂无数据">
        <el-table-column align="center" type="selection" width="55"></el-table-column>
        <el-table-column align="center" type="expand">
          <template slot-scope="scope">
            <el-form label-position="left" class="demo-table-expand">
              <el-row :gutter="20" type="flex" class="row-bg" justify="space-around">
                <el-col :span="8">
                  <el-form-item label="卡类型：">
                    <el-tag size="small" v-if="scope.row.electricCardType == 0">配置卡</el-tag>
                    <el-tag size="small" type="info" v-if="scope.row.electricCardType == 1">独立卡</el-tag>
                    <el-tag size="small" type="success" v-if="scope.row.electricCardType == 2">平台卡</el-tag>
                    <el-tag size="small" type="warning" v-if="scope.row.electricCardType == 3">超级卡</el-tag>
                  </el-form-item>
                  <el-form-item label="操作员：">
                    <span v-if="scope.row.cardKey">{{ scope.row.cardKey }}</span>
                  </el-form-item>
                  <el-form-item label="用户姓名：">
                    <span v-if="scope.row.cardName">{{ scope.row.cardName }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="卡余额（元）：">
                    <el-tag v-if="scope.row.money">￥{{ scope.row.money }}</el-tag>
                  </el-form-item>
                  <el-form-item label="更新时间"><span>{{ scope.row.updateTime }}</span>
                  </el-form-item>
                  <el-form-item label="手机号：">
                    <span v-if="scope.row.cardTel">{{ scope.row.cardTel }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="卡号：">
                    <span v-if="scope.row.physicalCode">{{ scope.row.physicalCode }}</span>
                  </el-form-item>
                  <!-- <el-form-item label="禁用：" v-if="bianji">
                    <el-switch v-model="scope.row.electricCardState" active-color="#ff4949" inactive-color="#13ce66"
                      :disabled="scope.row.electricCardState === 1 ? true : false"></el-switch>
                  </el-form-item> -->
                </el-col>
              </el-row>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column align="center" label="卡号" prop="physicalCode" width="140"></el-table-column>
        <el-table-column align="center" label="用户姓名" prop="cardName" width="140"></el-table-column>
        <el-table-column align="center" label="手机号" prop="cardTel" width="120"></el-table-column>
        <el-table-column align="center" label="车牌号" prop="carNumber" width="130">
          <template slot-scope="scope">
            <span> {{ scope.row.carNumber !== null && scope.row.carNumber !== '' ? scope.row.carNumber : '--' }} </span>
          </template>
        </el-table-column>
        <el-table-column align="left" label="所属电站" prop="stationName">
          <template slot-scope="scope">
            <span> {{ scope.row.stationName !== null && scope.row.stationName !== '' ? scope.row.stationName : '--' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="卡类型" prop="electricCardType" width="80">
          <template slot-scope="scope">
            <el-tag size="small" v-if="scope.row.electricCardType == 0">配置卡</el-tag>
            <el-tag size="small" type="info" v-if="scope.row.electricCardType == 1">独立卡</el-tag>
            <el-tag size="small" type="success" v-if="scope.row.electricCardType == 2">平台卡</el-tag>
            <el-tag size="small" type="warning" v-if="scope.row.electricCardType == 3">超级卡</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="卡余额（元）" prop="money" width="100">
          <template slot-scope="scope">
            <el-tag size="small" type="primary"> ￥{{ scope.row.money }} </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="禁用" prop="electricCardState" v-if="bianji" width="100">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.electricCardState"
              :active-color="scope.row.electricCardState == true ? '#ff4949' : '#13ce66'"
              :inactive-color="scope.row.electricCardState == true ? '#ff4949' : '#13ce66'"
              @change="change_type(scope.row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTime" width="100">
          <template slot-scope="scope"><span v-if="scope.row.createTime != null">{{ scope.row.createTime }}</span><span
              v-else>--</span></template>
        </el-table-column>
        <el-table-column align="center" label="操作时间" prop="updateTime" width="100">
          <template slot-scope="scope"><span v-if="scope.row.updateTime != null">{{ scope.row.updateTime }}</span><span
              v-else>--</span></template>
        </el-table-column>
        <el-table-column align="left" label="操作" :width="bianji == true && chongzhituikuan == true ? '380px' : '100px'">
          <template slot-scope="scope">
            <el-button size="small" type="warning" @click="kajilu(scope.row)"
              :disabled="scope.row.k_j_l_anniu">卡记录</el-button>
            <el-button size="small" type="primary" @click="showOnAccountDialog(scope.row)" icon="el-icon-edit"
              v-if="bianji" :disabled="scope.row.b_j_anniu">编辑</el-button>
            <el-button size="small"
              v-if="scope.row.electricCardType == 2 && chongzhituikuan == true && scope.row.electricCardState !== 1"
              type="success" @click="cz_tk(scope.row)" :disabled="scope.row.c_z_t_k_anniu">充值/退款</el-button>
            <el-button size="small" type="" @click="detailMethods(scope.row)">用户详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页导航区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.page" :page-sizes="[6, 20, 50, 100]" :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="total" background>
      </el-pagination>
    </el-card>
    <!-- 编辑在线卡用户的对话框 -->
    <el-dialog title="编辑在线卡用户" :visible.sync="editDialogVisible" width="30%" @close="editDialogClosed" append-to-body
      :close-on-click-modal="false">
      <!-- 内容主体区域 -->
      <el-form :model="onAccountForm" :rules="onAccountFormRules" ref="onAccountFormRef" label-width="80px">
        <el-form-item label="用户名" prop="cardName">
          <el-input v-model="onAccountForm.cardName"></el-input>
        </el-form-item>
        <el-form-item label="手机" prop="cardTel">
          <el-input v-model="onAccountForm.cardTel"></el-input>
        </el-form-item>
        <el-form-item label="车牌号" prop="carNumber">
          <el-input v-model="onAccountForm.carNumber"></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogClosed">取 消</el-button>
        <el-button type="primary" v-debounce="editOnlineAccount">确 定</el-button>
      </span>
    </el-dialog>


    <!-- 充值/退款 -->
    <el-dialog title="充值/退款" :visible.sync="cz_tk_dialoag" width="30%" @close="cz_tk_dialoagClosed" append-to-body
      :close-on-click-modal="false">
      <!-- 内容主体区域  -->
      <div>
        <el-radio-group v-model="radio" style="width: 100%;margin-bottom: 40px;" @input="change_input">
          <el-radio :label="item.radio" v-for="item in radio_value_cz_tk" :key="item.radio" :value="item.radio">{{
        item.label }}</el-radio>
        </el-radio-group>
        <!-- 充值/退款 -->
        <el-form :model="onAccountForm" ref="editPresentFormRef" label-width="124px">
          <el-form-item label="总余额（元）" prop="money">
            <el-tag>￥{{ onAccountForm.money / 100 }}</el-tag>
          </el-form-item>
          <el-form-item label="已赠金额（元）" prop="giveMoney">
            <el-tag>￥{{ onAccountForm.giveMoney / 100 }}</el-tag>
          </el-form-item>
          <el-form-item :label="btn_title">
            <el-input-number v-model="num" controls-position="right" :precision="2" :step="0.1"
              :min="0"></el-input-number><span style="margin-left: 20px;"> 元</span>
          </el-form-item>
        </el-form>
      </div>
      <!-- 底部按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="cz_tk_dialoagClosed">取 消</el-button>
        <el-button type="primary" v-debounce="cz_tk_dialoag_ok">确 定</el-button>
      </span>
    </el-dialog>


    <!-- 卡记录 -->
    <el-dialog title="卡记录" :visible.sync="kajilu_dialoag" width="80%" @close="kajilu_dialoagClosed" append-to-body>
      <!-- 内容主体区域 -->
      <div>
        <el-radio-group v-model="ids_all" style="width: 100%;" @input="change_input_card">
          <el-radio :label="item.ids_all" v-for="item in radio_value_JL" :key="item.ids_all" :value="item.ids_all">{{
        item.label }}</el-radio>
        </el-radio-group>
        <div v-if="ids_all == 0">
          <!-- 消费记录 -->
          <el-table :data="expendList" border>
            <el-table-column align="center" label="ID" prop="payId" width="100px"></el-table-column>
            <el-table-column align="center" label="卡号" prop="userNumber"></el-table-column>
            <el-table-column align="center" label="更新前金额（元）" prop=" startMoney">
              <template slot-scope="scope">
                <el-tag type="success">￥{{ jisuan(scope.row.startMoney,100,2) }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" label="变动金额（元）" prop="changMoney">
              <template slot-scope="scope">
                <el-tag type="success">￥{{ jisuan(scope.row.changMoney,100,2) }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作类型" prop="des" width="100px">
              <template slot-scope="scope">
                <el-tag
                  :type="scope.row.usingType == '0' ? 'warning' : scope.row.usingType == '1' ? 'danger' : scope.row.usingType == '2' ? 'success' : scope.row.usingType == '3' ? 'default' : 'warning'">
                  {{ scope.row.usingType == '0' ? '消费' : scope.row.usingType == '1' ? '充值' : scope.row.usingType ==
        '2' ? '退款' : scope.row.usingType == '3' ? '赠送' : '欠费' }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" label="新余额（元）" prop="newMoney">
              <template slot-scope="scope">
                <el-tag type="success">￥{{ jisuan(scope.row.newMoney,100,2) }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column align="center" label="流水单号" prop="billCode" width="200px"></el-table-column>
            <el-table-column align="center" label="创建时间" prop="createTime"></el-table-column>
            <div v-if="expendList.length < 1">
              <el-empty :image-size="200"></el-empty>
            </div>
          </el-table>
          <!-- 分页导航区域 -->
          <el-pagination @size-change="expendSizeChange" @current-change="expendCurrentChange"
            :current-page="expendInfo.page" :page-sizes="[10, 20, 50, 100]" :page-size="expendInfo.limit"
            layout="total, sizes, prev, pager, next, jumper" :total="expendTotal" background>
          </el-pagination>
        </div>
        <!-- 操作记录 -->
        <div v-if="ids_all == 1">
          <el-table :data="caozuojiluList" border>
            <el-table-column align="center" label="ID" prop="id" width="100px"></el-table-column>
            <el-table-column align="center" label="用户姓名" prop="cardName"></el-table-column>
            <el-table-column align="center" label="手机号" prop="cardTel"></el-table-column>
            <el-table-column align="center" label="卡号" prop="physicalCode"></el-table-column>
            <el-table-column align="center" label="操作时间" prop="updateTime"></el-table-column>
            <div v-if="caozuojiluList.length < 1">
              <el-empty :image-size="200"></el-empty>
            </div>
          </el-table>
          <!-- 分页导航区域 -->
          <el-pagination @size-change="caozuojiluSizeChange" @current-change="caozuojiluCurrentChange"
            :current-page="caozuojiluInfo.page" :page-sizes="[8, 20, 50, 100]" :page-size="caozuojiluInfo.limit"
            layout="total, sizes, prev, pager, next, jumper" :total="caozuojiluTotal" background>
          </el-pagination>
        </div>
      </div>
    </el-dialog>

    <!-- 用户详情 -->
    <el-dialog title="" :visible.sync="Detaildialoag" width="60%" @close="DetailBtn" append-to-body
      :close-on-click-modal="false">
      <div class="cardslist">
        <div class="top">
          <dl>
            <dt>
              <el-image style="width: 200px; height: 200px" :src="tupian + 'morentouxiang.png'"
                fit="scale-down"></el-image>
            </dt>
            <dd>
              <!-- 横向排布 -->
              <li>
                <!-- p：横向排布，均分 -->
                <p><span>{{ listobj.cardName }}</span><span></span></p>
                <p><span>{{ listobj.physicalCode }}</span><span></span></p>
                <p><span>{{ listobj.cardTel == '' ? '--' : listobj.cardTel }}</span><span></span></p>
                <p><span>{{ listobj.carNumber }}</span><span></span></p>
              </li>
              <li>
                <p><span>用户来源</span><span>{{ listobj.stationName }}</span></p>
                <p><span>卡类型</span>
                  <span>{{ listobj.electricCardType == 0 ? '配置卡' : listobj.electricCardType ==
        1 ? '独立卡' : listobj.electricCardType
          == 2 ? '平台卡' : '超级卡' }}</span>
                </p>
                <p><span>加入时间</span><span>{{ listobj.createTime }}</span></p>
                <p><span>最近一次充电时间</span><span>{{ listobj.startTime }}</span></p>
              </li>
            </dd>
          </dl>
        </div>
        <div class="center">
          <dl>
            <dt>
              <el-image style="width: 200px; height: 200px" :src="tupian + '总费用.png'" fit="scale-down"></el-image>
            </dt>
            <dd>
              <!-- 纵向排布 -->
              <li>
                <!-- p：纵向排布 -->
                <p><span>钱包余额（元）</span><span>{{ jisuan(listobj.money, 100, 2) }}</span></p>
              </li>
              <li>
                <!-- p：纵向排布 -->
                <p><span>总充值（元）</span><span>{{ jisuan(listobj.totalRechargeMoney, 100, 2) }}</span></p>
                <p><span>总消费（元）</span><span>{{ jisuan(listobj.totalConsumptionMoney, 100, 2) }}</span></p>
                <p><span>总退款（元）</span><span>{{ jisuan(listobj.totalRefundMoney, 100, 2) }}</span></p>
                <p><span>待退款（元）</span><span>{{ wait_s(listobj) }}</span></p>
                <p><span>异常资金（元）</span><span>{{ jisuan(listobj.totalArrearsMoney, 100, 2) }}</span></p>
              </li>
            </dd>
          </dl>
          <div class="footername">
            <span v-for="(item, index) in listname" :style="{
        color: listindex == index ? 'blue' : '#000'
      }" @click="listMethods(index)">{{ item }}</span>
          </div>
        </div>

        <div class="footer">
          <!-- 纵向 -->

          <li>
            <p>
              <span>近30天的充电数据</span>
            </p>
            <p>
              <span>充电次数：</span>
              <span>{{ listobj.totalBillCount }}次</span>
            </p>
            <p>
              <span>充电电量：</span>
              <span>{{ listobj.totalBillKwhs }}度</span>
            </p>
            <p>
              <span>充电费用：</span>
              <span>{{ listobj.totalBillMoney }}元</span>
            </p>
          </li>
        </div>
      </div>
    </el-dialog>
    <!-- 记录的表格 -->
    <el-dialog :visible.sync="currentDialoag" width="80%" @close="currentMethods" append-to-body
      :close-on-click-modal="false" :title="listindex == 0 ? '充值记录' : '退款记录'">
      <el-table :data="Moneylist" border width="80%">
        <el-table-column align="center" label="用户ID" prop="id"></el-table-column>
        <el-table-column align="center" label="唯一编码" prop="userNumber"></el-table-column>
        <el-table-column align="center" label="变动金额" prop="changMoney">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.usingType == 1 ? 'success' : scope.row.usingType == 2 ? 'warning' : scope.row.usingType == 3 ? '' : 'danger'">￥{{
        jisuan(scope.row.changMoney,100,2) }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="流水单号" prop="billCode"></el-table-column>
        <el-table-column align="center" label="备注说明" prop="des"></el-table-column>
        <el-table-column align="center" label="操作时间">
          <template slot-scope="scope">
            <span v-if="scope.row.dcreateTime != null">{{ scope.row.dcreateTime }}</span><span v-else>{{
        scope.row.createTime }}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2"
        :current-page="carInfo.page" :page-sizes="[8, 20, 50, 100]" :page-size="carInfo.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="total2" background>
      </el-pagination>
    </el-dialog>
    <!-- 历史记录 -->
    <el-dialog :visible.sync="dialoagBtn" width="80%" @close="MoneyDialoag2" append-to-body
      :close-on-click-modal="false">
      <div class="Gomoney">
        <h4>充电历史订单</h4>
        <div class="moneylist">
          <dl>
            <p>订单金额（元）</p>
            <p>{{ jisuan(moren.totalBillMoney, 100, 2) }}</p>
          </dl>
          <dl>
            <p>电费金额（元）</p>
            <p>{{ jisuan(moren.totalChargeMoney, 100, 2) }}</p>
          </dl>
          <dl>
            <p>优惠金额（元）</p>
            <p>{{ jisuan(moren.totalDiscountMoney, 100, 2) }}</p>
          </dl>
          <dl>
            <p>服务费金额（元）</p>
            <p>{{ jisuan(moren.totalServiceMoney, 100, 2) }}</p>
          </dl>
          <dl>
            <p>实际收入（元）</p>
            <p>{{ shiji(moren.totalServiceMoney, moren.totalChargeMoney) }}</p>
          </dl>
          <dl>
            <p>充电电量（kwh）</p>
            <p>{{ moren.totalBillKwhs }}</p>
          </dl>
          <dl>
            <p>充电时间（分钟）</p>
            <p>{{ jisuan(moren.totalLengthTime, 60, 0) }}</p>
          </dl>
          <dl>
            <p>充电次数</p>
            <p>{{ moren.totalBillCount }}</p>
          </dl>
        </div>
        <div class="list3" v-for="(item, index) in list3" :key="index">
          <h4
            style="display:flex; flex-direction:row;align-items:center; background:#e5e5e5;padding:10px 0;font-size:16px;">
            <span style="flex:1;padding-left:10px;font-size:14px;">{{ time_all(item) }}</span>
            <span style="flex:2;display:flex;justify-content:center;"> Vin码：{{ item.carvin !== '' ? item.carvin : '--'
              }}</span>
            <span style="flex:3;display:flex;justify-content:center;"> {{ item.createTime !== '' ? item.createTime :
        '--'
              }}</span>
            <span style="flex:1;display:flex;justify-content:center;">ID:{{ item.id }}</span>
            <span style="flex:1;display:flex;justify-content:center;"> 充电方式:{{ item.id }}</span>
            <span style="flex:2;display:flex;justify-content:center;"> 充电桩编码:{{ item.pileCode }}</span>
          </h4>
          <div class="CONTENT" style="border:1px solid gray;margin:20px 0;border-radius:15px;">
            <dl style="display:flex; flex-direction:row;">
              <dt style="display:flex;flex-direction:column;align-items:center;flex:1;">
                <el-image :src="tupian + 'morentouxiang.png'" style="width: 200px; height: 200px"></el-image>
                <span>充电卡用户ID：{{ item.id }}</span>
              </dt>
              <dd style="display:flex; flex-direction:column;flex:9;">
                <!-- 纵向均分两行 -->
                <div class="li1" style="flex:1;display:flex; flex-direction:row;">
                  <!-- 横向，均分5列 -->
                  <div class="hengxiang" style="flex:3;display:flex; flex-direction:column;">
                    <span style="flex:1;display:flex;align-items:center;">{{ item.stationName }}</span>
                    <span style="flex:1;display:flex;align-items:center;">充电口：{{ item.gunNumName }}</span>
                    <span style="flex:1;display:flex;align-items:center;">充电时长：{{jisuan(item.totalChargeTime,60,0)}}分钟</span>
                  </div>
                  <div class="hengxiang" style="flex:2;display:flex; flex-direction:column;">
                    <span style="flex:1;display:flex;align-items:center;"></span>
                    <span style="flex:1;display:flex;align-items:center;margin-left:-15%;">开始充电：{{ item.startTime
                      }}</span><span style="flex:1;display:flex;align-items:center;margin-left:-15%;">结束充电：{{
        item.endTime
      }}</span>
                  </div>
                  <div class="hengxiang" style="flex:2;display:flex; flex-direction:column;">
                    <span style="flex:1;display:flex;align-items:center;"></span>
                    <span style="flex:1;display:flex;align-items:center;margin-left:25%;">开始SOC：{{ item.startSoc
                      }}</span><span style="flex:1;display:flex;align-items:center;margin-left:25%;">结束SOC：{{
        item.endSoc
      }}</span>
                  </div>
                  <!-- span 纵向 -->
                  <div class="hengxiang" style="flex:1;display:flex; flex-direction:column;">
                    <span style="flex:1;display:flex;align-items:center;">￥{{ jisuan(item.billMoney, 100, 2)
                      }}</span><span style="flex:1;display:flex;align-items:center;">当前订单金额</span>
                  </div>
                  <!-- span 纵向 -->
                  <div class="hengxiang" style="flex:2;display:flex; flex-direction:column;">
                    <span style="flex:1;display:flex;align-items:center;margin-left:25%;">￥{{ shifu(item) }}</span><span
                      style="flex:1;display:flex;align-items:center;margin-left:25%;">实际订单金额</span>
                  </div>
                </div>
                <div class="li2" style="flex:1;display:flex; flex-direction:row;">
                  <div class="hengxiang" style="flex:3;display:flex; flex-direction:column;">
                    <span style="flex:1;display:flex;align-items:center;">尖电量：{{ jisuan(item.tipKwh, 100, 4) }}（度）</span>
                    <span style="flex:1;display:flex;align-items:center;">峰电量：{{ jisuan(item.peakKwh, 100, 4) }}（度）</span>
                  </div>
                  <div class="hengxiang" style="flex:2;display:flex; flex-direction:column;">
                    <span style="flex:1;display:flex;align-items:center;margin-left:-15%;">平电量：{{
        jisuan(item.flatKwh, 100, 4)
      }}（度）</span>
                    <span style="flex:1;display:flex;align-items:center;margin-left:-15%;">谷电量：{{
          jisuan(item.valleyKwh, 100, 4) }}（度）</span>
                  </div>
                  <div class="hengxiang" style="flex:2;display:flex; flex-direction:column;">
                    <span style="flex:1;display:flex;align-items:center;margin-left:25%;">电费：{{ item.startSoc
                      }}</span><span style="flex:1;display:flex;align-items:center;margin-left:25%;">服务费：{{ item.endSoc
                      }}</span>
                  </div>
                  <!-- span 纵向 -->
                  <div class="hengxiang" style="flex:1;display:flex; flex-direction:column;">
                    <span style="flex:1;display:flex;align-items:center;">{{ jisuan(item.totalKwh,100,4) }}</span><span
                      style="flex:1;display:flex;align-items:center;">已充电量（度）</span>
                  </div>
                  <!-- span 纵向 -->
                  <div class="hengxiang" style="flex:2;display:flex; flex-direction:column;">
                    <span :style="{color:item.payState!==1?'red':'green'}"
                      style="flex:1;display:flex;align-items:center;margin-left:25%;">{{ item.payState!==1?'未支付':'已结算' }}</span>
                    <span style="flex:1;display:flex;align-items:center;margin-left:25%;color:red"
                      v-if="item.payState!==1">去结算</span>
                    <span style="flex:1;display:flex;align-items:center;margin-left:25%;color:orange">推送</span>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
        </div>
        <el-pagination @size-change="handleSizeChange3" @current-change="handleCurrentChange3"
          :current-page="indexobj.page" :page-sizes="[8, 20, 50, 100]" :page-size="carInfo.limit"
          layout="total, sizes, prev, pager, next, jumper" :total="total3" background>
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import caozuo from "@/utils/pubilc.js";
export default {
  name: 'onlineAccountWorld',
  data() {
    return {
      ids_all: 0,
      bianji: false,
      dialoagBtn: false,
      Moneylist: [],
      total2: 0,
      currentDialoag: false,
      carInfo: {
        electricCardInfoId: '',
        physicalCode: '',
        usingType: 1,
        page: 1,
        limit: 8
      },
      DetailBtn: false,
      Detaildialoag: false,
      tupian: this.$imgUrls,
      chongzhituikuan: false,
      radio_value_JL: [
        {
          ids_all: 0,
          label: '消费记录'
        },
        {
          ids_all: 1,
          label: '操作记录'
        }
      ],
      radio: 0,
      title_btn: null,
      cztk_title: '充值',
      radio_value_cz_tk: [
        {
          label: '充值',
          radio: 0,
        },
        {
          label: '退款',
          radio: 1
        }
      ],
      electricCardType: [
        {
          label: '配置卡',
          electricCardType: 0
        },
        {
          label: '独立卡',
          electricCardType: 1
        },
        {
          label: '平台卡',
          electricCardType: 2
        },
        {
          label: '超级卡',
          electricCardType: 3
        }
      ],
      caozuoquanxian: false,
      caozuojiluVisible: false,
      // 查询在线卡用户参数
      queryInfo: {
        cardNumber: null,
        phone: null,
        startTime: null,
        endTime: null,
        physicalCode: '',
        stationIds: window.sessionStorage.getItem('stationIds'),
        roleId: window.sessionStorage.getItem('roleId'),
        page: 1,
        limit: 6,
        cardName: null
      },
      expendInfo: {
        page: 1,
        limit: 10,
        electricCardInfoId: null,
      },
      total3: 0,
      list3: [],
      moren: {
        totalBillMoney: 0,
        totalChargeMoney: 0,
        totalDiscountMoney: 0,
        totalServiceMoney: 0,
        totalBillKwhs: 0,
        totalLengthTime: 0,
        totalBillCount: 0,
      },
      indexobj: {
        physicalCode: '',
        page: 1,
        limit: 8,
        consumeType: 1
      },
      caozuojiluInfo: {
        page: 1,
        limit: 8,
        physicalCode: null,
      },
      listobj: {

      },
      ids: null,
      listTable: [],
      editDialogVisible: false,
      // 在线卡用户数据
      onAccountList: [],
      caozuojiluList: [],
      listname: [
        '查看充值记录',
        '查看退款记录',
        '查看充电记录',
      ],
      listall:[

      ],
      typIng: false,
      listindex: 0,
      onAccountForm: {},
      onAccountFormRules: {},
      total: 0,
      expendTotal: 0,
      caozuojiluTotal: 0,
      value: '',
      expendDialogVisible: false,
      expendList: [],
      editPresentDialogVisible: false,
      editRefundDialogVisible: false,
      num: 0,
      select_ids: 0,
      cz_tk_dialoag: false,
      kajilu_dialoag: false,
      btn_title: '充值'
    }
  },
  computed: {
    shiji() {
      return function (abc, bac) {
        return (((abc * 1) + (bac * 1)) / 100).toFixed(2)
      }
    },
    wait_s() {
      return function (e) {
        return ((e.money - e.giveMoney) / 100).toFixed(2)
      }
    },
    time_all() {
      return function (val) {
        const hr = val.billCode.slice(val.pileCode.length, val.billCode.length)
        return hr
      }
    },
    jisuan() {
      return function (obj, num, fixed) {
        return (obj / num).toFixed(fixed)
      }
    },
    shifu() {
      return function (val) {
        return ((val.billMoney - val.billMoneygive) / 100).toFixed(2)
      }
    }
  },
  created() {
    caozuo(99).then(res => {
      this.bianji = res;
    });
    caozuo(152).then(res => {
      this.chongzhituikuan = res;
    });
    this.getOnAccountList()
  },
  methods: {
    handleSizeChange2(newSize) {
      this.carInfo.limit = newSize;
      this.getCarinfo()
    },
    handleCurrentChange2(newPage) {
      this.carInfo.page = newPage;
      this.getCarinfo()
    },
    getCarinfo() {
      const that = this;
      that.Moneylist = [];
      that.carInfo.usingType = that.listindex == 0 ? 1 : that.listindex == 1 ? 2 : 3;
      that.$http.get('electric-card-info/getRecordsList', {
        params: that.carInfo
      }).then(res => {
        if (res.data.code !== 0) return;
        that.Moneylist = res.data.data;
        that.total2 = res.data.count;
      })
    },
    MoneyDialoag2() {
      this.dialoagBtn = false;
    },
    currentMethods() {

    },
    getIndex() {
      const that = this; that.list3 = [];
      that.$http.get('charge-user-info/getAllBillByUserId', {
        params: that.indexobj
      }).then(res => {
        if (res.data.code !== 0) return;
        that.list3 = res.data.data;
      })
    },
    handleSizeChange3(newSize) {
      this.indexobj.limit = newSize;
      this.getIndex()
    },
    handleCurrentChange3(newPage) {
      this.indexobj.page = newPage;
      this.getIndex()
    },
    listMethods(e) {
      const that = this;
      that.listindex = e;
      that.Moneylist = [];
      that.total2 = 0;
      if (that.listindex !== 2) {
        that.carInfo.usingType = that.listindex == 0 ? 1 : 2;
        that.$http.get('electric-card-info/getRecordsList', { params: that.carInfo }).then(res => {
          that.currentDialoag = true;
          if (res.data.code !== 0) return;
          that.total2 = res.data.count;
          that.Moneylist = res.data.data;
        })
      } else {
        that.$http.get('charge-user-info/getAllBillByUserId', { params: that.indexobj }).then(res => {
          that.dialoagBtn = true;
          if (res.data.code !== 0) return;
          that.total3 = res.data.count;
          that.list3 = res.data.data;
          that.moren = {
            totalBillMoney: res.data.data[0].totalBillMoney,
            totalChargeMoney: res.data.data[0].totalChargeMoney,
            totalDiscountMoney: res.data.data[0].totalDiscountMoney,
            totalServiceMoney: res.data.data[0].totalServiceMoney,
            totalBillKwhs: res.data.data[0].totalBillKwhs,
            totalLengthTime: res.data.data[0].totalLengthTime,
            totalBillCount: res.data.data[0].totalBillCount,
          }
        })
      }
    },
    MoneyDialoag2() {

    },
    detailMethods(e) {
      const that = this;
      that.carInfo.electricCardInfoId = e.id;
      that.carInfo.physicalCode = e.physicalCode;
      that.indexobj.physicalCode = e.physicalCode;
      that.$http.get(`electric-card-info/getElectricCardInfo/${e.id}`).then(res => {
        if (res.data.code !== 0) return;
        that.listobj = res.data.data;
        that.tanceng();
      })

    },
    // 打开详情
    tanceng() {
      this.Detaildialoag = true;
    },
    current() {
      this.queryInfo.page = 1;
    },
    chongzhi() {
      this.queryInfo = {
        cardNumber: null,
        phone: null,
        startTime: null,
        endTime: null,
        physicalCode: '',
        stationIds: window.sessionStorage.getItem('stationIds'),
        roleId: window.sessionStorage.getItem('roleId'),
        page: 1,
        limit: 8,
        cardName: null
      };
      this.getOnAccountList()
    },
    async change_input_card(e) {
      this.expendList = [];
      this.caozuojiluList = [];
      if (e == 0) {
        const { data: res } = await this.$http.get('electric-card-info/getRecordsList', {
          params: this.expendInfo
        });
        this.$message({
          message: res.code == 0 ? '获取在线卡用户消费记录成功！' : '在线卡用户消费记录为空',
          type: res.code !== 0 ? 'error' : 'success'
        });
        if (res.code !== 0) return;
        this.expendList = res.data;
        this.expendTotal = res.count;
      } else {
        await this.$http.get('electric-card-info/getOperationRecordsList', {
          params: this.caozuojiluInfo
        }).then(resb => {
          this.$message({
            message: resb.data.code == 0 ? '获取在线卡用户操作记录成功！' : '在线卡用户操作记录为空',
            type: resb.data.code !== 0 ? 'error' : 'success'
          });
          if (resb.data.code !== 0) return;
          this.caozuojiluList = resb.data.data;
          this.caozuojiluTotal = resb.data.count;
        })
      }
    },
    change_input(e) {
      this.num = 0;
      console.log(this.onAccountForm, '414');
      if (e == 1) {
        this.btn_title = '退款'
      } else {
        this.btn_title = '充值'
      }
    },
    // 充值\退款事件
    cz_tk_dialoag_ok() {
      if (this.num < 1) {
        return this.$message({
          message: '充值或退款金额必须大于1',
          type: 'warning'
        })
      }
      // 充值
      if (this.radio == 0) {
        this.$http.post('electric-card-info/updRechargeMoneyById?id=' + this.select_ids + '&money=' + this.num * 100 + '&adminId=' + Number(window.sessionStorage.getItem('adminId'))).then(res => {
          if (res.data.code !== 0) { this.$message.error(res.data.msg) } else {
            this.$message.success('在线卡充值成功！');
            this.getOnAccountList();
          };
          this.cz_tk_dialoag = false;
          this.radio = 0;
        })

      }
      if (this.radio == 1) {
        // 退款
        if (this.num * 100 > this.onAccountForm.money) {
          this.$message({
            message: '余额必须大于退款金额',
            type: 'warning'
          })
        } else {
          this.$http.post('electric-card-info/updateRefundMoneyById?id=' + this.select_ids + '&money=' + this.num * 100 + '&adminId=' + Number(window.sessionStorage.getItem('adminId'))).then(ress => {
            if (ress.data.code !== 0) { this.$message.error(ress.data.msg) }
            else {
              this.$message.success('在线卡退款成功！');
              this.getOnAccountList();
            }
            this.cz_tk_dialoag = false;
            this.radio = 0;
          })
          this.cz_tk_dialoag = false;
          this.radio = 0;
        }
      }
    },
    cz_tk_dialoagClosed() {
      this.cz_tk_dialoag = false;
    },
    async kajilu(row) {
      this.expendInfo.electricCardInfoId = '';
      this.caozuojiluInfo.physicalCode = '';
      this.expendList = [];
      this.expendTotal = 0;
      this.kajilu_dialoag = true;
      this.expendInfo.electricCardInfoId = row.id;
      this.expendInfo.physicalCode = row.physicalCode;
      this.caozuojiluInfo.physicalCode = row.id;
      this.expendInfo.page = 1;
      if (this.caozuojiluInfo.physicalCode.length == 0 || this.caozuojiluInfo.physicalCode == 0) return;
      const { data: res } = await this.$http.get('electric-card-info/getRecordsList', {
        params: this.expendInfo
      })
      if (res.code !== 0) { row.k_j_l_anniu = false; this.$message.error(res.msg) } else {
        row.k_j_l_anniu = true;
        this.$message.success('获取在线卡用户消费列表成功！');
        this.expendList = res.data;
        this.expendTotal = res.count
      };
    },
    kajilu_dialoagClosed() {
      this.kajilu_dialoag = false;
      this.ids_all = 0;
    },
    async cz_tk(row) {
      this.num = 0;
      const { data: res } = await this.$http.get('electric-card-info/getElectricCardInfo/' + row.id)
      if (res.code !== 0) return row.c_z_t_k_anniu = false;
      row.c_z_t_k_anniu = true;
      this.onAccountForm = res.data;
      this.select_ids = row.id;
      this.cz_tk_dialoag = true
    },
    async change_type(row) {
      this.onAccountForm.id = row.id;
      this.onAccountForm.electricCardState = row.electricCardState == true ? 1 : 0;
      await this.$http.post(`electric-card-info/updateStatusById/${row.id}/${this.onAccountForm.electricCardState}`).then(res => {
        this.$message({
          message: res.data.msg,
          type: res.data.code == 0 ? 'success' : 'error'
        })
        if (res.data.code !== 0) return;
        this.getOnAccountList()
      }).catch(res => {
        this.$message.error(res.data.msg)
      })
    },
    handleSelectionChange() { },
    changeTime(value) {
      if (value) {
        this.queryInfo.startTime = '';
        this.queryInfo.endTime = '';
        this.queryInfo.startTime = value[0]
        this.queryInfo.endTime = value[1]
      } else {
        this.queryInfo.startTime = null
        this.queryInfo.endTime = null
        this.getOnAccountList()
      }
    },
    // 获取在线卡用户列表
    async getOnAccountList() {
      this.$http.get('electric-card-info/getAllElectricCardInfo', { params: this.queryInfo }).then(res => {
        if (res.data.code !== 0) return this.$message.error(res.data.msg);
        this.listall = [
          {
            name: '用户总数量',
            countMoney: res.data.count
          },
          {
            name: '总充值（元）',
            countMoney: res.data.data[0].totalRechargeMoney
          },
          {
            name: '总退款（元）',
            countMoney: res.data.data[0].totalRefundMoney
          },
          {
            name: '用户总消费（元）',
            countMoney: res.data.data[0].totalConsumptionMoney
          },
          {
            name: '钱包余额（元）',
            countMoney: res.data.data[0].totalMoney
          },
          {
            name: '离线卡总余额（元）',
            countMoney: res.data.data[0].totalOfflineWallet
          }
        ];
        this.onAccountList = res.data.data; this.total = res.data.count;
        this.onAccountList.forEach(item => {
          item.k_j_l_anniu = false; item.b_j_anniu = false; item.c_z_t_k_anniu = false;
          item.money = item.money / 100;
          if (item.electricCardState == 1) {
            item.electricCardState = true
          } else {
            item.electricCardState = false
          }
        })
      })

    },
    // 双击展开事件
    dbclick(row, column, event) {
      if (event.currentTarget.querySelector('.el-table__expand-icon')) {
        event.currentTarget.querySelector('.el-table__expand-icon').click()
      }
    },
    // 监听pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize;
      this.getOnAccountList()
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage;
      this.getOnAccountList()
    },
    // 展示在线卡用户编辑对话框
    async showOnAccountDialog(row) {
      this.editDialogVisible = true
      const { data: res } = await this.$http.get('electric-card-info/getElectricCardInfo/' + row.id)
      if (res.code !== 0) {
        row.b_j_anniu = false
        this.$message.error(res.msg)
      } else {
        row.b_j_anniu = true; this.onAccountForm = res.data
      }
    },
    // 编辑在线卡用户事件
    editOnlineAccount() {
      this.$refs.onAccountFormRef.validate(async valid => {
        if (!valid) return
        this.onAccountForm.adminId = window.sessionStorage.getItem('adminId') * 1;
        const { data: res } = await this.$http.post('electric-card-info/updElectricCardInfo', this.onAccountForm)
        if (res.code !== 0) return this.$message.error(res.msg)
        this.$message.success('编辑在线卡用户成功！')
        this.getOnAccountList()
        this.editDialogVisible = false
      })
    },
    // 编辑在线卡用户对话框关闭事件
    editDialogClosed() {
      this.$refs.onAccountFormRef.resetFields();
      this.editDialogVisible = false;
      this.onAccountList.forEach(item => {
        item.b_j_anniu = false;
      })
    },
    // 监听pageSize改变的事件
    expendSizeChange(newSize) {
      this.expendInfo.limit = newSize;
      this.getExpendList()
    },
    // 监听页码值改变的事件
    expendCurrentChange(newPage) {
      this.expendInfo.page = newPage
      this.getExpendList()
    },
    // 展示消费列表对话框
    showExpend(row) {
      this.expendDialogVisible = true;
      this.expendInfo.electricCardInfoId = row.id;
      this.ids = row.id;
      this.getExpendList()
    },
    async getExpendList() {
      const { data: res } = await this.$http.get('electric-card-info/getRecordsList', {
        params: this.expendInfo
      })
      if (res.code !== 0) return this.$message.error(res.msg)
      console.log(res)
      this.$message.success('获取在线卡用户消费列表成功！')
      this.expendList = res.data
      this.expendTotal = res.count
    },
    // 消费列表关闭事件
    expendDialogClosed() {
      this.expendDialogVisible = false
      this.expendList = []
    },
    // 展示在线卡充值对话框
    async showPresent(id) {
      this.select_ids = id;
      this.editPresentDialogVisible = true;
      const { data: res } = await this.$http.get('electric-card-info/getElectricCardInfo/' + this.select_ids);
      if (res.code !== 0) {
        return this.$message.error(res.msg)
      }
      console.log(res.data)
      this.onAccountForm = res.data
    },
    // 展示在线卡退款对话框
    async showRefund(id) {
      this.select_ids = id;
      this.editRefundDialogVisible = true
      const { data: res } = await this.$http.get('electric-card-info/getElectricCardInfo/' + id)
      if (res.code !== 0) {
        return this.$message.error(res.msg)
      }
      this.onAccountForm = res.data
    },
    // 在线卡充值对话框关闭事件
    editPresentDialogClosed() {
      this.num = 0
    },
    // 在线卡退款对话框关闭事件
    editRefundDialogClosed() {
      this.num = 0
    },
    // 操作记录
    async caozuojilu(id) {
      this.caozuojiluVisible = true;
      this.caozuojiluInfo.physicalCode = id;
      this.caozuojiluInfo.page = 1;
      this.getcaozuojiluList()
    },
    async getcaozuojiluList() {
      const { data: res } = await this.$http.get('electric-card-info/getOperationRecordsList', {
        params: this.caozuojiluInfo
      });
      this.caozuojiluList = res.data;
      this.caozuojiluTotal = res.count;
    },
    caozuojiluClosed() {
      this.caozuojiluVisible = false
      this.caozuojiluList = []
    },
    // 监听pageSize改变的事件
    caozuojiluSizeChange(newSize) {
      this.caozuojiluInfo.limit = newSize;
      this.getcaozuojiluList()
    },
    // 监听页码值改变的事件
    caozuojiluCurrentChange(newPage) {
      this.caozuojiluInfo.page = newPage;
      this.getcaozuojiluList()
    },
  }
}
</script>

<style lang="less" scoped>
.moneylist {
  height: 180px;
  display: flex;
  flex-direction: row;

  dl {
    flex: 1;
    display: flex;
    flex-direction: column;

    p {
      flex: 1;
      display: flex;
      align-items: center;
    }

  }
}

// 用户详情
.cardslist {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .top,
  .center {
    flex: 2;
    display: flex;
    flex-direction: column;

    dl {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      dt {
        // flex: 2;
        width: 200px;
        display: flex;
        align-items: center;
      }

      dd {
        // flex: 8
        width: calc(100% - 200px);
      }
    }
  }

  .top {
    font-size: 16px;

    dd {
      display: flex;
      flex-direction: row;

      li {
        flex: 1;
        display: flex;
        flex-direction: column;

        p {
          display: flex;
          flex-direction: row;
          align-items: center;
        }


      }

      li:nth-child(2) {
        span:nth-child(1) {
          width: 160px;
        }
      }
    }

  }

  .center {
    border-bottom: 1px solid gray;
    border-top: 1px solid gray;
    padding-bottom: 20px;

    dl {
      flex: 9;
    }

    dd {
      display: flex;
      flex-direction: column;

      li {
        display: flex;
        flex-direction: row;

        p {
          margin-right: 30px;
          display: flex;
          flex-direction: column;

          span:nth-child(1) {
            font-size: 16px;
            width: 125px;
            color: gray;
          }

          span:nth-child(2) {
            color: blue;
            font-size: 32px;
            display: flex;
            margin-top: 10px;
            justify-content: center;
          }
        }
      }
    }

    .footername {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;

      span {
        flex: 1;
        color: blue;
      }
    }
  }


  .bottom {
    display: flex;
    flex-direction: column;
    font-size: 16px;

    h4 {
      padding: 20px 0;
    }
  }

  .center,
  .bottom.top {
    flex: 3;

  }

  .footer {
    flex: 1;
    display: flex;
    flex-direction: column;
    font-size: 16px;

    li {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      p {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}

.listname {
  display: flex;
  flex-direction: row;
  height: 120px;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 20px;
  border: 1px solid gray;
  list-style: none;
  border-radius: 15px;
  font-size: 16px;

  .dl {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 20px;

    p {
      flex: 1;
      display: flex;
      justify-content: center;
      // font-size: 16px;
    }

    p:nth-child(1) {
      color: gray;
      // font-size: 16px;
    }

  }

  .dl:last-child {
    margin-right: 0;
  }
}
</style>