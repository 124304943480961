<template>
  <div class="default">
    <div class="left">
      <div class="left1">
        <h4><span>今日收入概览</span><span></span></h4>
        <li><span>今日充电收入</span><span class="ts">1558.56</span><span>元</span></li>
        <li><span>今日充值金额</span><span class="ts">1338.03</span><span>元</span></li>
        <li><span>今日充电服务费</span><span class="ts">455.25</span><span>元</span></li>
        <li><span>今日充电电量</span><span class="ts">1613.35</span><span>元</span></li>
        <li><span>今日占位费</span><span class="ts">120.00</span><span>元</span></li>
      </div>
      <div class="left2">
        <h4><span>本月数据统计</span><span></span></h4>
        <li><span>本月充电收入</span><span class="ts">40310.62</span><span>元</span>
        </li>
        <li><span>本月充值金额</span><span class="ts">48996.13</span><span>元</span>
        </li>
        <li><span>本月充电服务费</span><span class="ts">12382.57</span><span>元</span>
        </li>
        <li><span>本月充电电量</span><span class="ts">39817.87</span><span>元</span>
        </li>
        <li><span>本月占位费</span><span class="ts">120.00</span><span>元</span>
        </li>
      </div>
      <div class="left3">
        <h4><span>历史累计数据</span><span></span></h4>
        <div id="main"></div>
      </div>
    </div>
    <div class="center">
      <div class="center1">
        <h4><span>充电收入总览</span><span>{{ time }}</span></h4>
        <li><span>2340528.84</span><span>历史收入总和（元）</span></li>
      </div>
      <div class="center2">
        <li><span>累计充值</span><span>2396994.84</span></li>
        <li><span>累计服务费收入</span><span>1115967.88</span></li>
        <li><span>累计电量</span><span>2320748.43</span></li>
        <li><span>累计占位费</span><span>120.00</span></li>
      </div>
      <div class="center3">
        <h4><span>充电运营近三月数据分析</span><span></span></h4>
        <div id="content2"></div>
      </div>
    </div>
    <div class="right">
      <div class="right1">
        <li>
          <dl>
            <dt><el-image :src="pichers" fit="full" style="width: 10px;
            height: 20px;margin-right: 5px;"></el-image> <span>充电站数量</span></dt>
            <dd><span>45</span></dd>
          </dl>
          <dl>
            <dt><el-image :src="pichers" fit="full" style="width: 10px;
            height: 20px;margin-right: 5px;"></el-image> <span>充电枪数量</span></dt>
            <dd><span>156</span></dd>
          </dl>
          <dl>
            <dt><el-image :src="pichers" fit="full" style="width: 10px;
            height: 20px;margin-right: 5px;"></el-image> <span>在线充电枪</span></dt>
            <dd><span>142</span></dd>
          </dl>
        </li>
        <li>
          <dl>
            <dt><el-image :src="pichers" fit="full" style="width: 10px;
            height: 20px;margin-right: 5px;"></el-image> <span>在用充电枪</span></dt>
            <dd><span>9</span></dd>
          </dl>
          <dl>
            <dt><el-image :src="pichers" fit="full" style="width: 10px;
            height: 20px;margin-right: 5px;"></el-image> <span>充电次数</span></dt>
            <dd><span>59940</span></dd>
          </dl>
          <dl>
            <dt><el-image :src="pichers" fit="full" style="width: 10px;
            height: 20px;margin-right: 5px;"></el-image> <span>注册用户</span></dt>
            <dd><span>19656</span></dd>
          </dl>
        </li>
      </div>
      <div class="right2">
        <h4><span>本周数据统计</span><span></span></h4>
        <li>
          <dl>
            <dt><el-image :src="pichers" fit="full" style="width: 10px;
            height: 20px;margin-right: 5px;"></el-image> <span>本周收入</span></dt>
            <dd><span>8201.46</span></dd>
          </dl>
          <dl>
            <dt><el-image :src="pichers" fit="full" style="width: 10px;
            height: 20px;margin-right: 5px;"></el-image> <span>本周充值</span></dt>
            <dd><span>8048.39</span></dd>
          </dl>
        </li>
        <li>
          <dl>
            <dt><el-image :src="pichers" fit="full" style="width: 10px;
            height: 20px;margin-right: 5px;"></el-image> <span>本周充电人数</span></dt>
            <dd><span>454</span> </dd>
          </dl>
          <dl>
            <dt><el-image :src="pichers" fit="full" style="width: 10px;
            height: 20px;margin-right: 5px;"></el-image> <span>本周退款</span></dt>
            <dd><span>281</span></dd>
          </dl>
        </li>
      </div>
      <div class="right3">
        <h4><span>最新订单</span><span></span></h4>
        <div class="content">
          <dv-scroll-board :config="config" style="width:100%;height:100%" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from 'echarts'
export default {
  name: 'Default',
  data() {
    return {
      newobj: {

      },
      time: '',
      pichers: '',
      config: {
        header: ['手机号/卡号', '充电电量', '订单金额'],
        align: ['center'],
        carousel: 'page',
        data: [],
        headerHeight: 50,
        headerBGC: '#122232'
      },
      dianfei: 0,
      dianfei2: 0,
      zhu1list: [120, 200, 150, 80],
      zhu1listName: ['累计充值', '累计收入', '累计服务费', '累计充电电量'],
      zhu2listName: ['月充值', '月收入', '月服务费', '月电量'],
      month1: [320, 232, 201],
      month2: [220, 132, 101],
      month3: [120, 122, 91],
      month4: [20, 32, 31],
      dataMonth: ['2024-12', '2024-11', '2024-10']
    }
  },
  computed: {
    jisuan: function () {
      return function (abc, num, fixed) {
        return (abc / num).toFixed(fixed)
      }
    }
  },
  watch: {

  },
  created() {
    this.pichers = require('../../assets/images/img/square.png');
    setInterval(() => {
      this.currentMethods()
    }, 1000);
    console.log(this.time, '181');
    this.config.data = [['15343906035', '38.7', '25.73'],
    ['15343906035', '38.5', '25.73'],
    ['15343906035', '38.7', '25.73'],
    ['15343906035', '38.7', '25.73'],
    ['15343906035', '38.7', '25.73'],
    ['15343906035', '38.7', '25.73'],
    ['15343906035', '38.7', '25.73'],
    ['15343906035', '38.7', '25.73'],
    ['15343906035', '38.7', '25.73'],
    ['15343906035', '38.7', '25.73']];
    let aaa = this.zhu1list.sort((a, b) => { return b - a });
    this.dianfei = (aaa[0] + 100).toFixed(2);
    let cnc = Math.max(...this.month1, ...this.month2, ...this.month3, ...this.month4);
    this.dianfei2 = (cnc + 100).toFixed(2);
  },
  mounted() {
    this.zhu1();
    this.zhu2();
  },
  methods: {
    currentMethods() {
      const that = this;
      let abc = new Date();
      let y = abc.getFullYear();
      let m = (abc.getMonth() + 1 + '').padStart(2, '0');
      let d = (abc.getDate() + '').padStart(2, '0');
      let hh = (abc.getHours() + '').padStart(2, '0');
      let mm = (abc.getMinutes() + '').padStart(2, '0');
      let ss = (abc.getSeconds() + '').padStart(2, '0');
      that.time = `${y}年${m}月${d}日 ${hh}时${mm}分${ss}秒`;
    },
    zhu1() {
      const that = this;
      let chartDom = document.getElementById('main');
      let myChart = echarts.init(chartDom);
      myChart.setOption({
        xAxis: [
          {
            data: that.zhu1listName,
          }
        ],
        yAxis: [
          {
            type: 'value',
            max: that.dianfei,
            name: '',
            min: 0,
            max: that.dianfei,
          }
        ],
        legend: {
          show: true,
          data: [
            {
              name: that.zhu1listName[0],
              itemStyle: {
                color: '#B0830C'
              }
            },
            {
              name: that.zhu1listName[1],
              itemStyle: {
                color: '#C75E27'
              }
            },
            {
              name: that.zhu1listName[2],
              itemStyle: {
                color: '#0AC89C'
              }
            },
            {
              name: that.zhu1listName[3],
              itemStyle: {
                color: '#1E81DE'
              }
            }
          ],
          icon: "rect",
          itemWidth: 15,
          itemHeight: 15, //图例宽高
          textStyle: {
            color: "#fff"
          }
        },
        grid: {
          left: '0%',    // 距离左边的距离
          right: '0%',   // 距离右边的距离
          top: '10%',     // 距离顶部的距离
          bottom: '0%',   // 距离底部的距离
          containLabel: true
        },
        series: [
          {
            data: [{
              value: that.zhu1list[0], itemStyle: {
                color: '#B0830C'
              }
            }, null, null, null],
            type: 'bar',
            name: that.zhu1listName[0],
            stack: 'sta',
            barWidth: 50,
            label: {
              show: true,
              color: '#fff'
            }
          },
          {
            data: [null, {
              value: that.zhu1list[1], itemStyle: {
                color: '#C75E27'
              }
            }, null, null],
            type: 'bar',
            name: that.zhu1listName[1],
            stack: 'sta',
            barWidth: 50,
            label: {
              show: true,
              color: '#fff'
            }
          },
          {
            data: [null, null, {
              value: that.zhu1list[2], itemStyle: {
                color: '#0AC89C'
              }
            }, null],
            type: 'bar',
            stack: 'sta',
            barWidth: 50,
            name: that.zhu1listName[2],
            label: {
              show: true,
              color: '#fff'
            }
          },
          {
            data: [null, null, null, {
              value: that.zhu1list[3], itemStyle: {
                color: '#1E81DE'
              }
            }],
            type: 'bar',
            stack: 'sta',
            barWidth: 50,
            name: that.zhu1listName[3],
            label: {
              show: true,
              color: '#fff'
            }
          }
        ]
      });
    },
    zhu2() {
      const that = this;
      let chartDom = document.getElementById('content2');
      let myChart = echarts.init(chartDom);
      myChart.setOption({
        xAxis: [
          {
            type: 'category',
            data: this.dataMonth,
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: {
              fontSize: 14
            },
            axisTick: {
              show: false
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '（元）',
            min: 0,
            max: that.dianfei2,
          }
        ],
        barWidth: 50,
        legend: {
          data: that.zhu2listName,
          textStyle:{
            color:'#fff'
          }
        },
        grid: {
          left: '.5%',    // 距离左边的距离
          right: '1%',   // 距离右边的距离
          top: '12%',     // 距离顶部的距离
          bottom: '1%',   // 距离底部的距离
          containLabel: true
        },
        series: [
          {
            name: that.zhu2listName[0],
            type: 'bar',
            data: that.month1,
            label: {
              show: true,
              formatter: (params) => Math.max(params.value),
              textStyle: {
                color: '#fff',
                fontSize: 14
              }
            }
          },
          {
            name: that.zhu2listName[1],
            type: 'bar',
            data: that.month2,
            label: {
              show: true,
              formatter: (params) => Math.max(params.value),
              textStyle: {
                color: '#fff',
                fontSize: 14
              }
            }
          },
          {
            name: that.zhu2listName[2],
            type: 'bar',
            data: that.month3,
            label: {
              show: true,
              formatter: (params) => Math.max(params.value),
              textStyle: {
                color: '#fff',
                fontSize: 14
              }
            }
          }, {
            name: that.zhu2listName[3],
            type: 'bar',
            data: that.month4,
            label: {
              show: true,
              formatter: (params) => Math.max(params.value),
              textStyle: {
                color: '#fff',
                fontSize: 14
              }
            }
          }
        ]
      });
    }
  }
};
</script>

<style lang='less' scoped>
.default {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  color: #fff;
  background: #061b30;
  overflow: hidden;

  .left,
  .right {
    flex: 2;
    font-size: 16px;
  }

  .center {
    flex: 6;
    display: flex;
    flex-direction: column;

    .center1,
    .center2 {
      flex: 2;
    }

    .center1 {
      // padding-right: 5%;

      h4 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // background-color: rebeccapurple;
        align-items: center;
        span:nth-child(1){
          border-bottom: 1px solid #77AAD5;
        }
      }

      li {
        display: flex;
        flex-direction: column;

        span {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
        }

        span:nth-child(1) {
          font-size: 28px;
        }
      }
    }

    .center3 {
      flex: 6;

      #content2 {
        width: 100%;
        height: 100%;
      }
    }

    .center2 {
      display: flex;
      flex-direction: row;

      li {
        flex: 1;
        font-size: 18px;
        display: flex;
        flex-direction: column;

        span {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .left,
  .right,
  .center {
    display: flex;
    flex-direction: column;
  }

  h4 {
    height: 30px;
    font-size: 18px;
    display: flex;
    align-items: center;
    span:nth-child(1){
          border-bottom: 1px solid #77AAD5;
        }
  }

  .left1,
  .right1,
  .left2,
  .right2,
  .left3,
  .right3 {
    flex: 1;
  }

  .left1,
  .left2 {
    padding: 0 10px;
    display: flex;
    flex-direction: column;

    li {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #77AAD5;
font-size: 15px;
      span,
      .ts {
        flex: 1;
      }

      .ts {
        font-size: 20px;
        display: flex;
        justify-content: center;
      }

      span:nth-child(1) {
        color: #fff;
      }

      span:nth-child(3) {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .left3 {
    #main {
      height: calc(100% - 30px);
      width: 100%;
    }
  }

  .right1,
  .right2 {
    display: flex;
    flex-direction: column;

    li {
      flex: 1;
      display: flex;
      flex-direction: row;

      dl {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-right: 10px;

        dt,
        dd {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        dd {
          font-size: 20px;
          margin-left: 0;
        }
      }
    }


  }

  .right1 {
    padding: 0 10px;

    dl>dd {
      color: #77AAD5;
      font-size: 16px;
    }

    li:nth-child(1) {
      dl:nth-child(1)>dd {
        color: #c5c5c5
      }

      dl:nth-child(2)>dd {
        color: #0AC89C;
      }

      dl:nth-child(3)>dd {
        color: #C75E27;
      }
    }

    li:nth-child(2)>dl:nth-child(1)>dd {
      color: red;
    }
  }

  .right2 {
    li:nth-child(3) {
      dd {
        color: #B0830C;
      }

      dl:nth-child(1)>dd {
        color: #0AC89C;
      }
    }

    li:nth-child(2) {
      dd {
        color: #C75E27;
      }

      dl:nth-child(1)>dd {
        color: rgb(97, 97, 168);
      }
    }
  }

  .right3 {
    .content {
      width: 100%;
      height: calc(100% - 30px);
    }
  }
}
</style>