import Vue from 'vue'
import Router from 'vue-router'
import Login from '../views/Login.vue'
import Menu from '../views/Menu.vue'
import Blank from '../views/blank/Blank.vue'
import Power from '../views/system/Power.vue'
import User from '../views/system/User.vue'
import Role from '../views/system/Role.vue'
import OperateLog from '../views/system/OperateLog.vue'
import LoginLog from '../views/system/LoginLog.vue'
import Settlement from '../views/system/Settlement.vue'
import WechartApplet from '../views/system/WechartApplet.vue'
import Application from '../views/system/Application.vue'
import Hlht from '../views/system/Hlht.vue'
import Coupon from '../views/Preferential/Coupon.vue'
import Invoice from '../views/Preferential/Invoice.vue'
import Preferential from '../views/Preferential/Preferential.vue'
import PowerStation from '../views/Site/PowerStation.vue'
import ElectricPile from '../views/Site/ElectricPile.vue'
import PileAnalysis from '../views/Site/PileAnalysis.vue'
import ChargingBill from '../views/OrderCenter/ChargingBill.vue'
import HistoryBill from '../views/OrderCenter/HistoryBill.vue'
import DataStatistics from '../views/DataCenter/DataStatistics.vue'
import Default from '../views/DataCenter/Default.vue'
import Sites from '../views/DataCenter/Sites.vue'
import OrderCenter from '../views/OrderCenter/OrderCenter.vue'
import ManageAccount from '../views/Account/ManageAccount.vue'
import WechartAccount from '../views/Account/WechartAccount.vue'
import VinAccount from '../views/Account/VinAccount.vue'
import SysParam from '../views/sysParams/sysParam.vue'
import Parkingorders from '../views/OrderCenter/Parkingorders.vue'
import trainstop from '../views/Site/trainstop.vue'
import Groundlockmanagement from '../views/Site/Groundlockmanagement.vue'
import ActiveName from "../views/Preferential/ActiveName"
import FreeCoupton from "../views/Preferential/FreeCoupton"
import OperatorAccount from "../views/Account/OperatorAccount"
import AccountOpening from "../views/Account/AccountOpening"
import Withdrawal from "../views/Account/Withdrawal"
import Flowmanagement from "../views/Flowmanagement/Flowmanagement"
import FlowmanagementList from "../views/Flowmanagement/FlowmanagementList"
import FlowmanagementStation from "../views/Flowmanagement/FlowmanagementStation"
import DefaultH5 from "../views/DefaultH5"
import ElecticAustor from "../views/Site/ElecticAustor"
// import CodeM from "../views/sysParams/codeM.vue"
// 历史标签使用重复路由导致的报错（已解决）：
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router)

const router = new Router({
  routes: [
    { path: '/', redirect: '/dataStatistics' },
    { path: '/login', component: Login },
    {
      path: '/DefaultH5', component: DefaultH5
    },
    {
      path: '/menu',
      component: Menu,
      redirect: '/dataStatistics',
      children: [
        { path: '/blank', component: Blank },
        { path: '/user', component: User },
        { path: '/role', component: Role },
        { path: '/power', component: Power },
        { path: '/settlement', component: Settlement },
        { path: '/wechartApplet', component: WechartApplet },
        { path: '/application', component: Application },
        { path: '/hlht', component: Hlht },
        { path: '/operateLog', component: OperateLog },
        { path: '/loginLog', component: LoginLog },
        { path: '/orderCenter', component: OrderCenter },
        { path: '/manageAccount', component: ManageAccount },
        { path: '/wechartAccount', component: WechartAccount },
        { path: '/vinAccount', component: VinAccount },
        { path: '/preferential', component: Preferential },
        { path: '/coupon', component: Coupon },
        { path: '/invoice', component: Invoice },
        { path: '/powerStation', component: PowerStation },
        { path: '/electricPile', component: ElectricPile },
        { path: '/pileAnalysis', component: PileAnalysis },
        { path: '/chargingBill', component: ChargingBill },
        { path: '/historyBill', component: HistoryBill },
        { path: '/dataStatistics', component: DataStatistics },
        {
          path: '/Default', component: Default
        },
{
  path:'/ElecticAustor',component:ElecticAustor
},
// {
// path:'/CodeM',component:CodeM
// },
        { path: '/sysParam', component: SysParam },
        { path: '/Parkingorders', component: Parkingorders },
        { path: '/trainstop', component: trainstop },
        { path: '/Groundlockmanagement', component: Groundlockmanagement },
        { path: '/Sites', component: Sites },
        { path: '/ActiveName', component: ActiveName },
        { path: '/FreeCoupton', component: FreeCoupton },
        { path: '/OperatorAccount', component: OperatorAccount },
        { path: '/AccountOpening', component: AccountOpening },
        { path: '/Withdrawal', component: Withdrawal },
        { path: '/Flowmanagement', component: Flowmanagement },
        { path: '/FlowmanagementList', component: FlowmanagementList },
        { path: '/FlowmanagementStation', component: FlowmanagementStation },
      ]
    }
  ]
})
// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  // to 将要访问的路径
  // from 代表从哪个路径跳转而来
  // next 是一个函数，表示放行
  //    next() 放行    next('/login') 强制跳转
  if (to.path === '/login') return next()
  // 获取token
  const tokenStr = window.sessionStorage.getItem('token')
  if (!tokenStr) return next('/login')
  next()
})

export default router
