<template>
  <el-container class="container">
    <!-- 头部区域 -->
    <el-header class="header" style="background-color: #fff;">
      <div style="font-size: 16px;font-weight: bolder;">
        电助理管理系统
      </div>
      <div>
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            你好，{{ trueName }}
          </span>
          <el-dropdown-menu slot="dropdown">
            <!-- <el-dropdown-item command="personCenter">个人中心</el-dropdown-item> -->
            <el-dropdown-item command="showEditPassword">修改密码</el-dropdown-item>
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <!-- 页面主体区域 -->
    <el-container class="main-con" style="position: relative;">
      <!-- 侧边栏 -->
      <div style="border: 1px solid #eee;">
        <!-- 手机端侧导隐藏 -->
        <el-aside width="150px">
          <!-- 侧边栏菜单区域 -->
          <el-menu text-color="#333744" active-text-color="#0065df" unique-opened router :collapse="isCollapse"
            :default-active="activePath" @select="handleSelect" @open="handleOpen">
            <!-- 一级菜单 -->
            <el-submenu :index="Item.url" v-for="Item in menulist" :key="Item.id">
              <!-- 一级菜单的模板区域 -->
              <template slot="title">
                <!-- 图标 -->
                <i :class="Item.icon"></i>
                <!-- <i :class="iconObj[Item.id]"></i> -->
                <!-- 文本 -->
                <span style="font-size: 16px;">{{ Item.menuname }}</span>
              </template>
              <!-- 二级菜单 -->
              <el-menu-item :index="subItem.url" v-for="subItem in Item.childList" :key="subItem.id">
                <!-- 一级菜单的模板区域 -->
                <template slot="title">
                  <!-- 图标 -->
                  <i :class="subItem.icon"></i>
                  <!-- <i class="el-icon-menu"></i> -->
                  <!-- 文本 -->
                  <span>{{ subItem.menuname }}</span>
                </template>
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </el-aside>
      </div>
      <div style="width: 100%;height: 100%;overflow: hidden;">
        <!-- 历史标签 -->
        <div v-if="tablist !== null" style="margin-bottom: 10px;height: 40px;">
          <div class="breadcrumb" v-if="tablist.length > 0"
            style="overflow: hidden;display: flex;flex-direction: row;justify-content: space-between;">
            <el-tabs style="overflow-x: scroll;" v-model="editableTabsValue" type="card" closable
              @tab-click="handleTabsEdit" @tab-remove="remobe">
              <el-tab-pane v-for="(item, index) in tablist" :key="item.url" :label="item.menuname"
                :name="item.url"></el-tab-pane>
            </el-tabs>
            <el-select class="qita" v-model="value_check" @change="changes" style="margin-left: 20px;width: 160px;">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <!-- 视图区域 -->
        <div class="luyou" style="width: 100%;
           height: 100%;overflow: auto;">
          <keep-alive>
            <router-view style="padding: 0px 10px;padding-bottom: 3.7%"></router-view>
          </keep-alive>
        </div>
      </div>
    </el-container>
    <!-- 修改密码、切换登陆弹窗 -->
    <el-dialog title="修改密码" :visible.sync="dialogFormVisible" width="35%" @close="dialogFormClosed" append-to-body
      :close-on-click-modal="false">
      <el-form :model="passwordForm" :rules="passwordFormRules" ref="passwordFormRef" label-width="100px">
        <el-form-item label="原密码" prop="oldPassWord">
          <el-input type="password" v-model="passwordForm.oldPassWord" placeholder="请输入原密码"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="passWord">
          <el-input type="password" v-model="passwordForm.passWord" placeholder="请输入新密码"></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="conPassWord">
          <el-input type="password" v-model="passwordForm.conPassWord" placeholder="请确认新密码"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormClosed">取 消</el-button>
        <el-button type="primary" @click="editPassword">确 定</el-button>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
export default {
  name: 'homeWorld',

  data() {
    const checkPassword = (rule, value, callback) => {
      if (value === '' || value === undefined || value === null) {
        callback(new Error('请输入原密码!'))
      } else {
        callback()
      }
    }
    const checkNewPassword = (rule, value, callback) => {
      if (value === '' || value === undefined || value === null) {
        callback(new Error('请输入新密码!'))
      } else if (!/^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{6,20}$/.test(value)) {
        callback(new Error('请输入6-20位英文字母、数字或者符号(除空格), 且字母、数字和标点符号至少包含两种'))
      } else {
        callback()
      }
    }
    const checkConPassword = (rule, value, callback) => {
      if (value === '' || value === undefined || value === null) {
        callback(new Error('请再次输入密码!'))
      } else if (value !== this.passwordForm.passWord) {
        callback(new Error('请确认与新密码是否一致!'))
      } else {
        callback()
      }
    }
    return {
      isCollapse: false,
      value_check: '',
      options: [
        {
          label: '关闭其他',
          value: '0'
        },
        {
          label: '关闭所有',
          value: '1'
        },
        {
          label: '关闭当前',
          value: '2'
        }
      ],
      editableTabsValue: 0,
      width_height: 0,
      tablist: [],
      // 获取菜单列表的参数对象
      queryInfo: {
        roleId: window.sessionStorage.getItem('roleId'),
        // 当前展示的页码数
        page: 1,
        // 当前每页显示的数据条数
        limit: 100
      },
      trueName: window.sessionStorage.getItem('trueName'),
      menulist: [],
      menuName: '',
      subMenuName: '',
      iconObj: {
        1: 'iconfont icon-user',
        5: 'iconfont icon-tijikongjian',
        6: 'iconfont icon-shangpin',
        7: 'iconfont icon-danju',
        8: 'iconfont icon-baobiao'
      },
      // 被激活的链接地址
      activePath: '',
      dialogFormVisible: false,
      passwordForm: {
        id: Number(window.sessionStorage.getItem('adminId')),
        oldPassWord: '',
        passWord: '',
        conPassWord: ''
      },
      passwordFormRules: {
        // 密码的校验规则
        oldPassWord: [
          { required: true, validator: checkPassword, trigger: 'blur' }
        ],
        passWord: [
          { required: true, validator: checkNewPassword, trigger: 'blur' }
        ],
        conPassWord: [
          { required: true, validator: checkConPassword, trigger: 'blur' }
        ]
      }
    }
  },

  watch: {
    '$store.state.all': {
      deep: true,
      handler(newVal, oldVal) {
        this.tablist = newVal;
      }
    },
    tablist(newVal) {
      if (newVal == null) return
      if (newVal.legth < 1) {
        this.$store.commit('luyou', {
          url:  window.sessionStorage.getItem('oldUrl'),
          menuname:  window.sessionStorage.getItem('oldmenuname')
        });
        this.$router.push('/' +  window.sessionStorage.getItem('oldUrl'));
        this.handleSelect(window.sessionStorage.getItem('oldUrl'));
      }
    }
  },

  created() {
    const that = this;
    if (that.$store.state.all.length == 0) {
      that.$store.commit('moren', JSON.parse(window.sessionStorage.getItem('tablist')));
      that.tablist = that.$store.state.all;
      that.editableTabsValue = window.sessionStorage.getItem('activePath');
    };
    that.getMenuList();
    that.activePath = window.sessionStorage.getItem('activePath')
    that.menuName = window.sessionStorage.getItem('menuName')
    that.subMenuName = window.sessionStorage.getItem('subMenuName')
  },

  methods: {
    // 历史标签操作选择
    changes(e) {
      if (e == 1) {
        // 关闭所有
        if (this.tablist.length > 1) {
          this.$store.commit('qingkong', []);
          if (window.sessionStorage.getItem('oldUrl').length == 0) {
            this.$router.push('/');
            this.handleSelect('dataStatistics')
          } else {
            this.$router.push('/' + window.sessionStorage.getItem('oldUrl'));
            this.handleSelect(window.sessionStorage.getItem('oldUrl'));
          }
          this.value_check = '';
        }
      } else if (e == 2) {
        // 关闭当前
        if (this.tablist.length > 1) {
          this.$store.commit('del', window.sessionStorage.getItem('activePath'));
          this.value_check = '';
          this.$router.push('/' + this.tablist[0].url);
          this.handleSelect(this.tablist[0].url)
        }
      } else {
        // 关闭其他
        if (this.tablist.length > 1) {
          this.$store.commit('del_event', this.activePath);
          this.value_check = '';
          this.$router.push('/' + window.sessionStorage.getItem('activePath'));
          this.handleSelect(window.sessionStorage.getItem('activePath'))
        }
      };
      this.tablist = this.$store.state.all;
    },
    // 删除历史标签页面
    remobe(targetName) {
      let activeName = this.editableTabsValue;
      if (this.tablist == null) return;
      if (this.tablist.length > 0) {
        if (activeName == targetName) {
          this.$store.commit('del', targetName);
        }
        this.$router.push('/' + this.tablist[0].url);
        this.handleSelect(this.tablist[0].url);
      }
    },
    // 开关菜单
    handleTabsEdit() {
      this.$router.push('/' + this.editableTabsValue);
      this.handleSelect(this.editableTabsValue)
    },
    handleClick(tab) {
      this.tablist.forEach((item => {
        if (item.menuname === tab.name) {
          this.$router.push(item.url)
        }
      }))
    },
    handleCommand(command) {
      if (command === 'personCenter') {
        this.logout()
      } else if (command === 'showEditPassword') {
        this.showEditPassword()
      } else if (command == 'logout') {
        this.logout()
      }
    },
    showEditPassword() {
      this.dialogFormVisible = true
    },
    // 修改密码
    async editPassword() {
      const { data: res } = await this.$http.post('admin/updatePassWordById', this.passwordForm)
      if (res.code !== 0) return this.$message.error(res.msg)
      console.log(res)
      this.$message.success('修改密码成功，请重新登录！')
      window.sessionStorage.clear()
      this.dialogFormVisible = false
    },
    // 重置密码
    dialogFormClosed() {
      this.$refs.passwordFormRef.resetFields();
      this.dialogFormVisible = false;
    },
    // 退出登录
    logout() {
      window.sessionStorage.clear()
      this.$store.commit('qingkong', []);
      this.$router.push('/login')
    },
    // 获取所有的菜单
    async getMenuList() {
      let abc = [];
      let arr = [];
      const { data: res } = await this.$http.get('adminMenu/getAllAdminMenuByRoleId', { params: this.queryInfo })
      if (res.code !== 0) return this.$message.error(res.msg)
      let cdn = new Set(res.data.map(item => item.id)).size !== res.data.length;
      this.menulist = [];
      if (cdn == false) {
        abc = res.data;
        abc.forEach(item => {
          if (item.childList.length > 1) {
            arr = item.childList;
            arr.sort((a, b) => b.sort - a.sort);
            item.childList.forEach(ite => {
              if (ite.sort == arr[0].sort) {
                item.icon = ite.icon;
                item.url = ite.url
              }
            })
          } else {
              item.menuname = item.childList[0].menuname;
              item.icon = item.childList[0].icon;
              item.url = item.childList[0].url;
          }
        });
        this.menulist = abc;
      } else {
        let duplicateObjects = res.data.filter((item, index, self) => {
          if (self.findIndex(t => t.id === item.id) !== index) {
            if (!this.menulist.includes(item.id)) {
              this.menulist.push(item);
              return true;
            }
          }
          return false;
        });

      }
    },
    // 当前页面
    handleOpen(activePath) {
      window.sessionStorage.setItem('activePath', activePath)
      this.activePath = activePath
      this.editableTabsValue = this.activePath;
      this.menulist.forEach(item => {
        if (item.url === this.activePath && item.parentId === 0) {
          item.childList.forEach(ite => {
            if (item.url == ite.url) {
              this.$store.commit('luyou', {
                url: item.url,
                menuname: ite.menuname
              });
            }
          });
          this.menuName = item.menuname
          window.sessionStorage.setItem('menuName', item.menuname)
          this.subMenuName = item.childList[0].menuname
          window.sessionStorage.setItem('subMenuName', item.childList[0].menuname)
          window.sessionStorage.setItem('activePath', item.childList[0].url);
        }
      })
      this.$router.push('/' + activePath)
    },
    // 激活当前菜单的方法
    handleSelect(activePath) {
      this.activePath = activePath;
      this.editableTabsValue = this.activePath;
      this.menulist.forEach(item => {
        item.childList.forEach(ite => {
          if (ite.url == this.activePath) {
            this.subMenuName = ite.menuname;
            window.sessionStorage.setItem('activePath', ite.url);
            window.sessionStorage.setItem('subMenuName', ite.menuname)
            this.$store.commit('luyou', {
              url: ite.url,
              menuname: ite.menuname
            })
          }
        })
      });
    }
  }
};
</script>

<style>
.el-menu {
  border: none;
}

/* .el-aside{
  height: 100%;
}
.el-aside>ul{
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
}
.el-aside>ul>li{
  flex:1;
} */
/* .el-menu>.el-menu-item {
  font-size: 16px;
} */
</style>