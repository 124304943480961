import { render, staticRenderFns } from "./ManageAccount.vue?vue&type=template&id=68f5d0ba&scoped=true&"
import script from "./ManageAccount.vue?vue&type=script&lang=js&"
export * from "./ManageAccount.vue?vue&type=script&lang=js&"
import style0 from "./ManageAccount.vue?vue&type=style&index=0&id=68f5d0ba&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68f5d0ba",
  null
  
)

export default component.exports